import React from "react";

const Notifications = React.lazy(() => import("src/pages/dashboard/employer/notifications"))
const JobNotifications = React.lazy(() => import("src/pages/dashboard/employer/notifications/job_notifications/JobNotifications.js"))

const routes = [
    { path: '/employer/notifications', name: 'Employer Notifications', element: Notifications },
    { path: '/employer/job-notifications', name: 'Employer Job Notifications', element: JobNotifications },
];

export default routes;