import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"

export const googleDriveImagePickerSlide = createSlice({
    name: "googleDriveImagePicker",
    initialState: {
        jobImages: [],
        eventImages: [],
    },
    reducers: {
        doPickImageSuccess: (state, action) => {
            state.jobImages = action.payload.jobImages;
            state.eventImages = action.payload.eventImages;
        },
        doPickImageFail: (state, action) => {
            state.jobImages = [];
            state.eventImages = [];
        },
        resetImageStateSuccess: (state, action) => {
            state.jobImages = [];
            state.eventImages = [];
        },
    },
})

export const doPickImage = () => async (dispatch) => {
    try {
        const url = `/api/google-drive/pick`;

        const response = await axios.get(url);
        dispatch(doPickImageSuccess(response.data));
    } catch (error) {
        dispatch(doPickImageFail(error.response));
    }
}

export const doResetImageState = () => async (dispatch) => {
    dispatch(resetImageStateSuccess());
};

export const {
    doPickImageSuccess,
    doPickImageFail,
    resetImageStateSuccess,
} = googleDriveImagePickerSlide.actions

export const getGoogleDriveJobImages = (state) => state.googleDriveImagePicker?.jobImages;
export const getGoogleDriveEventImages = (state) => state.googleDriveImagePicker?.eventImages;

export default googleDriveImagePickerSlide.reducer