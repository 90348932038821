import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"

export const fairAndEventEmployerSlide = createSlice({
    name: "fairAndEventEmployer",
    initialState: {
        fairAndEventList: null,
        fairAndEventListTotalRow: 0,
        fairAndEventPartnerList: null,
        fairAndEventPartnerListTotalRow: 0,
        fairAndEventRegisteredList: null,
        fairAndEventRegisteredListTotalRow: 0,
        fairAndEventDetail: null,
        registerFairAndEventSuccess: false,
        registerFairAndEventFail: false,
        registerFairAndEventFailedDetail: null,
        fairAndEventRegistrationDetail: null,
        updateRegisterSuccess: false,
        updateRegisterFail: false,
        updateRegisterErrorDetail: null,
        makePaymentSuccess: false,
        makePaymentFail: false,
        makePaymentErrorDetail: null,
    },
    reducers: {
        doGetFairAndEventListSuccess: (state, action) => {
            state.fairAndEventList = action.payload.data
            state.fairAndEventListTotalRow = action.payload?.total_row;
        },
        doGetFairAndEventListFail: (state, action) => {
            state.fairAndEventList = null
            state.fairAndEventListTotalRow = 0;
        },
        doGetFairAndEventPartnerListSuccess: (state, action) => {
            state.fairAndEventPartnerList = action.payload.data
            state.fairAndEventPartnerListTotalRow = action.payload?.total_row;
        },
        doGetFairAndEventPartnerListFail: (state, action) => {
            state.fairAndEventPartnerList = null
            state.fairAndEventPartnerListTotalRow = 0;
        },
        doGetFairAndEventRegisteredListSuccess: (state, action) => {
            state.fairAndEventRegisteredList = action.payload.data
            state.fairAndEventRegisteredListTotalRow = action.payload?.total_row;
        },
        doGetFairAndEventRegisteredListFail: (state, action) => {
            state.fairAndEventRegisteredList = null
            state.fairAndEventRegisteredListTotalRow = 0;
        },
        doGetFairAndEventDetailSuccess: (state, action) => {
            state.fairAndEventDetail = action.payload.data
        },
        doGetFairAndEventDetailFail: (state, action) => {
            state.fairAndEventDetail = null
        },
        doRegisterFairAndEventSuccess: (state, action) => {
            state.registerFairAndEventSuccess = true
            state.registerFairAndEventFail = false
        },
        doRegisterFairAndEventFail: (state, action) => {
            state.registerFairAndEventSuccess = false
            state.registerFairAndEventFail = true
            state.registerFairAndEventFailedDetail = action.payload.data
        },
        resetRegisterFairAndEventState: (state, action) => {
            state.registerFairAndEventSuccess = false
            state.registerFairAndEventFail = false
            state.registerFairAndEventFailedDetail = null
        },
        doGetFairAndEventRegistrationDetailSuccess: (state, action) => {
            state.fairAndEventRegistrationDetail = action.payload.data
        },
        doGetFairAndEventRegistrationDetailFail: (state, action) => {
            state.fairAndEventRegistrationDetail = null
        },
        doUpdateRegisterFairAndEventSuccess: (state, action) => {
            state.updateRegisterSuccess = true
            state.updateRegisterFail = false
        },
        doUpdateRegisterFairAndEventFail: (state, action) => {
            state.updateRegisterSuccess = false
            state.updateRegisterFail = true
            state.updateRegisterErrorDetail = action.payload.data
        },
        resetUpdateFairAndEventRegistrationStateSuccess: (state, action) => {
            state.updateRegisterSuccess = false
            state.updateRegisterFail = false
            state.updateRegisterErrorDetail = null
        },
        doMakePaymentSuccess: (state, action) => {
            state.makePaymentSuccess = true
            state.makePaymentFail = false
        },
        doMakePaymentFail: (state, action) => {
            state.makePaymentSuccess = false
            state.makePaymentFail = true
            state.makePaymentErrorDetail = action.payload.data
        },
        resetMakePaymentState: (state, action) => {
            state.makePaymentSuccess = false
            state.makePaymentFail = false
            state.makePaymentErrorDetail = null
        },
    },
})

export const doGetFairAndEventList = (param) => async (dispatch) => {
    try {
        let url = `api/employer/fair-and-event?page=${param.page}&size=${param.limit}`;

        if (param.filter != null) {
            for (const field in param.filter) {
                const value = param.filter[field];

                if (value !== null && value !== '' && value !== undefined) {
                    url += `&${field}=${value}`;
                }
            }
        }

        const response = await axios.get(url);
        dispatch(doGetFairAndEventListSuccess(response.data))
    } catch (error) {
        dispatch(doGetFairAndEventListFail(error.response))
    }
}

export const doGetFairAndEventPartnerList = (param) => async (dispatch) => {
    try {
        let url = `api/employer/fair-and-event/partner?page=${param.page}&size=${param.limit}`;

        if (param.filter != null) {
            for (const field in param.filter) {
                const value = param.filter[field];

                if (value !== null && value !== '' && value !== undefined) {
                    url += `&${field}=${value}`;
                }
            }
        }

        const response = await axios.get(url);
        dispatch(doGetFairAndEventPartnerListSuccess(response.data))
    } catch (error) {
        dispatch(doGetFairAndEventPartnerListFail(error.response))
    }
}

export const doGetFairAndEventRegisteredList = (param) => async (dispatch) => {
    try {
        let url = `api/employer/fair-and-event/registered?page=${param.page}&size=${param.limit}`;

        if (param.filter != null) {
            for (const field in param.filter) {
                const value = param.filter[field];

                if (value !== null && value !== '' && value !== undefined) {
                    url += `&${field}=${value}`;
                }
            }
        }

        const response = await axios.get(url);
        dispatch(doGetFairAndEventRegisteredListSuccess(response.data))
    } catch (error) {
        dispatch(doGetFairAndEventRegisteredListFail(error.response))
    }
}

export const doGetFairAndEventDetail = (fair_id) => async (dispatch) => {
    try {
        const response = await axios.get(`/api/employer/fair-and-event/${fair_id}`)
        dispatch(doGetFairAndEventDetailSuccess(response.data));
    } catch (error) {
        dispatch(doGetFairAndEventDetailFail(error.response));
    }
}

export const doRegisterFairAndEvent = (param, fair_id) => async (dispatch) => {
    try {
        const url = `/api/employer/fair-and-event/${fair_id}`
        const response = await axios.post(url, param)
        dispatch(doRegisterFairAndEventSuccess(response.data));
    } catch (error) {
        dispatch(doRegisterFairAndEventFail(error.response));
    }
}

export const doResetRegisterFairAndEventState = () => async (dispatch) => {
    dispatch(resetRegisterFairAndEventState(false))
}

export const doGetFairAndEventRegistrationDetail = (fair_id) => async (dispatch) => {
    try {
        const response = await axios.get(`/api/employer/fair-and-event/registration/${fair_id}`)
        dispatch(doGetFairAndEventRegistrationDetailSuccess(response.data));
    } catch (error) {
        dispatch(doGetFairAndEventRegistrationDetailFail(error.response));
    }
}

export const doUpdateRegisterFairAndEvent = (param, fair_registration_id) => async (dispatch) => {
    try {
        const url = `/api/employer/fair-and-event/registration/${fair_registration_id}`
        const response = await axios.put(url, param)
        dispatch(doUpdateRegisterFairAndEventSuccess(response.data));
    } catch (error) {
        dispatch(doUpdateRegisterFairAndEventFail(error.response));
    }
}

export const doResetUpdateFairAndEventRegistrationStateSuccess = () => async (dispatch) => {
    dispatch(resetUpdateFairAndEventRegistrationStateSuccess(true))
}

export const doMakePayment = (param, fair_registration_id) => async (dispatch) => {
    try {
        let formData = new FormData();
        if (param.paymentFile !== null && param.paymentFile !== "") {
            formData.append('paymentFile', param.paymentFile);
        }

        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }
        const url = `/api/employer/fair-and-event/registration/payment/${fair_registration_id}`
        const response = await axios.post(url, formData, config)
        dispatch(doMakePaymentSuccess(response.data));
    } catch (error) {
        dispatch(doMakePaymentFail(error.response));
    }
}

export const doResetMakePaymentState = () => async (dispatch) => {
    dispatch(resetMakePaymentState())
}

export const {
    doGetFairAndEventListSuccess,
    doGetFairAndEventListFail,
    doGetFairAndEventPartnerListSuccess,
    doGetFairAndEventPartnerListFail,
    doGetFairAndEventRegisteredListSuccess,
    doGetFairAndEventRegisteredListFail,
    doGetFairAndEventDetailSuccess,
    doGetFairAndEventDetailFail,
    doRegisterFairAndEventSuccess,
    doRegisterFairAndEventFail,
    resetRegisterFairAndEventState,
    doGetFairAndEventRegistrationDetailSuccess,
    doGetFairAndEventRegistrationDetailFail,
    doUpdateRegisterFairAndEventSuccess,
    doUpdateRegisterFairAndEventFail,
    resetUpdateFairAndEventRegistrationStateSuccess,
    doMakePaymentSuccess,
    doMakePaymentFail,
    resetMakePaymentState,
} = fairAndEventEmployerSlide.actions

export const getFairAndEventList = (state) => state.fairAndEventEmployer?.fairAndEventList
export const getFairAndEventListTotalRow = (state) => state.fairAndEventEmployer?.fairAndEventListTotalRow
export const getFairAndEventPartnerList = (state) => state.fairAndEventEmployer?.fairAndEventPartnerList
export const getFairAndEventPartnerListTotalRow = (state) => state.fairAndEventEmployer?.fairAndEventPartnerListTotalRow
export const getFairAndEventRegisteredList = (state) => state.fairAndEventEmployer?.fairAndEventRegisteredList
export const getFairAndEventRegisteredListTotalRow = (state) => state.fairAndEventEmployer?.fairAndEventRegisteredListTotalRow
export const getFairAndEventDetail = (state) => state.fairAndEventEmployer?.fairAndEventDetail
export const getRegisterFairAndEventDetailSuccess = (state) => state.fairAndEventEmployer?.registerFairAndEventSuccess
export const getRegisterFairAndEventDetailFail = (state) => state.fairAndEventEmployer?.registerFairAndEventFail
export const getRegisterFairAndEventFailedDetail = (state) => state.fairAndEventEmployer?.registerFairAndEventFailedDetail
export const getFairAndEventRegistrationDetail = (state) => state.fairAndEventEmployer?.fairAndEventRegistrationDetail
export const getUpdateRegisterFairAndEventSuccess = (state) => state.fairAndEventEmployer?.updateRegisterSuccess
export const getUpdateRegisterFairAndEventFail = (state) => state.fairAndEventEmployer?.updateRegisterFail
export const getUpdateRegisterFairAndEventErrorDetail = (state) => state.fairAndEventEmployer?.updateRegisterErrorDetail
export const getMakePaymentSuccess = (state) => state.fairAndEventEmployer?.makePaymentSuccess
export const getMakePaymentFail = (state) => state.fairAndEventEmployer?.makePaymentFail
export const getMakePaymentErrorDetail = (state) => state.fairAndEventEmployer?.makePaymentErrorDetail

export default fairAndEventEmployerSlide.reducer