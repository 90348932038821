import React from "react";

const CreateJob = React.lazy(() => import('src/pages/dashboard/employer/job/create_job/CreateJob'));
const EditJob = React.lazy(() => import('src/pages/dashboard/employer/job/edit_job/EditJob'));
const YourJob = React.lazy(() => import("src/pages/dashboard/employer/job/your_job/yourJob"))
const JobDetail = React.lazy(() => import("src/pages/dashboard/employer/job/your_job/job_detail/JobDetail"))
const YourJobApplicant = React.lazy(() => import("src/pages/dashboard/employer/job/your_job_applicant/YourJobApplicant"))

const routes = [
    { path: '/employer/job/create', name: 'Create an Job', element: CreateJob },
    { path: '/employer/job/edit', name: 'Edit Job', element: EditJob },
    { path: '/employer/your-job', name: 'Your Job', element: YourJob },
    { path: '/employer/job/detail', name: 'Job Detail', element: JobDetail },
    { path: '/employer/your-job-applicant', name: 'Your Job', element: YourJobApplicant },
];

export default routes;