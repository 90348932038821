import React from "react";

const JobListing = React.lazy(() => import("src/pages/dashboard/student/jobs/JobListing"))
const JobDetail = React.lazy(() => import("src/pages/dashboard/student/jobs/job_detail/JobDetail"))
const JobEmployerProfile = React.lazy(() => import("src/pages/dashboard/student/jobs/job_detail/employer_profile/JobEmployerProfile"))

const routes = [
    { path: '/student/jobs', name: 'Jobs', element: JobListing },
    { path: '/student/jobs/detail', name: 'Detail', element: JobDetail },
    { path: '/student/jobs/employer-profile', name: 'Employer Profile', element: JobEmployerProfile },
];

export default routes;