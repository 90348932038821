import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"

export const StudentSlide = createSlice({
    name: "student",
    initialState: {
        registerAvailableStudent: null,
        registerAvailableFailStudent: false,
        registerAvailableErrorMessageStudent: null,
        registerSuccessStudent: false,
        registerFailStudent: false,
        registerErrorMessageStudent: null,
        registerResponseDataStudent: null,
        registerUrlParamStudent: null,
        registerSuccessStudentId: null,

        updateProfileSuccessStudent: false,
        updateProfileFailStudent: false,
        updateProfileErrorMessageStudent: null,
        resendSuccess: false,
        resendFail: false,
        studentDetail: null,
        getStudentDetailErrorData: null
    },
    reducers: {
        doCheckRegisterSuccessStudent: (state, action) => {
            state.registerAvailableStudent = action.payload.available
            state.registerAvailableFailStudent = false
            state.registerAvailableErrorMessageStudent = null
        },
        doCheckRegisterFailStudent: (state, action) => {
            state.registerAvailableStudent = null
            state.registerAvailableFailStudent = true
            state.registerAvailableErrorMessageStudent = action.payload.data
        },
        resetRegisterAvailableStudent: (state, action) => {
            state.registerAvailableStudent = null
            state.registerAvailableFailStudent = false
            state.registerAvailableErrorMessageStudent = null
        },
        doRegisterSuccessStudent: (state, action) => {
            state.registerSuccessStudent = true
            state.registerFailStudent = false
            state.registerErrorMessageStudent = null
            state.registerResponseDataStudent = action.payload.data
            state.registerSuccessStudentId = action.payload.studentId
        },
        doRegisterFailStudent: (state, action) => {
            state.registerSuccessStudent = false
            state.registerFailStudent = true
            state.registerErrorMessageStudent = action.payload.data
        },
        setRegisterUrlParamStudent: (state, action) => {
            state.registerUrlParamStudent = action.payload
        },
        //
        resetRegisterStateStudent: (state, action) => {
            state.registerSuccessStudent = action.payload
            state.registerFailStudent = action.payload
            state.registerErrorMessageStudent = null
            state.registerResponseDataStudent = null
        },
        resetRegisterUrlParamStateStudent: (state, action) => {
            state.registerUrlParamStudent = null
        },
        doUpdateProfileSuccessStudent: (state, action) => {
            state.updateProfileSuccessStudent = true
            state.updateProfileFailStudent = false
            state.updateProfileErrorMessageStudent = null
        },
        doUpdateProfileFailStudent: (state, action) => {
            state.updateProfileSuccessStudent = false
            state.updateProfileFailStudent = true
            state.updateProfileErrorMessageStudent = action.payload.data
        },
        resetUpdateProfileStateStudent: (state, action) => {
            state.updateProfileSuccessStudent = false
            state.updateProfileFailStudent = false
            state.updateProfileErrorMessageStudent = null
        },
        doResendEmailVerificationSuccessStudent: (state, action) => {
            state.resendSuccess = true
            state.resendFail = false
        },
        doResendEmailVerificationFailStudent: (state, action) => {
            state.resendSuccess = false
            state.resendFail = true
        },
        resetRegisterSuccessStudentId: (state, action) => {
            state.registerSuccessStudentId = null
        },
        resetResendVerificationEmailStateStudent: (state, action) => {
            state.resendSuccess = false
            state.resendFail = false
        },
        doGetStudentDetailSuccess: (state, action) => {
            state.studentDetail = action.payload
        },
        doGetStudentDetailFail: (state, action) => {
            state.studentDetail = null
            state.getStudentDetailErrorData = action.payload.data
        },
    },
})

export const doCheckRegisterStudent = (param) => async (dispatch) => {
    try {
        let data = param
        const url = `/api/student/auth/check`
        const response = await axios.post(url, data)

        dispatch(doCheckRegisterSuccessStudent(response.data))
    } catch (error) {
        dispatch(doCheckRegisterFailStudent(error.response))
    }
}

export const doRegisterStudent = (param) => async (dispatch) => {
    try {
        let data = param
        const url = `/api/student/auth/register`
        const response = await axios.post(url, data)

        dispatch(doRegisterSuccessStudent(response.data))
    } catch (error) {
        dispatch(doRegisterFailStudent(error.response))
    }
}

export const doSetRegisterUrlParamStudent = (param) => async (dispatch) => {
    dispatch(setRegisterUrlParamStudent(param))
}

export const doResetRegisterAvailableStudent = () => async (dispatch) => {
    dispatch(resetRegisterAvailableStudent(false))
}

export const doResetRegisterStateStudent = () => async (dispatch) => {
    dispatch(resetRegisterStateStudent(false))
}

export const doResetRegisterUrlParamStateStudent = () => async (dispatch) => {
    dispatch(resetRegisterUrlParamStateStudent(false))
}

export const doUpdateProfileStudent = (param) => async (dispatch) => {
    try {
        let formData = new FormData();
        
        for (const field in param) {
            const value = param[field];
            if (field !== 'resumeFile') {
                formData.append(
                    field, value
                );
            }
        }

        if (param.resumeFile !== null && param.resumeFile !== "") {
            formData.append('resumeFile', param.resumeFile);
        }

        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }

        const url = `/api/student/auth/me`
        const response = await axios.put(url, formData, config)

        dispatch(doUpdateProfileSuccessStudent(response.data))
    } catch (error) {
        dispatch(doUpdateProfileFailStudent(error.response))
    }
}

export const doResetUpdateProfileStateStudent = () => async (dispatch) => {
    dispatch(resetUpdateProfileStateStudent(false))
}

export const doResendEmailVerificationStudent = (param) => async (dispatch) => {
    try {
        const response = await axios.post("api/student/auth/send-email-verification", param)
        dispatch(doResendEmailVerificationSuccessStudent(response.data))
    } catch (error) {
        dispatch(doResendEmailVerificationFailStudent(error.response))
    }
}

export const doResetRegisterSuccessStudentId = () => async (dispatch) => {
    dispatch(resetRegisterSuccessStudentId())
}

export const doResetResendVerificationEmailStateStudent = () => async (dispatch) => {
    dispatch(resetResendVerificationEmailStateStudent())
}

export const doGetStudentDetail = (param) => async (dispatch) => {
    try {
        const response = await axios.post('api/student/detail', param)
        dispatch(doGetStudentDetailSuccess(response.data))
    } catch (error) {
        dispatch(doGetStudentDetailFail(error.response))
    }
}

export const {
    doCheckRegisterSuccessStudent,
    doCheckRegisterFailStudent,
    resetRegisterAvailableStudent,
    doRegisterSuccessStudent,
    doRegisterFailStudent,
    setRegisterUrlParamStudent,
    resetRegisterStateStudent,
    resetRegisterUrlParamStateStudent,
    doUpdateProfileSuccessStudent,
    doUpdateProfileFailStudent,
    resetUpdateProfileStateStudent,
    doResendEmailVerificationSuccessStudent,
    doResendEmailVerificationFailStudent,
    resetRegisterSuccessStudentId,
    resetResendVerificationEmailStateStudent,
    doGetStudentDetailSuccess,
    doGetStudentDetailFail,
    // resetGetStudentDetailState,
} = StudentSlide.actions

export const getRegisterAvailableStudent = (state) => state.student?.registerAvailableStudent
export const getRegisterAvailableFailStudent = (state) => state.student?.registerAvailableFailStudent
export const getRegisterAvailableErrorMessageStudent = (state) => state.student?.registerAvailableErrorMessageStudent
export const getRegisterSuccessStudent = (state) => state.student?.registerSuccessStudent
export const getRegisterFailStudent = (state) => state.student?.registerFailStudent
export const getRegisterErrorMessageStudent = (state) => state.student?.registerErrorMessageStudent
export const getRegisterResponseDataStudent = (state) => state.student?.registerResponseDataStudent
export const getRegisterUrlParamStudent = (state) => state.student?.registerUrlParamStudent
export const getRegisterSuccessStudentId = (state) => state.student?.registerSuccessStudentId

export const getUpdateProfileSuccessStudent = (state) => state.student?.updateProfileSuccessStudent
export const getUpdateProfileFailStudent = (state) => state.student?.updateProfileFailStudent
export const getUpdateProfileErrorMessageStudent = (state) => state.student?.updateProfileErrorMessageStudent

export const getResendVerificationEmailSuccessStudent = (state) => state.student?.resendSuccess
export const getResendVerificationEmailFailStudent = (state) => state.student?.resendFail

export const getStudentDetail = (state) => state.student?.studentDetail

export default StudentSlide.reducer
