import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"

export const UniversitySlide = createSlice({
    name: "university",
    initialState: {
        registerAvailableUniversity: null,
        registerAvailableFailUniversity: false,
        registerAvailableErrorMessageUniversity: null,
        registerSuccessUniversity: false,
        registerFailUniversity: false,
        registerErrorMessageUniversity: null,
        registerResponseDataUniversity: null,
        registerUrlParamUniversity: null,
        registerSuccessUniversityUserId: null,

        updateProfileSuccessUniversity: false,
        updateProfileFailUniversity: false,
        updateProfileErrorMessageUniversity: null,

        universityDetail: null,

        updateUniversitySuccess: false,
        updateUniversityFail: false,
        updateUniversityError: null,
        
        registerSuccessUniversityLeadingTeam: false,
        registerFailUniversityLeadingTeam: false,
        registerErrorMessageUniversityLeadingTeam: null,
        registerResponseDataUniversityLeadingTeam: null,
        registerUrlParamUniversityLeadingTeam: null,

        resendSuccess: false,
        resendFail: false,

        rerouteId: null,
        rerouteSuccess: false,
        rerouteFail: false,
        rerouteError: null,
    },
    reducers: {
        doCheckRegisterSuccessUniversity: (state, action) => {
            state.registerAvailableUniversity = action.payload.available
            state.registerAvailableFailUniversity = false
            state.registerAvailableErrorMessageUniversity = null
        },
        doCheckRegisterFailUniversity: (state, action) => {
            state.registerAvailableUniversity = null
            state.registerAvailableFailUniversity = true
            state.registerAvailableErrorMessageUniversity = action.payload.data
        },
        resetRegisterAvailableUniversity: (state, action) => {
            state.registerAvailableUniversity = null
            state.registerAvailableFailUniversity = false
            state.registerAvailableErrorMessageUniversity = null
        },
        doRegisterSuccessUniversity: (state, action) => {
            state.registerSuccessUniversity = true
            state.registerFailUniversity = false
            state.registerErrorMessageUniversity = null
            state.registerResponseDataUniversity = action.payload.data
            state.registerSuccessUniversityUserId = action.payload?.university_user_id
        },
        doRegisterFailUniversity: (state, action) => {
            state.registerSuccessUniversity = false
            state.registerFailUniversity = true
            state.registerErrorMessageUniversity = action.payload.data
        },
        setRegisterUrlParamUniversity: (state, action) => {
            state.registerUrlParamUniversity = action.payload
        },
        //
        resetRegisterStateUniversity: (state, action) => {
            state.registerSuccessUniversity = action.payload
            state.registerFailUniversity = action.payload
            state.registerErrorMessageUniversity = null
            state.registerResponseDataUniversity = null
        },
        resetRegisterUrlParamStateUniversity: (state, action) => {
            state.registerUrlParamUniversity = null
        },
        doUpdateProfileSuccessUniversity: (state, action) => {
            state.updateProfileSuccessUniversity = true
            state.updateProfileFailUniversity = false
            state.updateProfileErrorMessageUniversity = null
        },
        doUpdateProfileFailUniversity: (state, action) => {
            state.updateProfileSuccessUniversity = false
            state.updateProfileFailUniversity = true
            state.updateProfileErrorMessageUniversity = action.payload.data
        },
        doGetUniversityDetailSuccess: (state, action) => {
            state.universityDetail = action.payload.data
        },
        doGetUniversityDetailFail: (state, action) => {
            state.universityDetail = null
        },
        doUpdateUniversitySuccess: (state, action) => {
            state.updateUniversitySuccess = true
            state.updateUniversityFail = false
            state.updateUniversityError = null
        },
        doUpdateUniversityFail: (state, action) => {
            state.updateUniversitySuccess = false
            state.updateUniversityFail = true
            state.updateUniversityError = action.payload.data
        },
        resetUpdateProfileStateUniversity: (state, action) => {
            state.updateProfileSuccessUniversity = false
            state.updateProfileFailUniversity = false
            state.updateProfileErrorMessageUniversity = null
            state.updateUniversitySuccess = action.payload
            state.updateUniversityFail = action.payload
            state.updateUniversityError = null
        },
        doRegisterSuccessUniversityLeadingTeam: (state, action) => {
            state.registerSuccessUniversityLeadingTeam = true
            state.registerFailUniversityLeadingTeam = false
            state.registerErrorMessageUniversityLeadingTeam = null
            state.registerResponseDataUniversityLeadingTeam = action.payload.data
        },
        doRegisterFailUniversityLeadingTeam: (state, action) => {
            state.registerSuccessUniversityLeadingTeam = false
            state.registerFailUniversityLeadingTeam = true
            state.registerErrorMessageUniversityLeadingTeam = action.payload.data
        },
        resetRegisterStateUniversityLeadingTeam: (state, action) => {
            state.registerSuccessUniversityLeadingTeam = action.payload
            state.registerFailUniversityLeadingTeam = action.payload
            state.registerErrorMessageUniversityLeadingTeam = null
            state.registerResponseDataUniversityLeadingTeam = null
        },
        doResendEmailVerificationSuccessUniversity: (state, action) => {
            state.resendSuccess = true
            state.resendFail = false
        },
        doResendEmailVerificationFailUniversity: (state, action) => {
            state.resendSuccess = false
            state.resendFail = true
        },
        resetRegisterSuccessUniversityUserId: (state, action) => {
            state.registerSuccessUniversityUserId = null
        },
        resetResendVerificationEmailStateUniversity: (state, action) => {
            state.resendSuccess = false
            state.resendFail = false
        },
        doReroutePublicTalengenUrlSuccess: (state, action) => {
            state.rerouteId = action.payload?.id
            state.rerouteSuccess = true
            state.rerouteFail = false
            state.rerouteError = null
        },
        doReroutePublicTalengenUrlFail: (state, action) => {
            state.rerouteId = null
            state.rerouteSuccess = false
            state.rerouteFail = true
            state.rerouteError = action.payload.data
        },
        resetReroutePublicTalengenUrlState: (state, action) => {
            state.rerouteId = null
            state.rerouteSuccess = false
            state.rerouteFail = false
            state.rerouteError = null
        },
    },
})

export const doCheckRegisterUniversity = (param) => async (dispatch) => {
    try {
        let data = param
        const url = `/api/university_user/auth/check`
        const response = await axios.post(url, data)

        dispatch(doCheckRegisterSuccessUniversity(response.data))
    } catch (error) {
        dispatch(doCheckRegisterFailUniversity(error.response))
    }
}

export const doRegisterUniversity = (param) => async (dispatch) => {
    try {
        let data = param
        const url = `/api/university/auth/register`
        const response = await axios.post(url, data)

        dispatch(doRegisterSuccessUniversity(response.data))
    } catch (error) {
        dispatch(doRegisterFailUniversity(error.response))
    }
}

export const doSetRegisterUrlParamUniversity = (param) => async (dispatch) => {
    dispatch(setRegisterUrlParamUniversity(param))
}

export const doResetRegisterAvailableUniversity = () => async (dispatch) => {
    dispatch(resetRegisterAvailableUniversity(false))
}

export const doResetRegisterStateUniversity = () => async (dispatch) => {
    dispatch(resetRegisterStateUniversity(false))
}

export const doResetRegisterUrlParamStateUniversity = () => async (dispatch) => {
    dispatch(resetRegisterUrlParamStateUniversity(false))
}

export const doUpdateProfileUniversity = (param) => async (dispatch) => {
    try {
        let data = param
        const url = `/api/university_user/auth/me`
        const response = await axios.put(url, data)

        dispatch(doUpdateProfileSuccessUniversity(response.data))
    } catch (error) {
        dispatch(doUpdateProfileFailUniversity(error.response))
    }
}

export const doResetUpdateProfileStateUniversity = () => async (dispatch) => {
    dispatch(resetUpdateProfileStateUniversity(false))
}

export const doGetUniversityDetail = (param) => async (dispatch) => {
    try {
        const url = `/api/admin/university-profile`
        const response = await axios.get(url)

        dispatch(doGetUniversityDetailSuccess(response.data))
    } catch (error) {
        dispatch(doGetUniversityDetailFail(error.response))
    }
}

export const doUpdateUniversity = (param) => async (dispatch) => {
    try {
        let data = param

        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }
        
        const url = `/api/admin/university-profile`
        const response = await axios.put(url, data, config)

        dispatch(doUpdateUniversitySuccess(response.data))
    } catch (error) {
        dispatch(doUpdateUniversityFail(error.response))
    }
}

export const doRegisterUniversityLeadingTeam = (param) => async (dispatch) => {
    try {
        let data = param
        const url = `/api/university_user/auth/register`
        const response = await axios.post(url, data)

        dispatch(doRegisterSuccessUniversityLeadingTeam(response.data))
    } catch (error) {
        dispatch(doRegisterFailUniversityLeadingTeam(error.response))
    }
}

export const doResetRegisterStateUniversityLeadingTeam = () => async (dispatch) => {
    dispatch(resetRegisterStateUniversityLeadingTeam(false))
}

export const doResendEmailVerificationUniversity = (param) => async (dispatch) => {
    try {
        const response = await axios.post("api/university_user/auth/send-email-verification", param)
        dispatch(doResendEmailVerificationSuccessUniversity(response.data))
    } catch (error) {
        dispatch(doResendEmailVerificationFailUniversity(error.response))
    }
}

export const doResetRegisterSuccessUniversityUserId = () => async (dispatch) => {
    dispatch(resetRegisterSuccessUniversityUserId())
}

export const doResetResendVerificationEmailStateUniversity = () => async (dispatch) => {
    dispatch(resetResendVerificationEmailStateUniversity())
}

export const doReroutePublicTalengenUrl = (university_name) => async (dispatch) => {
    try{
        const response = await axios.get(`api/university/public-profile/${university_name}`)
        dispatch(doReroutePublicTalengenUrlSuccess(response.data))
    } catch (error) {
        dispatch(doReroutePublicTalengenUrlFail(error.response))
    }
}

export const doResetReroutePublicTalengenUrlState = () => async (dispatch) => {
    dispatch(resetReroutePublicTalengenUrlState())
}

export const {
    doCheckRegisterSuccessUniversity,
    doCheckRegisterFailUniversity,
    resetRegisterAvailableUniversity,
    doRegisterSuccessUniversity,
    doRegisterFailUniversity,
    setRegisterUrlParamUniversity,
    resetRegisterStateUniversity,
    resetRegisterUrlParamStateUniversity,
    doUpdateProfileSuccessUniversity,
    doUpdateProfileFailUniversity,
    doGetUniversityDetailSuccess,
    doGetUniversityDetailFail,
    doUpdateUniversitySuccess,
    doUpdateUniversityFail,
    resetUpdateProfileStateUniversity,
    doRegisterSuccessUniversityLeadingTeam,
    doRegisterFailUniversityLeadingTeam,
    resetRegisterStateUniversityLeadingTeam,
    doResendEmailVerificationSuccessUniversity,
    doResendEmailVerificationFailUniversity,
    resetRegisterSuccessUniversityUserId,
    resetResendVerificationEmailStateUniversity,
    doReroutePublicTalengenUrlSuccess,
    doReroutePublicTalengenUrlFail,
    resetReroutePublicTalengenUrlState,
} = UniversitySlide.actions

export const getRegisterAvailableUniversity = (state) => state.university?.registerAvailableUniversity
export const getRegisterAvailableFailUniversity = (state) => state.university?.registerAvailableFailUniversity
export const getRegisterAvailableErrorMessageUniversity = (state) => state.university?.registerAvailableErrorMessageUniversity
export const getRegisterSuccessUniversity = (state) => state.university?.registerSuccessUniversity
export const getRegisterFailUniversity = (state) => state.university?.registerFailUniversity
export const getRegisterErrorMessageUniversity = (state) => state.university?.registerErrorMessageUniversity
export const getRegisterResponseDataUniversity = (state) => state.university?.registerResponseDataUniversity
export const getRegisterUrlParamUniversity = (state) => state.university?.registerUrlParamUniversity
export const getRegisterSuccessUniversityUserId = (state) => state.university?.registerSuccessUniversityUserId

export const getUpdateProfileSuccessUniversity = (state) => state.university?.updateProfileSuccessUniversity
export const getUpdateProfileFailUniversity = (state) => state.university?.updateProfileFailUniversity
export const getUpdateProfileErrorMessageUniversity = (state) => state.university?.updateProfileErrorMessageUniversity

export const getUniversityDetail = (state) => state.university?.universityDetail

export const getUpdateUniversitySuccess = (state) => state.university?.updateUniversitySuccess
export const getUpdateUniversityFail = (state) => state.university?.updateUniversityFail
export const getUpdateUniversityError = (state) => state.university?.updateUniversityError

export const getRegisterSuccessUniversityLeadingTeam = (state) => state.university?.registerSuccessUniversityLeadingTeam
export const getRegisterFailUniversityLeadingTeam = (state) => state.university?.registerFailUniversityLeadingTeam
export const getRegisterErrorMessageUniversityLeadingTeam = (state) => state.university?.registerErrorMessageUniversityLeadingTeam
export const getRegisterResponseDataUniversityLeadingTeam = (state) => state.university?.registerResponseDataUniversityLeadingTeam
export const getRegisterUrlParamUniversityLeadingTeam = (state) => state.university?.registerUrlParamUniversityLeadingTeam

export const getResendVerificationEmailSuccessUniversity = (state) => state.university?.resendSuccess
export const getResendVerificationEmailFailUniversity = (state) => state.university?.resendFail

export const getReroutePublicTalengenUrlId = (state) => state.university?.rerouteId
export const getReroutePublicTalengenUrlSuccess = (state) => state.university?.rerouteSuccess
export const getReroutePublicTalengenUrlFail = (state) => state.university?.rerouteFail
export const getReroutePublicTalengenUrlError = (state) => state.university?.rerouteError

export default UniversitySlide.reducer
