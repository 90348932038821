import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"

export const talengenCareerSlide = createSlice({
    name: "talengenCareer",
    initialState: {
        talengenCareerList: [],
        talengenCareerListPage: 0,
        talengenCareerListPageCount: 0,

        talengenCareerDetail: null,

        applyTalengenCareerSuccess: false,
        applyTalengenCareerFail : false,
        applyTalengenCareerErrorDetail : null,

        talengenCareerApplicationList: [],

        talengenCareerApplicationDetail: null,
    },
    reducers: {
        doGetTalengenCareerListSuccess: (state, action) => {
            state.talengenCareerList = action.payload.data;
            state.talengenCareerListPage = action.payload?.current_page;
			state.talengenCareerListPageCount = action.payload?.page_count;
        },
        doGetTalengenCareerListFail: (state, action) => {
            state.talengenCareerList = [];
            state.talengenCareerListPage = 0;
			state.talengenCareerListPageCount = 0;
        },
        doGetTalengenCareerDetailSuccess: (state, action) => {
            state.talengenCareerDetail = action.payload.data
        },
        doGetTalengenCareerDetailFail: (state, action) => {
            state.talengenCareerDetail = null
        },
        doApplyTalengenCareerSuccess: (state, action) => {
            state.applyTalengenCareerSuccess = true
            state.applyTalengenCareerFail = false
            state.applyTalengenCareerErrorDetail = null
        },
        doApplyTalengenCareerFail: (state, action) => {
            state.applyTalengenCareerSuccess = false
            state.applyTalengenCareerFail = true
            state.applyTalengenCareerErrorDetail = action.payload.data
        },
        resetApplyTalengenCareerState: (state, action) => {
            state.applyTalengenCareerSuccess = false
            state.applyTalengenCareerFail = false
            state.applyTalengenCareerErrorDetail = null
        },
        doGetTalengenCareerApplicationListSuccess: (state, action) => {
            state.talengenCareerApplicationList = action.payload.data
        },
        doGetTalengenCareerApplicationListFail: (state, action) => {
            state.talengenCareerApplicationList = []
        },
        doGetTalengenCareerApplicationDetailSuccess: (state, action) => {
            state.talengenCareerApplicationDetail = action.payload.data
        },
        doGetTalengenCareerApplicationDetailFail: (state, action) => {
            state.talengenCareerApplicationDetail = null
        },
    },
})

export const doGetTalengenCareerList = (param) => async (dispatch) => {
    try {
        let url = `/api/talengen-career/list?page=${param.page}&size=${param.limit}`

        if (param.filter != null) {
            for (const field in param.filter) {
                const value = param.filter[field];
                if (value !== null && value !== '' && value !== undefined) {
                    url += `&${field}=${value}`;
                }
            }
        }
        const response = await axios.get(url)
        dispatch(doGetTalengenCareerListSuccess(response.data))
    } catch (error) {
        dispatch(doGetTalengenCareerListFail(error.response))
    }
}

export const doGetTalengenCareerDetail = (career_id) => async (dispatch) => {
    try {
        const url = `/api/talengen-career/detail/${career_id}`

        const response = await axios.get(url)
        dispatch(doGetTalengenCareerDetailSuccess(response.data))
    } catch (error) {
        dispatch(doGetTalengenCareerDetailFail(error.response))
    }
}

export const doApplyTalengenCareer = (param, career_id) => async (dispatch) => {
    try {
        let formData = new FormData();
        for (const field in param) {
            const value = param[field];
            if (field !== 'talengenCareerFile') {
                if(value !== undefined) {
                    formData.append(
                        field, value
                    );
                }
               
            }
        }

        if (param.talengenCareerFile !== null && param.talengenCareerFile !== "") {
            formData.append('talengenCareerFile', param.talengenCareerFile);
        }

        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }
        const url = `/api/talengen-career/detail/${career_id}/apply`

        const response = await axios.post(url, param, config)
        dispatch(doApplyTalengenCareerSuccess(response.data))
    } catch (error) {
        dispatch(doApplyTalengenCareerFail(error.response))
    }
}

export const doResetApplyTalengenCareerState = () => async (dispatch) => {
    dispatch(resetApplyTalengenCareerState());
}

export const doGetTalengenCareerApplicationList = () => async (dispatch) => {
    try {
        const url = `/api/talengen-career/application/list`

        const response = await axios.get(url)
        dispatch(doGetTalengenCareerApplicationListSuccess(response.data))
    } catch (error) {
        dispatch(doGetTalengenCareerApplicationListFail(error.response))
    }
}

export const doGetTalengenCareerApplicationDetail = (application_id) => async (dispatch) => {
    try {
        const url = `/api/talengen-career/application/detail/${application_id}`

        const response = await axios.get(url)
        dispatch(doGetTalengenCareerApplicationDetailSuccess(response.data))
    } catch (error) {
        dispatch(doGetTalengenCareerApplicationDetailFail(error.response))
    }
}

export const {
    doGetTalengenCareerListSuccess,
    doGetTalengenCareerListFail,
    doGetTalengenCareerDetailSuccess,
    doGetTalengenCareerDetailFail,
    doApplyTalengenCareerSuccess,
    doApplyTalengenCareerFail,
    resetApplyTalengenCareerState,
    doGetTalengenCareerApplicationListSuccess,
    doGetTalengenCareerApplicationListFail,
    doGetTalengenCareerApplicationDetailSuccess,
    doGetTalengenCareerApplicationDetailFail,
} = talengenCareerSlide.actions


export const getTalengenCareerList = (state) => state.talengenCareer?.talengenCareerList
export const getTalengenCareerListPage = (state) => state.talengenCareer?.talengenCareerListPage
export const getTalengenCareerListPageCount = (state) => state.talengenCareer?.talengenCareerListPageCount
export const getTalengenCareerDetail = (state) => state.talengenCareer?.talengenCareerDetail
export const getApplyTalengenCareerSuccess = (state) => state.talengenCareer?.applyTalengenCareerSuccess
export const getApplyTalengenCareerFail = (state) => state.talengenCareer?.applyTalengenCareerFail
export const getApplyTalengenCareerErrorDetail = (state) => state.talengenCareer?.applyTalengenCareerErrorDetail
export const getTalengenCareerApplicationList = (state) => state.talengenCareer?.talengenCareerApplicationList
export const getTalengenCareerApplicationDetail = (state) => state.talengenCareer?.talengenCareerApplicationDetail

export default talengenCareerSlide.reducer