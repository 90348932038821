import React, { useEffect, Suspense } from "react"
import { HashRouter, Route, Routes, Navigate, useLocation, useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import CircularProgress from "@mui/material/CircularProgress"
import Typography from "@mui/material/Typography"
import ReactGA from "react-ga"

import "./scss/style.scss"

import employerRoutes from "src/routes/employer_routes"
import studentRoutes from "src/routes/student_routes"
import univerityRoutes from "src/routes/university_routes"
import publicEventRoutes from "src/routes/public_event_routes"
import publicJobRoutes from "src/routes/public_job_routes"

import MobileAlphaReleaseDialog from "src/components/dialogs/MobileAlphaReleaseDialog"
import { SIGN_UP_MODE } from "./pages/sign_up/sign_up_constant"
import { getLoggedIn } from "src/reducers/reducer_core_login";
import { getIsLoading } from "./reducers/reducer_core"
import { IsNotDesktopView } from "./utils/functions"

function ScrollToTop() {
    const { pathname } = useLocation();
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  
    return null;
}

function MobileTabletNotReadyHover() {
    const navigate = useNavigate();
    const location = useLocation();

    const isNotDesktopView = IsNotDesktopView();
    const isWaitlistPath = location.pathname.startsWith('/waitlist');
    if (isNotDesktopView && !isWaitlistPath) {
        return <MobileAlphaReleaseDialog
            isOpen={true}
            handleClose={() => navigate('/waitlist')}
        />;
    }
    return null;
}

const loading = (
    <div className="h-100vh d-flex">
        <div className="d-flex flex-column m-auto">
            <CircularProgress />
            <Typography variant="caption" className="mt-3" color="primary">Loading...</Typography>
        </div>
    </div>
)

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"))

// Pages
// Sign Up Sign in
const SignUp = React.lazy(() => import("./pages/sign_up/SignUp"))
const SignUpAdmin = React.lazy(() => import("./pages/sign_up/admin/index"))
const SignIn = React.lazy(() => import("./pages/sign_in/SignIn"))
const SignUpLeadingTeamEmployer = React.lazy(() => import("./pages/sign_up/leading_team_employer"))
const SignUpLeadingTeamUniversity = React.lazy(() => import("./pages/sign_up/leading_team_university"))
const LeadingTeamAddedSuccessPage = React.lazy(() => import("./pages/leading_team_added_success"))

// Landing
const Landing = React.lazy(() => import("./pages/landing"))
const LandingStudent = React.lazy(() => import("./pages/landing_student"))
const LandingEmployer = React.lazy(() => import("./pages/landing_employer"))
const EmployerProduct = React.lazy(() => import("./pages/products/employer"))
const EmployerPricing = React.lazy(() => import("./pages/dashboard/employer/pricing/Pricing"))
const LandingUniversity = React.lazy(() => import("./pages/landing_university"))
const UniversityProduct = React.lazy(() => import("./pages/products/university"))

// 404
const Page404 = React.lazy(() => import("./pages/page404/Page404"))
const Page500 = React.lazy(() => import("./pages/page500/Page500"))

// Footers
const CareerStory = React.lazy(() => import("./pages/career/CareerStory"))
const CareerListing = React.lazy(() => import("./pages/career/career_listing/CareerListing"))
const CareerDetail = React.lazy(() => import("./pages/career/career_detail/CareerDetail"))
const CareerApplication = React.lazy(() => import("./pages/career/career_application/CareerApplication"))
const ContactUs = React.lazy(() => import("./pages/contact_us/ContactUs"))
const ContactUsMenu = React.lazy(() => import("./pages/contact_us/ContactUsMenu"))
const ContactUsNewBusiness = React.lazy(() => import("./pages/contact_us/ContactUsNewBusiness"))
const PrivacyPolicy = React.lazy(() => import("./pages/privacy_policy/PrivacyPolicy"))
const TermsOfService = React.lazy(() => import("./pages/terms_of_service/TermsOfService"))

// Others
const ForgotPassword = React.lazy(() => import("./pages/forgot-password/ForgotPassword"))
const ChangeProfilePicture = React.lazy(() => import("./pages/my_profile/components/forms/ChangeProfilePictureForm"))
const ResetPassword = React.lazy(() => import("./pages/reset_password/ResetPassword"))
const ToVerifyEmail = React.lazy(() => import("./pages/sign_up/verify_email/ToVerifyEmail"))
const VerifyEmail = React.lazy(() => import("./pages/sign_up/verify_email/VerifyEmail"))
const NotVerify = React.lazy(() => import("./pages/sign_in/not_verify/index"))
const PendingReview = React.lazy(() => import("./pages/sign_in/pending_review/index"))
const NotActive = React.lazy(() => import("./pages/sign_in/not_active/index"))

const ViewParticipantDetail = React.lazy(() => import("./pages/my_profile/components/forms/view_only/ViewOnlyStudentProfileForm"))
const Waitlist = React.lazy(() => import("./pages/waitlist/index"))


const App = () => {
    const loggedIn = useSelector(getLoggedIn);
    const isLoading = useSelector(getIsLoading);

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search)
    }, [])

    window.onunload = function () {
        window.scrollTo(0, 0);
    }

    return (
        <HashRouter>
            {isLoading && (
                <div
                    className="overlay"
                    style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: 9999, backgroundColor: 'rgba(255, 255, 255, 1)' }}
                >
                    <div className="d-flex flex-column h-100 justify-content-center align-items-center">
                    <CircularProgress />
                    <Typography variant="caption" className="mt-3" color="primary">
                        Loading...
                    </Typography>
                    </div>
                </div>
            )} 
            <ScrollToTop />
            <MobileTabletNotReadyHover />
            <Suspense fallback={loading}>
                <Routes>
                    <Route path="/" element={<Navigate to="/student" />} />
                    <Route exact path="/sign-in" name="Sign In Page" element={<SignIn />} />
                    <Route exact path="/sign-in/not-verify-email" name="Email Not Verify" element={<NotVerify />} />
                    <Route exact path="/sign-in/pending-review" name="Pending Review" element={<PendingReview />} />
                    <Route exact path="/sign-in/not-active" name="Deactivated" element={<NotActive />} />
                    <Route exact path="/sign-up" name="Sign Up Page" element={<SignUp mode={SIGN_UP_MODE.SIGN_UP}/>} />
                    <Route exact path="/sign-up/leading-team-employer" name="Sign Up Leading Team Employer" element={<SignUpLeadingTeamEmployer />} />
                    <Route exact path="/sign-up/leading-team-university" name="Sign Up Leading Team University" element={<SignUpLeadingTeamUniversity />} />
                    <Route exact path="/sign-up/student" name="Student Sign Up Page" element={<SignUp mode={SIGN_UP_MODE.STUDENT}/>} />
                    <Route exact path="/sign-up/employer" name="Employer Sign Up Page" element={<SignUp mode={SIGN_UP_MODE.EMPLOYER}/>} />
                    <Route exact path="/sign-up/university" name="University Sign Up Page" element={<SignUp mode={SIGN_UP_MODE.UNIVERSITY}/>} />
                    <Route exact path="/sign-up/admin" name="University Sign Up Page" element={<SignUpAdmin />} />
                    <Route exact path="/sign-up/to-verify-email" name="To Verify Email" element={<ToVerifyEmail />} />
                    <Route exact path="/sign-up/verify-email" name="Verify Email" element={<VerifyEmail />} />
                    <Route exact path="/my-profile/change-profile-picture" name="Change Password" element={<ChangeProfilePicture />} />
                    <Route exact path="/404" name="Page 404" element={<Page404 />} />
                    <Route exact path="/500" name="Page 500" element={<Page500 />} />
                    <Route exact path="/leading-team-added-success" name="LeadingTeamAddedSuccessPage" element={<LeadingTeamAddedSuccessPage />} />
                    <Route exact path="/career-story" name="Career Storytelling" element={<CareerStory />} />
                    <Route exact path="/career" name="Career" element={<CareerListing />} />
                    <Route exact path="/career/detail" name="Career" element={<CareerDetail />} />
                    <Route exact path="/career/application" name="Career" element={<CareerApplication />} />
                    <Route exact path="/contact-us" name="Contact Us" element={<ContactUs />} />
                    <Route exact path="/contact-us-menu" name="Contact Us" element={<ContactUsMenu />} />
                    <Route exact path="/contact-us-new-business" name="Contact Us" element={<ContactUsNewBusiness />} />
                    <Route exact path="/privacy-policy" name="Privacy Policy" element={<PrivacyPolicy />} />
                    <Route exact path="/terms-of-service" name="Terms of Service" element={<TermsOfService />} />
                    <Route exact path="/forgot-password" name="Forgot Password" element={<ForgotPassword />} />
                    <Route exact path="/view-participant-detail" name="View Detail" element={<ViewParticipantDetail />} />
                    <Route exact path="/waitlist" name="Waitlist" element={<Waitlist />} />
                    <Route
                        path="/reset-password"
                        name="Reset Password"
                        element={<ResetPassword />}
                    />
                    <Route path="/student" name="Student" element={<LandingStudent />} />
                    <Route path="/employer" name="Employer" element={<LandingEmployer />} />
                    <Route path="/employer/product" name="Employer Product" element={<EmployerProduct />} />
                    <Route path="/employer/pricing" name="Employer Pricing" element={<EmployerPricing />} />
                    <Route path="/university" name="University" element={<LandingUniversity />} />
                    <Route path="/university/product" name="University Product" element={<UniversityProduct />} />
                    <Route path="" name="Dashboard" element={<DefaultLayout />}>
                        {loggedIn?.users?.loggedInMode === "employer" ? 
                            employerRoutes?.map((item, index) => (
                                <Route
                                    key={index}
                                    path={item?.path}
                                    name={item?.name}
                                    element={<item.element />}
                                />
                            ))
                            : loggedIn?.users?.loggedInMode === "student" ? 
                                studentRoutes?.map((item, index) => (
                                    <Route
                                        key={index}
                                        path={item?.path}
                                        name={item?.name}
                                        element={<item.element />}
                                    />
                                ))
                            : univerityRoutes?.map((item, index) => (
                                <Route
                                    key={index}
                                    path={item?.path}
                                    name={item?.name}
                                    element={<item.element />}
                                />
                            ))
                        }
                        {publicEventRoutes.map((item, index) => (
                            <Route
                                key={index}
                                path={item?.path}
                                name={item?.name}
                                element={<item.element />}
                            />
                        ))}
                        {publicJobRoutes.map((item, index) => (
                            <Route
                                key={index}
                                path={item?.path}
                                name={item?.name}
                                element={<item.element />}
                            />
                        ))}
                    </Route>
                    <Route exact path="/landing" name="Home" element={<Landing />} />
                    <Route path="*" name="Not Found" element={<Page404 />} />
                </Routes>
            </Suspense>
        </HashRouter>
    )
}

export default App
