import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"

export const UpdatePasswordSlide = createSlice({
    name: "updatePassword",
    initialState: {
        updatePasswordSuccessStudent: false,
        updatePasswordFailStudent: false,
        updatePasswordFailedDetailStudent: null,
        updatePasswordSuccessEmployer: false,
        updatePasswordFailEmployer: false,
        updatePasswordFailedDetailEmployer: null,
        updatePasswordSuccessUniversity: false,
        updatePasswordFailUniversity: false,
        updatePasswordFailedDetailUniversity: null,
    },
    reducers: {
        // update password student
        doUpdatePasswordSuccessStudent: (state, action) => {
            state.updatePasswordSuccessStudent = true
            state.updatePasswordFailStudent = false
            state.updatePasswordFailedDetailStudent = null
        },
        doUpdatePasswordFailStudent: (state, action) => {
            state.updatePasswordSuccessStudent = false
            state.updatePasswordFailStudent = true
            state.updatePasswordFailedDetailStudent = action.payload.data
        },
        // update password employer
        doUpdatePasswordSuccessEmployer: (state, action) => {
            state.updatePasswordSuccessEmployer = true
            state.updatePasswordFailEmployer = false
            state.updatePasswordFailedDetailEmployer = null
        },
        doUpdatePasswordFailEmployer: (state, action) => {
            state.updatePasswordSuccessEmployer = false
            state.updatePasswordFailEmployer = true
            state.updatePasswordFailedDetailEmployer = action.payload.data
        },
        // update password university
        doUpdatePasswordSuccessUniversity: (state, action) => {
            state.updatePasswordSuccessUniversity = true
            state.updatePasswordFailUniversity = false
            state.updatePasswordFailedDetailUniversity = null
        },
        doUpdatePasswordFailUniversity: (state, action) => {
            state.updatePasswordSuccessUniversity = false
            state.updatePasswordFailUniversity = true
            state.updatePasswordFailedDetailUniversity = action.payload.data
        },

        // reset update password
        resetUpdatePasswordState: (state, action) => {
            state.updatePasswordSuccessStudent = false
            state.updatePasswordFailStudent = false
            state.updatePasswordFailedDetailStudent = null
            state.updatePasswordSuccessEmployer = false
            state.updatePasswordFailEmployer = false
            state.updatePasswordFailedDetailEmployer = null
            state.updatePasswordSuccessUniversity = false
            state.updatePasswordFailUniversity = false
            state.updatePasswordFailedDetailUniversity = null
        },
    },
})

export const doUpdatePasswordStudent = (param) => async (dispatch) => {
    try {
        let data = {
            current_password: param.current_password,
            password: param.new_password,
            confirm_password: param.confirm_new_password,
        }
        const url = `/api/student/auth/password`
        const response = await axios.put(url, data)
        dispatch(doUpdatePasswordSuccessStudent(response.data))
    } catch (error) {
        dispatch(doUpdatePasswordFailStudent(error.response))
    }
}

export const doUpdatePasswordEmployer = (param) => async (dispatch) => {
    try {
        let data = {
            current_password: param.current_password,
            password: param.new_password,
            confirm_password: param.confirm_new_password,
        }
        const url = `/api/employer_user/auth/password`
        const response = await axios.put(url, data)
        dispatch(doUpdatePasswordSuccessEmployer(response.data))
    } catch (error) {
        dispatch(doUpdatePasswordFailEmployer(error.response))
    }
}

export const doUpdatePasswordUniversity = (param) => async (dispatch) => {
    try {
        let data = {
            current_password: param.current_password,
            password: param.new_password,
            confirm_password: param.confirm_new_password,
        }
        const url = `/api/university_user/auth/password`
        const response = await axios.put(url, data)
        dispatch(doUpdatePasswordSuccessUniversity(response.data))
    } catch (error) {
        dispatch(doUpdatePasswordFailUniversity(error.response))
    }
}

// reset update password
export const doResetUpdatePasswordState = () => async (dispatch) => {
    dispatch(resetUpdatePasswordState(false))
}

export const {
    doUpdatePasswordSuccessStudent,
    doUpdatePasswordFailStudent,
    doUpdatePasswordSuccessEmployer,
    doUpdatePasswordFailEmployer,
    doUpdatePasswordSuccessUniversity,
    doUpdatePasswordFailUniversity,
    resetUpdatePasswordState,
} = UpdatePasswordSlide.actions

// update password student
export const getUpdatePasswordFailedDetailStudent = (state) => state.updatePassword?.updatePasswordFailedDetailStudent
export const getUpdatePasswordFailStudent = (state) => state.updatePassword?.updatePasswordFailStudent
export const getUpdatePasswordSuccessStudent = (state) => state.updatePassword?.updatePasswordSuccessStudent
// update password employer
export const getUpdatePasswordFailedDetailEmployer = (state) => state.updatePassword?.updatePasswordFailedDetailEmployer
export const getUpdatePasswordFailEmployer = (state) => state.updatePassword?.updatePasswordFailEmployer
export const getUpdatePasswordSuccessEmployer = (state) => state.updatePassword?.updatePasswordSuccessEmployer
// update password university
export const getUpdatePasswordFailedDetailUniversity = (state) => state.updatePassword?.updatePasswordFailedDetailUniversity
export const getUpdatePasswordFailUniversity = (state) => state.updatePassword?.updatePasswordFailUniversity
export const getUpdatePasswordSuccessUniversity = (state) => state.updatePassword?.updatePasswordSuccessUniversity

export default UpdatePasswordSlide.reducer
