const SIGN_UP_MODE = {
	SIGN_UP: 1,
    STUDENT: 2,
    EMPLOYER: 3,
    UNIVERSITY: 4,
}

const CATEGORIES = {
	EMPLOYER: 1,
    UNIVERSITY: 2,
    STUDENT: 3,
}

module.exports = {
    SIGN_UP_MODE,
    CATEGORIES,
}