import React from "react";

const EmployerPartnership = React.lazy(() => import("src/pages/dashboard/university/employer_partnership/index"))
const EmployerDetail = React.lazy(() => import("src/pages/dashboard/university/employer_partnership/employer_detail/EmployerDetail"))
const EmployerRequest = React.lazy(() => import("src/pages/dashboard/university/employer_partnership/employer_detail/employer_request/index"))
const ViewRequest = React.lazy(() => import("src/pages/dashboard/university/employer_partnership/employer_detail/employer_request/ViewRequest"))
const RequestStatus = React.lazy(() => import("src/pages/dashboard/university/employer_partnership/employer_detail/employer_request/RequestStatus"))
const ContactEmployer = React.lazy(() => import("src/pages/dashboard/university/employer_partnership/contact_employer/ContactEmployer"))

const routes = [
    { path: '/university/employer-partnership/request/status', name: 'Request Status', element: RequestStatus },
    { path: '/university/employer-partnership/request/view', name: 'View Request', element: ViewRequest },
    { path: '/university/employer-partnership/request', name: 'Request', element: EmployerRequest },
    { path: '/university/employer-partnership/detail', name: 'Detail', element: EmployerDetail },
    { path: '/university/employer-partnership', name: 'Employer Partership', element: EmployerPartnership },
    { path: '/university/employer-partnership/contact', name: 'Contact Employer', element: ContactEmployer },
];

export default routes;