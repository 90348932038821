import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"

export const notificationsEmployerSlide = createSlice({
    name: "notificationsEmployer",
    initialState: {
        notificationList: null,
        readNotificationSuccess: false,
        readNotificationFail: false,
        readNotificationErrorDetail: null,
        jobNotificationList: null,
        readJobNotificationSuccess: false,
        readJobNotificationFail: false,
        readJobNotificationErrorDetail: null,
    },
    reducers: {
        setNotificationList: (state, action) => {
            state.notificationList = action.payload.data
        },
        doReadNotificationSuccess: (state, action) => {
            state.readNotificationSuccess = true;
            state.readNotificationFail = false;
        },
        doReadNotificationFail: (state, action) => {
            state.readNotificationSuccess = false;
            state.readNotificationFail = true;
            state.readNotificationErrorDetail = action.payload.data;
        },
        resetReadNotificationState: (state, action) => {
            state.readNotificationSuccess = false;
            state.readNotificationFail = false;
            state.readNotificationErrorDetail = null;
        },
        setJobNotificationList: (state, action) => {
            state.jobNotificationList = action.payload?.data
        },
        doReadJobNotificationSuccess: (state, action) => {
            state.readJobNotificationSuccess = true;
            state.readJobNotificationFail = false;
        },
        doReadJobNotificationFail: (state, action) => {
            state.readJobNotificationSuccess = false;
            state.readJobNotificationFail = true;
            state.readJobNotificationErrorDetail = action.payload.data;
        },
        resetReadJobNotificationState: (state, action) => {
            state.readJobNotificationSuccess = false;
            state.readJobNotificationFail = false;
            state.readJobNotificationErrorDetail = null;
        },
    },
})

export const doGetNotificationList = (param) => async (dispatch) => {
    try {
        let url = `api/employer/notifications?page=${param.page}&size=${param.limit}`;

        const response = await axios.get(url);
        dispatch(setNotificationList({ data: response.data }))
    } catch (error) {
        dispatch(setNotificationList({ data: null }))
    }
}

export const doReadNotification = (notification_id) => async (dispatch) => {
    try {
        let url = `api/employer/notifications/read/${notification_id}`;
        const response = await axios.put(url);
        dispatch(doReadNotificationSuccess(response.data));
    } catch (error) {
        dispatch(doReadNotificationFail(error.response))
    }
}

export const doReadAllNotification = () => async (dispatch) => {
    try {
        let url = `api/employer/notifications/read-all`;
        const response = await axios.put(url);
        dispatch(doReadNotificationSuccess(response.data));
    } catch (error) {
        dispatch(doReadNotificationFail(error.response))
    }
}

export const doResetReadNotificationState = () => async (dispatch) => {
    dispatch(resetReadNotificationState())
}

export const doGetJobNotificationList = (param) => async (dispatch) => {
    try {
        let url = `api/employer/job-notifications?page=${param.page}&size=${param.limit}`;

        const response = await axios.get(url);
        dispatch(setJobNotificationList(response.data))
    } catch (error) {
        dispatch(setJobNotificationList(null))
    }
}

export const doReadJobNotification = (notification_id) => async (dispatch) => {
    try {
        let url = `api/employer/job-notifications/read/${notification_id}`;
        const response = await axios.put(url);
        dispatch(doReadJobNotificationSuccess(response.data));
    } catch (error) {
        dispatch(doReadJobNotificationFail(error.response))
    }
}

export const doReadAllJobNotification = () => async (dispatch) => {
    try {
        let url = `api/employer/job-notifications/read-all`;
        const response = await axios.put(url);
        dispatch(doReadJobNotificationSuccess(response.data));
    } catch (error) {
        dispatch(doReadJobNotificationFail(error.response))
    }
}

export const doResetReadJobNotificationState = () => async (dispatch) => {
    dispatch(resetReadJobNotificationState())
}

export const {
    setNotificationList,
    doReadNotificationSuccess,
    doReadNotificationFail,
    resetReadNotificationState,
    setJobNotificationList,
    doReadJobNotificationSuccess,
    doReadJobNotificationFail,
    resetReadJobNotificationState,
} = notificationsEmployerSlide.actions

export const getNotificationList = (state) => state.notificationsEmployer?.notificationList

export const getReadNotificationSuccess = (state) => state.notificationsEmployer?.readNotificationSuccess
export const getReadNotificationFail = (state) => state.notificationsEmployer?.readNotificationFail
export const getReadNotificationErrorDetail = (state) => state.notificationsEmployer?.readNotificationErrorDetail

export const getJobNotificationList = (state) => state.notificationsEmployer?.jobNotificationList

export const getReadJobNotificationSuccess = (state) => state.notificationsEmployer?.readJobNotificationSuccess
export const getReadJobNotificationFail = (state) => state.notificationsEmployer?.readJobNotificationFail
export const getReadJobNotificationErrorDetail = (state) => state.notificationsEmployer?.readJobNotificationErrorDetail

export default notificationsEmployerSlide.reducer