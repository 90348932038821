import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"

export const EmployerSlide = createSlice({
    name: "employer",
    initialState: {
        registerAvailableEmployer: null,
        registerAvailableFailEmployer: false,
        registerAvailableErrorMessageEmployer: null,
        registerSuccessEmployer: false,
        registerFailEmployer: false,
        registerErrorMessageEmployer: null,
        registerResponseDataEmployer: null,
        registerUrlParamEmployer: null,
        registerSuccessEmployerUserId: null,

        updateProfileSuccessEmployer: false,
        updateProfileFailEmployer: false,
        updateProfileErrorMessageEmployer: null,

        employerDetail: null,

        updateEmployerSuccess: false,
        updateEmployerFail: false,
        updateEmployerError: null,
        
        registerSuccessEmployerLeadingTeam: false,
        registerFailEmployerLeadingTeam: false,
        registerErrorMessageEmployerLeadingTeam: null,
        registerResponseDataEmployerLeadingTeam: null,
        registerUrlParamEmployerLeadingTeam: null,

        resendSuccess: false,
        resendFail: false,

        rerouteId: null,
        rerouteSuccess: false,
        rerouteFail: false,
        rerouteError: null,
    },
    reducers: {
        doCheckRegisterSuccessEmployer: (state, action) => {
            state.registerAvailableEmployer = action.payload.available
            state.registerAvailableFailEmployer = false
            state.registerAvailableErrorMessageEmployer = null
        },
        doCheckRegisterFailEmployer: (state, action) => {
            state.registerAvailableEmployer = null
            state.registerAvailableFailEmployer = true
            state.registerAvailableErrorMessageEmployer = action.payload.data
        },
        resetRegisterAvailableEmployer: (state, action) => {
            state.registerAvailableEmployer = null
            state.registerAvailableFailEmployer = false
            state.registerAvailableErrorMessageEmployer = null
        },
        doRegisterSuccessEmployer: (state, action) => {
            state.registerSuccessEmployer = true
            state.registerFailEmployer = false
            state.registerErrorMessageEmployer = null
            state.registerResponseDataEmployer = action.payload.data
            state.registerSuccessEmployerUserId = action.payload?.employer_user_id
        },
        doRegisterFailEmployer: (state, action) => {
            state.registerSuccessEmployer = false
            state.registerFailEmployer = true
            state.registerErrorMessageEmployer = action.payload.data
        },
        setRegisterUrlParamEmployer: (state, action) => {
            state.registerUrlParamEmployer = action.payload
        },
        //
        resetRegisterStateEmployer: (state, action) => {
            state.registerSuccessEmployer = action.payload
            state.registerFailEmployer = action.payload
            state.registerErrorMessageEmployer = null
            state.registerResponseDataEmployer = null
        },
        resetRegisterUrlParamStateEmployer: (state, action) => {
            state.registerUrlParamEmployer = null
        },
        doUpdateProfileSuccessEmployer: (state, action) => {
            state.updateProfileSuccessEmployer = true
            state.updateProfileFailEmployer = false
            state.updateProfileErrorMessageEmployer = null
        },
        doUpdateProfileFailEmployer: (state, action) => {
            state.updateProfileSuccessEmployer = false
            state.updateProfileFailEmployer = true
            state.updateProfileErrorMessageEmployer = action.payload.data
        },
        doGetEmployerDetailSuccess: (state, action) => {
            state.employerDetail = action.payload.data
        },
        doGetEmployerDetailFail: (state, action) => {
            state.employerDetail = null
        },
        doUpdateEmployerSuccess: (state, action) => {
            state.updateEmployerSuccess = true
            state.updateEmployerFail = false
            state.updateEmployerError = null
        },
        doUpdateEmployerFail: (state, action) => {
            state.updateEmployerSuccess = false
            state.updateEmployerFail = true
            state.updateEmployerError = action.payload.data
        },
        resetUpdateProfileStateEmployer: (state, action) => {
            state.updateProfileSuccessEmployer = action.payload
            state.updateProfileFailEmployer = action.payload
            state.updateProfileErrorMessageEmployer = null
            state.updateEmployerSuccess = action.payload
            state.updateEmployerFail = action.payload
            state.updateEmployerError = null
        },
        doRegisterSuccessEmployerLeadingTeam: (state, action) => {
            state.registerSuccessEmployerLeadingTeam = true
            state.registerFailEmployerLeadingTeam = false
            state.registerErrorMessageEmployerLeadingTeam = null
            state.registerResponseDataEmployerLeadingTeam = action.payload.data
        },
        doRegisterFailEmployerLeadingTeam: (state, action) => {
            state.registerSuccessEmployerLeadingTeam = false
            state.registerFailEmployerLeadingTeam = true
            state.registerErrorMessageEmployerLeadingTeam = action.payload.data
        },
        resetRegisterStateEmployerLeadingTeam: (state, action) => {
            state.registerSuccessEmployerLeadingTeam = action.payload
            state.registerFailEmployerLeadingTeam = action.payload
            state.registerErrorMessageEmployerLeadingTeam = null
            state.registerResponseDataEmployerLeadingTeam = null
        },
        doResendEmailVerificationSuccessEmployer: (state, action) => {
            state.resendSuccess = true
            state.resendFail = false
        },
        doResendEmailVerificationFailEmployer: (state, action) => {
            state.resendSuccess = false
            state.resendFail = true
        },
        resetRegisterSuccessEmployerUserId: (state, action) => {
            state.registerSuccessEmployerUserId = null
        },
        resetResendVerificationEmailStateEmployer: (state, action) => {
            state.resendSuccess = false
            state.resendFail = false
        },
        doReroutePublicTalengenUrlSuccess: (state, action) => {
            state.rerouteId = action.payload?.id
            state.rerouteSuccess = true
            state.rerouteFail = false
            state.rerouteError = null
        },
        doReroutePublicTalengenUrlFail: (state, action) => {
            state.rerouteId = null
            state.rerouteSuccess = false
            state.rerouteFail = true
            state.rerouteError = action.payload.data
        },
        resetReroutePublicTalengenUrlState: (state, action) => {
            state.rerouteId = null
            state.rerouteSuccess = false
            state.rerouteFail = false
            state.rerouteError = null
        },
    },
})

export const doCheckRegisterEmployer = (param) => async (dispatch) => {
    try {
        let data = param
        const url = `/api/employer_user/auth/check`
        const response = await axios.post(url, data)

        dispatch(doCheckRegisterSuccessEmployer(response.data))
    } catch (error) {
        dispatch(doCheckRegisterFailEmployer(error.response))
    }
}

export const doRegisterEmployer = (param) => async (dispatch) => {
    try {
        let data = param
        const url = `/api/employer/auth/register`
        const response = await axios.post(url, data)

        dispatch(doRegisterSuccessEmployer(response.data))
    } catch (error) {
        dispatch(doRegisterFailEmployer(error.response))
    }
}

export const doUpdateProfileEmployer = (param) => async (dispatch) => {
    try {
        let data = param
        const url = `/api/employer_user/auth/me`
        const response = await axios.put(url, data)

        dispatch(doUpdateProfileSuccessEmployer(response.data))
    } catch (error) {
        dispatch(doUpdateProfileFailEmployer(error.response))
    }
}

export const doGetEmployerDetail = (param) => async (dispatch) => {
    try {
        const url = `/api/admin/company-profile`
        const response = await axios.get(url)

        dispatch(doGetEmployerDetailSuccess(response.data))
    } catch (error) {
        dispatch(doGetEmployerDetailFail(error.response))
    }
}

export const doUpdateEmployer = (param) => async (dispatch) => {
    try {
        let formData = new FormData();
        for (const field in param) {
            const value = param[field];
            if (field !== 'logoFile') {
                formData.append(
                    field, value
                );
            }
        }

        if (param.logoFile !== null && param.logoFile !== "") {
            formData.append('logoFile', param.logoFile);
        }

        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }
        
        const url = `/api/admin/company-profile`
        const response = await axios.put(url, formData, config)

        dispatch(doUpdateEmployerSuccess(response.data))
    } catch (error) {
        dispatch(doUpdateEmployerFail(error.response))
    }
}

export const doSetRegisterUrlParamEmployer = (param) => async (dispatch) => {
    dispatch(setRegisterUrlParamEmployer(param))
}

export const doResetRegisterAvailableEmployer = () => async (dispatch) => {
    dispatch(resetRegisterAvailableEmployer(false))
}

export const doResetRegisterStateEmployer = () => async (dispatch) => {
    dispatch(resetRegisterStateEmployer(false))
}

export const doResetRegisterUrlParamStateEmployer = () => async (dispatch) => {
    dispatch(resetRegisterUrlParamStateEmployer(false))
}

export const doResetUpdateProfileStateEmployer = () => async (dispatch) => {
    dispatch(resetUpdateProfileStateEmployer(false))
}

export const doRegisterEmployerLeadingTeam = (param) => async (dispatch) => {
    try {
        let data = param
        const url = `/api/employer_user/auth/register`
        const response = await axios.post(url, data)

        dispatch(doRegisterSuccessEmployerLeadingTeam(response.data))
    } catch (error) {
        dispatch(doRegisterFailEmployerLeadingTeam(error.response))
    }
}

export const doResetRegisterStateEmployerLeadingTeam = () => async (dispatch) => {
    dispatch(resetRegisterStateEmployerLeadingTeam(false))
}

export const doResendEmailVerificationEmployer = (param) => async (dispatch) => {
    try {
        const response = await axios.post("api/employer_user/auth/send-email-verification", param)
        dispatch(doResendEmailVerificationSuccessEmployer(response.data))
    } catch (error) {
        dispatch(doResendEmailVerificationFailEmployer(error.response))
    }
}

export const doResetRegisterSuccessEmployerUserId = () => async (dispatch) => {
    dispatch(resetRegisterSuccessEmployerUserId())
}

export const doResetResendVerificationEmailStateEmployer = () => async (dispatch) => {
    dispatch(resetResendVerificationEmailStateEmployer())
}

export const doReroutePublicTalengenUrl = (employer_name) => async (dispatch) => {
    try{
        const response = await axios.get(`api/employer/public-profile/${employer_name}`)
        dispatch(doReroutePublicTalengenUrlSuccess(response.data))
    } catch (error) {
        dispatch(doReroutePublicTalengenUrlFail(error.response))
    }
}

export const doResetReroutePublicTalengenUrlState = () => async (dispatch) => {
    dispatch(resetReroutePublicTalengenUrlState())
}

export const {
    doCheckRegisterSuccessEmployer,
    doCheckRegisterFailEmployer,
    resetRegisterAvailableEmployer,
    doRegisterSuccessEmployer,
    doRegisterFailEmployer,
    setRegisterUrlParamEmployer,
    resetRegisterStateEmployer,
    resetRegisterUrlParamStateEmployer,
    doUpdateProfileSuccessEmployer,
    doUpdateProfileFailEmployer,
    doGetEmployerDetailSuccess,
    doGetEmployerDetailFail,
    doUpdateEmployerSuccess,
    doUpdateEmployerFail,
    resetUpdateProfileStateEmployer,
    doRegisterSuccessEmployerLeadingTeam,
    doRegisterFailEmployerLeadingTeam,
    resetRegisterStateEmployerLeadingTeam,
    doResendEmailVerificationSuccessEmployer,
    doResendEmailVerificationFailEmployer,
    resetRegisterSuccessEmployerUserId,
    resetResendVerificationEmailStateEmployer,
    doReroutePublicTalengenUrlSuccess,
    doReroutePublicTalengenUrlFail,
    resetReroutePublicTalengenUrlState,
} = EmployerSlide.actions

export const getRegisterAvailableEmployer = (state) => state.employer?.registerAvailableEmployer
export const getRegisterAvailableFailEmployer = (state) => state.employer?.registerAvailableFailEmployer
export const getRegisterAvailableErrorMessageEmployer = (state) => state.employer?.registerAvailableErrorMessageEmployer
export const getRegisterSuccessEmployer = (state) => state.employer?.registerSuccessEmployer
export const getRegisterFailEmployer = (state) => state.employer?.registerFailEmployer
export const getRegisterErrorMessageEmployer = (state) => state.employer?.registerErrorMessageEmployer
export const getRegisterResponseDataEmployer = (state) => state.employer?.registerResponseDataEmployer
export const getRegisterUrlParamEmployer = (state) => state.employer?.registerUrlParamEmployer
export const getRegisterSuccessEmployerUserId = (state) => state.employer?.registerSuccessEmployerUserId

export const getUpdateProfileSuccessEmployer = (state) => state.employer?.updateProfileSuccessEmployer
export const getUpdateProfileFailEmployer = (state) => state.employer?.updateProfileFailEmployer
export const getUpdateProfileErrorMessageEmployer = (state) => state.employer?.updateProfileErrorMessageEmployer

export const getEmployerDetail = (state) => state.employer?.employerDetail

export const getUpdateEmployerSuccess = (state) => state.employer?.updateEmployerSuccess
export const getUpdateEmployerFail = (state) => state.employer?.updateEmployerFail
export const getUpdateEmployerError = (state) => state.employer?.updateEmployerError

export const getRegisterSuccessEmployerLeadingTeam = (state) => state.employer?.registerSuccessEmployerLeadingTeam
export const getRegisterFailEmployerLeadingTeam = (state) => state.employer?.registerFailEmployerLeadingTeam
export const getRegisterErrorMessageEmployerLeadingTeam = (state) => state.employer?.registerErrorMessageEmployerLeadingTeam
export const getRegisterResponseDataEmployerLeadingTeam = (state) => state.employer?.registerResponseDataEmployerLeadingTeam
export const getRegisterUrlParamEmployerLeadingTeam = (state) => state.employer?.registerUrlParamEmployerLeadingTeam

export const getResendVerificationEmailSuccessEmployer = (state) => state.employer?.resendSuccess
export const getResendVerificationEmailFailEmployer = (state) => state.employer?.resendFail

export const getReroutePublicTalengenUrlId = (state) => state.employer?.rerouteId
export const getReroutePublicTalengenUrlSuccess = (state) => state.employer?.rerouteSuccess
export const getReroutePublicTalengenUrlFail = (state) => state.employer?.rerouteFail
export const getReroutePublicTalengenUrlError = (state) => state.employer?.rerouteError

export default EmployerSlide.reducer
