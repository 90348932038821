import React from "react";

const YourEvent = React.lazy(() => import("src/pages/dashboard/employer/your_event/index"))
const YourEventDetail = React.lazy(() => import("src/pages/dashboard/employer/your_event/your_event_detail/YourEventDetail"))
const YourEventRegister = React.lazy(() => import("src/pages/dashboard/employer/your_event/your_event_detail/your_event_register/index"))

const routes = [
    { path: '/employer/your-event/register', name: 'Register', element: YourEventRegister },
    { path: '/employer/your-event/detail', name: 'Detail', element: YourEventDetail },
    { path: '/employer/your-event', name: 'Your Event', element: YourEvent },
];

export default routes;