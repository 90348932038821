import React from "react";

const EventListing = React.lazy(() => import("src/pages/dashboard/student/events/event_listing/EventListing"))
const LikedListing = React.lazy(() => import("src/pages/dashboard/student/events/event_listing/LikedListing"))
const RegisteredListing = React.lazy(() => import("src/pages/dashboard/student/events/event_listing/RegisteredListing"))
const EventDetail = React.lazy(() => import("src/pages/dashboard/student/events/event_detail/EventDetail"))
const EmployerDetail = React.lazy(() => import("src/pages/dashboard/student/events/event_detail/employer_detail/index"))
const EmployerListing = React.lazy(() => import("src/pages/dashboard/student/events/event_detail/employer_listing/index"))
const UniversityDetail  = React.lazy(() => import("src/pages/dashboard/student/events/event_detail/university_detail/index"))

const routes = [
    { path: '/student/events/view-organization/listing', name: 'View', element: EmployerListing },
    { path: '/student/events/view-organization', name: 'View', element: EmployerDetail },
    { path: '/student/events/view-university', name: 'View', element: UniversityDetail },
    { path: '/student/events/detail', name: 'Detail', element: EventDetail },
    { path: '/student/events/liked', name: 'Events', element: LikedListing },
    { path: '/student/events/registered', name: 'Events', element: RegisteredListing },
    { path: '/student/events', name: 'Events', element: EventListing },
];

export default routes;