import student_dashboard_routes from "./student_modules/student_dashboard_routes";
import student_event_routes from "./student_modules/student_event_routes";
import student_job_routes from "./student_modules/student_job_routes";
import student_my_profile_routes from "./student_modules/student_my_profile_routes";

const routes = [
    ...student_dashboard_routes,
    ...student_event_routes,
    ...student_job_routes,
    ...student_my_profile_routes,
];

export default routes;