import React from "react";

const MyProfile = React.lazy(() => import("src/pages/my_profile/MyProfile"))
const ChangePassword = React.lazy(() => import("src/pages/my_profile/components/forms/ChangePasswordForm"))

const routes = [
    { path: '/student/my-profile', name: 'My Profile', element: MyProfile },
    { path: '/student/my-profile/change-password', name: 'Change Password', element: ChangePassword },
];

export default routes;