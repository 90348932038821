import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"

export const ProfilePictureSlide = createSlice({
    name: "profilePicture",
    initialState: {
        // update profile picture
        updateProfilePictureSuccessStudent: false,
        updateProfilePictureFailStudent: false,
        updateProfilePictureFailedDetailStudent: null,
        updateProfilePictureSuccessEmployer: false,
        updateProfilePictureFailEmployer: false,
        updateProfilePictureFailedDetailEmployer: null,
        updateProfilePictureSuccessUniversity: false,
        updateProfilePictureFailUniversity: false,
        updateProfilePictureFailedDetailUniversity: null,

        // delete profile picture
        deleteProfilePictureSuccessStudent: false,
        deleteProfilePictureFailStudent: false,
        deleteProfilePictureFailedDetailStudent: null,
        deleteProfilePictureSuccessEmployer: false,
        deleteProfilePictureFailEmployer: false,
        deleteProfilePictureFailedDetailEmployer: null,
        deleteProfilePictureSuccessUniversity: false,
        deleteProfilePictureFailUniversity: false,
        deleteProfilePictureFailedDetailUniversity: null,
    },
    reducers: {
        // update profile picture student
        doUpdateProfilePictureSuccessStudent: (state, action) => {
            state.updateProfilePictureSuccessStudent = true
            state.updateProfilePictureFailStudent = false
            state.updateProfilePictureFailedDetailStudent = null
        },
        doUpdateProfilePictureFailStudent: (state, action) => {
            state.updateProfilePictureSuccessStudent = false
            state.updateProfilePictureFailStudent = true
            state.updateProfilePictureFailedDetailStudent = action.payload.data
        },
        // update profile picture employer
        doUpdateProfilePictureSuccessEmployer: (state, action) => {
            state.updateProfilePictureSuccessEmployer = true
            state.updateProfilePictureFailEmployer = false
            state.updateProfilePictureFailedDetailEmployer = null
        },
        doUpdateProfilePictureFailEmployer: (state, action) => {
            state.updateProfilePictureSuccessEmployer = false
            state.updateProfilePictureFailEmployer = true
            state.updateProfilePictureFailedDetailEmployer = action.payload.data
        },
        // update profile picture university
        doUpdateProfilePictureSuccessUniversity: (state, action) => {
            state.updateProfilePictureSuccessUniversity = true
            state.updateProfilePictureFailUniversity = false
            state.updateProfilePictureFailedDetailUniversity = null
        },
        doUpdateProfilePictureFailUniversity: (state, action) => {
            state.updateProfilePictureSuccessUniversity = false
            state.updateProfilePictureFailUniversity = true
            state.updateProfilePictureFailedDetailUniversity = action.payload.data
        },

        // reset update profile picture
        resetUpdateProfilePictureState: (state, action) => {
            state.updateProfilePictureSuccessStudent = false
            state.updateProfilePictureFailStudent = false
            state.updateProfilePictureFailedDetailStudent = null
            state.updateProfilePictureSuccessEmployer = false
            state.updateProfilePictureFailEmployer = false
            state.updateProfilePictureFailedDetailEmployer = null
            state.updateProfilePictureSuccessUniversity = false
            state.updateProfilePictureFailUniversity = false
            state.updateProfilePictureFailedDetailUniversity = null
        },

        
        // delete profile picture student
        doDeleteProfilePictureSuccessStudent: (state, action) => {
            state.deleteProfilePictureSuccessStudent = true
            state.deleteProfilePictureFailStudent = false
            state.deleteProfilePictureFailedDetailStudent = null
        },
        doDeleteProfilePictureFailStudent: (state, action) => {
            state.deleteProfilePictureSuccessStudent = false
            state.deleteProfilePictureFailStudent = true
            state.deleteProfilePictureFailedDetailStudent = action.payload.data
        },
        // delete profile picture employer
        doDeleteProfilePictureSuccessEmployer: (state, action) => {
            state.deleteProfilePictureSuccessEmployer = true
            state.deleteProfilePictureFailEmployer = false
            state.deleteProfilePictureFailedDetailEmployer = null
        },
        doDeleteProfilePictureFailEmployer: (state, action) => {
            state.deleteProfilePictureSuccessEmployer = false
            state.deleteProfilePictureFailEmployer = true
            state.deleteProfilePictureFailedDetailEmployer = action.payload.data
        },
        // delete profile picture university
        doDeleteProfilePictureSuccessUniversity: (state, action) => {
            state.deleteProfilePictureSuccessUniversity = true
            state.deleteProfilePictureFailUniversity = false
            state.deleteProfilePictureFailedDetailUniversity = null
        },
        doDeleteProfilePictureFailUniversity: (state, action) => {
            state.deleteProfilePictureSuccessUniversity = false
            state.deleteProfilePictureFailUniversity = true
            state.deleteProfilePictureFailedDetailUniversity = action.payload.data
        },

        // reset delete profile picture
        resetDeleteProfilePictureState: (state, action) => {
            state.deleteProfilePictureSuccessStudent = false
            state.deleteProfilePictureFailStudent = false
            state.deleteProfilePictureFailedDetailStudent = null
            state.deleteProfilePictureSuccessEmployer = false
            state.deleteProfilePictureFailEmployer = false
            state.deleteProfilePictureFailedDetailEmployer = null
            state.deleteProfilePictureSuccessUniversity = false
            state.deleteProfilePictureFailUniversity = false
            state.deleteProfilePictureFailedDetailUniversity = null
        },
    },
})

export const doUpdateProfilePictureStudent = (param) => async (dispatch) => {
    try {
        let formData = new FormData();

        if (param.studentFile !== null && param.studentFile !== "") {
            formData.append('studentFile', param.studentFile);
        }

        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }

        const url = `/api/student/auth/me/profile_pic`
        const response = await axios.post(url, formData, config);
        dispatch(doUpdateProfilePictureSuccessStudent(response.data))
    } catch (error) {
        dispatch(doUpdateProfilePictureFailStudent(error.response))
    }
}

export const doUpdateProfilePictureEmployer = (param) => async (dispatch) => {
    try {
        let formData = new FormData();

        if (param.employerUserFile !== null && param.employerUserFile !== "" && param.employerUserFile !== undefined) {
            formData.append('employerUserFile', param.employerUserFile);
        }

        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }

        const url = `/api/employer_user/auth/me/profile_pic`
        const response = await axios.post(url, formData, config);
        dispatch(doUpdateProfilePictureSuccessEmployer(response.data))
    } catch (error) {
        dispatch(doUpdateProfilePictureFailEmployer(error.response))
    }
}

export const doUpdateProfilePictureUniversity = (param) => async (dispatch) => {
    try {
        let formData = new FormData();

        if (param.universityUserFile !== null && param.universityUserFile !== "" && param.universityUserFile !== undefined) {
            formData.append('universityUserFile', param.universityUserFile);
        }

        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }

        const url = `/api/university_user/auth/me/profile_pic`
        const response = await axios.post(url, formData, config);
        dispatch(doUpdateProfilePictureSuccessUniversity(response.data))
    } catch (error) {
        dispatch(doUpdateProfilePictureFailUniversity(error.response))
    }
}

// reset update profile picture
export const doResetUpdateProfilePictureState = () => async (dispatch) => {
    dispatch(resetUpdateProfilePictureState(false))
}

export const doDeleteProfilePictureStudent = (param) => async (dispatch) => {
    try {
        const url = `/api/student/auth/me/delete_profile_pic`
        const response = await axios.post(url);
        dispatch(doDeleteProfilePictureSuccessStudent(response.data))
    } catch (error) {
        dispatch(doDeleteProfilePictureFailStudent(error.response))
    }
}

export const doDeleteProfilePictureEmployer = (param) => async (dispatch) => {
    try {
        const url = `/api/employer_user/auth/me/delete_profile_pic`
        const response = await axios.post(url);
        dispatch(doDeleteProfilePictureSuccessEmployer(response.data))
    } catch (error) {
        dispatch(doDeleteProfilePictureFailEmployer(error.response))
    }
}

export const doDeleteProfilePictureUniversity = () => async (dispatch) => {
    try {
        const url = `/api/university_user/auth/me/delete_profile_pic`
        const response = await axios.post(url);
        dispatch(doDeleteProfilePictureSuccessUniversity(response.data))
    } catch (error) {
        dispatch(doDeleteProfilePictureFailUniversity(error.response))
    }
}

// reset delete profile picture
export const doResetDeleteProfilePictureState = () => async (dispatch) => {
    dispatch(resetDeleteProfilePictureState(false))
}

export const {
    doUpdateProfilePictureSuccessStudent,
    doUpdateProfilePictureFailStudent,
    doUpdateProfilePictureSuccessEmployer,
    doUpdateProfilePictureFailEmployer,
    doUpdateProfilePictureSuccessUniversity,
    doUpdateProfilePictureFailUniversity,
    resetUpdateProfilePictureState,
    doDeleteProfilePictureSuccessStudent,
    doDeleteProfilePictureFailStudent,
    doDeleteProfilePictureSuccessEmployer,
    doDeleteProfilePictureFailEmployer,
    doDeleteProfilePictureSuccessUniversity,
    doDeleteProfilePictureFailUniversity,
    resetDeleteProfilePictureState,
} = ProfilePictureSlide.actions

// update profile picture student
export const getUpdateProfilePictureFailedDetailStudent = (state) => state.profilePicture?.updateProfilePictureFailedDetailStudent
export const getUpdateProfilePictureFailStudent = (state) => state.profilePicture?.updateProfilePictureFailStudent
export const getUpdateProfilePictureSuccessStudent = (state) => state.profilePicture?.updateProfilePictureSuccessStudent
// update profile picture employer
export const getUpdateProfilePictureFailedDetailEmployer = (state) => state.profilePicture?.updateProfilePictureFailedDetailEmployer
export const getUpdateProfilePictureFailEmployer = (state) => state.profilePicture?.updateProfilePictureFailEmployer
export const getUpdateProfilePictureSuccessEmployer = (state) => state.profilePicture?.updateProfilePictureSuccessEmployer
// update profile picture university
export const getUpdateProfilePictureFailedDetailUniversity = (state) => state.profilePicture?.updateProfilePictureFailedDetailUniversity
export const getUpdateProfilePictureFailUniversity = (state) => state.profilePicture?.updateProfilePictureFailUniversity
export const getUpdateProfilePictureSuccessUniversity = (state) => state.profilePicture?.updateProfilePictureSuccessUniversity

// delete profile picture student
export const getDeleteProfilePictureFailedDetailStudent = (state) => state.profilePicture?.deleteProfilePictureFailedDetailStudent
export const getDeleteProfilePictureFailStudent = (state) => state.profilePicture?.deleteProfilePictureFailStudent
export const getDeleteProfilePictureSuccessStudent = (state) => state.profilePicture?.deleteProfilePictureSuccessStudent
// delete profile picture employer
export const getDeleteProfilePictureFailedDetailEmployer = (state) => state.profilePicture?.deleteProfilePictureFailedDetailEmployer
export const getDeleteProfilePictureFailEmployer = (state) => state.profilePicture?.deleteProfilePictureFailEmployer
export const getDeleteProfilePictureSuccessEmployer = (state) => state.profilePicture?.deleteProfilePictureSuccessEmployer
// delete profile picture university
export const getDeleteProfilePictureFailedDetailUniversity = (state) => state.profilePicture?.deleteProfilePictureFailedDetailUniversity
export const getDeleteProfilePictureFailUniversity = (state) => state.profilePicture?.deleteProfilePictureFailUniversity
export const getDeleteProfilePictureSuccessUniversity = (state) => state.profilePicture?.deleteProfilePictureSuccessUniversity

export default ProfilePictureSlide.reducer
