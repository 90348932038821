import React from "react";

const PublicJobListing = React.lazy(() => import("src/pages/dashboard/public_jobs/PublicJobListing"))
const PublicEvent = React.lazy(() => import("src/pages/dashboard/public_jobs/public_job_detail/PublicJobDetail"))
const PublicJobEmployerProfile = React.lazy(() => import("src/pages/dashboard/public_jobs/public_job_detail/employer_profile/PublicJobEmployerProfile"))


const routes = [
    { path: '/public-jobs', name: 'Jobs', element: PublicJobListing },
    { path: '/public-jobs/detail', name: 'Detail', element: PublicEvent },
    { path: '/public-jobs/employer-profile', name: 'Employer Profile', element: PublicJobEmployerProfile },
];

export default routes;