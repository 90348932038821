import React from "react";

const CreateEvent = React.lazy(() => import('src/pages/dashboard/university/create_event/components/forms/CreateEventForm'));
const EventDashboard = React.lazy(() => import('src/pages/dashboard/university/your_event/event_detail/UniversityEventDashboard'));
const EditEvent = React.lazy(() => import('src/pages/dashboard/university/edit_event/index'));

const routes = [
    { path: '/university/event/create', name: 'Create an Event', element: CreateEvent },
    { path: '/university/event/detail', name: 'Event Dashboard', element: EventDashboard },
    { path: '/university/event/edit', name: 'Edit Event', element: EditEvent },
];

export default routes;