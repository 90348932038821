import React from "react";

const FairAndEvent = React.lazy(() => import("src/pages/dashboard/employer/fair_and_event"))
const FairAndEventDetail = React.lazy(() => import("src/pages/dashboard/employer/fair_and_event/fair_and_event_detail/FairAndEventDetail"))
const FairAndEventRegister = React.lazy(() => import("src/pages/dashboard/employer/fair_and_event/fair_and_event_detail/fair_and_event_register/index"))
const FairAndEventStatus = React.lazy(() => import("src/pages/dashboard/employer/fair_and_event/fair_and_event_detail/fair_and_event_status/index"))

const routes = [
    { path: '/employer/fair-and-event/status', name: 'Status', element: FairAndEventStatus },
    { path: '/employer/fair-and-event/register', name: 'Register', element: FairAndEventRegister },
    { path: '/employer/fair-and-event/detail', name: 'Detail', element: FairAndEventDetail },
    { path: '/employer/fair-and-event', name: 'Fair and Event', element: FairAndEvent },

];

export default routes;