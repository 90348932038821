import React from "react";

const PublicEvent = React.lazy(() => import("src/pages/dashboard/public_events/EventDetail"))
const EmployerDetail = React.lazy(() => import("src/pages/dashboard/public_events/employer_detail/index"))
const EmployerListing = React.lazy(() => import("src/pages/dashboard/public_events/employer_listing/index"))

const PublicUniversityProfile = React.lazy(() => import("src/pages/dashboard/university/public_profile/PublicProfile"))

const PublicEmployerProfile = React.lazy(() => import("src/pages/dashboard/employer/public_profile/PublicProfile"))


const routes = [
    { path: '/public-event', name: 'Student Public Event', element: PublicEvent },
    { path: '/public-event/view-organization', name: 'View', element: EmployerDetail },
    { path: '/public-event/view-organization/listing', name: 'View', element: EmployerListing },

    { path: '/employer/:employer_name', name: 'Employer Profile', element: PublicEmployerProfile },
    { path: '/university/:university_name', name: 'University Profile', element: PublicUniversityProfile },
];

export default routes;