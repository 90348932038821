import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"

export const notificationsStudentSlide = createSlice({
    name: "notificationsStudent",
    initialState: {
        notificationList: null,
        readNotificationSuccess: false,
        readNotificationFail: false,
        readNotificationErrorDetail: null,
        starNotificationSuccess: false,
        starNotificationFail: false,
        starNotificationErrorDetail: null,
        unstarNotificationSuccess: false,
        unstarNotificationFail: false,
        unstarNotificationErrorDetail: null,
    },
    reducers: {
        setNotificationList: (state, action) => {
            state.notificationList = action.payload.data
        },
        doReadNotificationSuccess: (state, action) => {
            state.readNotificationSuccess = true;
            state.readNotificationFail = false;
        },
        doReadNotificationFail: (state, action) => {
            state.readNotificationSuccess = false;
            state.readNotificationFail = true;
            state.readNotificationErrorDetail = action.payload.data;
        },
        resetReadNotificationState: (state, action) => {
            state.readNotificationSuccess = false;
            state.readNotificationFail = false;
            state.readNotificationErrorDetail = null;
        },
        doStarNotificationSuccess: (state, action) => {
            state.starNotificationSuccess = true;
            state.starNotificationFail = false;
        },
        doStarNotificationFail: (state, action) => {
            state.starNotificationSuccess = false;
            state.starNotificationFail = true;
            state.starNotificationErrorDetail = action.payload.data;
        },
        resetStarNotificationState: (state, action) => {
            state.starNotificationSuccess = false;
            state.starNotificationFail = false;
            state.starNotificationErrorDetail = null;
        },
        doUnstarNotificationSuccess: (state, action) => {
            state.unstarNotificationSuccess = true;
            state.unstarNotificationFail = false;
        },
        doUnstarNotificationFail: (state, action) => {
            state.unstarNotificationSuccess = false;
            state.unstarNotificationFail = true;
            state.unstarNotificationErrorDetail = action.payload.data;
        },
        resetUnstarNotificationState: (state, action) => {
            state.unstarNotificationSuccess = false;
            state.unstarNotificationFail = false;
            state.unstarNotificationErrorDetail = null;
        },
    },
})

export const doGetNotificationList = () => async (dispatch) => {
    try {
        let url = `api/student/notifications`;

        const response = await axios.get(url);
        dispatch(setNotificationList({ data: response.data }))
    } catch (error) {
        dispatch(setNotificationList({ data: null }))
    }
}

export const doReadNotification = (notification_id) => async (dispatch) => {
    try {
        let url = `api/student/notifications/read/${notification_id}`;
        const response = await axios.put(url);
        dispatch(doReadNotificationSuccess(response.data));
    } catch (error) {
        dispatch(doReadNotificationFail(error.response))
    }
}

export const doReadAllNotification = () => async (dispatch) => {
    try {
        let url = `api/student/notifications/read-all`;
        const response = await axios.put(url);
        dispatch(doReadNotificationSuccess(response.data));
    } catch (error) {
        dispatch(doReadNotificationFail(error.response))
    }
}

export const doResetReadNotificationState = () => async (dispatch) => {
    dispatch(resetReadNotificationState())
}

export const doStarNotification = (notification_id) => async (dispatch) => {
    try {
        let url = `api/student/notifications/star/${notification_id}`;
        const response = await axios.put(url);
        dispatch(doStarNotificationSuccess(response.data));
    } catch (error) {
        dispatch(doStarNotificationFail(error.response))
    }
}

export const doResetStarNotificationState = () => async (dispatch) => {
    dispatch(resetStarNotificationState())
}

export const doUnstarNotification = (notification_id) => async (dispatch) => {
    try {
        let url = `api/student/notifications/unstar/${notification_id}`;
        const response = await axios.put(url);
        dispatch(doStarNotificationSuccess(response.data));
    } catch (error) {
        dispatch(doStarNotificationFail(error.response))
    }
}

export const doResetUnstarNotificationState = () => async (dispatch) => {
    dispatch(resetUnstarNotificationState())
}

export const {
    setNotificationList,
    doReadNotificationSuccess,
    doReadNotificationFail,
    resetReadNotificationState,
    doStarNotificationSuccess,
    doStarNotificationFail,
    resetStarNotificationState,
    resetUnstarNotificationState,
} = notificationsStudentSlide.actions

export const getNotificationList = (state) => state.notificationsStudent?.notificationList

export const getReadNotificationSuccess = (state) => state.notificationsStudent?.readNotificationSuccess
export const getReadNotificationFail = (state) => state.notificationsStudent?.readNotificationFail
export const getReadNotificationErrorDetail = (state) => state.notificationsStudent?.readNotificationErrorDetail

export const getStarNotificationSuccess = (state) => state.notificationsStudent?.starNotificationSuccess
export const getStarNotificationFail = (state) => state.notificationsStudent?.starNotificationFail
export const getStarNotificationErrorDetail = (state) => state.notificationsStudent?.starNotificationErrorDetail

export const getUnstarNotificationSuccess = (state) => state.notificationsStudent?.unstarNotificationSuccess
export const getUnstarNotificationFail = (state) => state.notificationsStudent?.unstarNotificationFail
export const getUnstarNotificationErrorDetail = (state) => state.notificationsStudent?.unstarNotificationErrorDetail

export default notificationsStudentSlide.reducer