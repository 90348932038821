import { combineReducers } from "redux"
import storage from "redux-persist/lib/storage"

import core from "./reducer_core"
import login from "./reducer_core_login"
import updatePassword from "./reducer_core_update_password"
import waitlist from "./reducer_waitlist"
import contactUs from "./reducer_core_contact_us"
import student from "./reducer_core_student"
import employer from "./reducer_core_employer"
import university from "./reducer_core_university"
import forgotPassword from "./reducer_forgot_password"
import profilePicture from "./reducer_core_profile_picture"
import eventEmployer from "./reducer_event_employer"
import eventUniversity from "./reducer_event_university"
import eventStudent from "./reducer_event_student"
import publicEvent from "./reducer_public_event"
import publicJob from "./reducer_public_job"
import fairAndEventEmployer from "./reducer_fair_and_event_employer"
import fairAndEventUniversity from "./reducer_fair_and_event_university"
import employerUniversityPartnership from "./reducer_employer_university_partnership"
import universityEmployerPartnership from "./reducer_university_employer_partnership"
import leadingTeamEmployer from "./reducer_leading_team_employer"
import leadingTeamUniversity from "./reducer_leading_team_university"
import notificationsUniversity from "./reducer_notifications_university"
import notificationsEmployer from "./reducer_notifications_employer"
import notificationsStudent from "./reducer_notifications_student"
import jobEmployer from "./reducer_job_employer"
import jobStudent from "./reducer_job_student"
import talengenCareer from "./reducer_talengen_career"
import googleDriveImagePicker from "./reducer_google_drive_image_picker"

const appReducer = combineReducers({
    core,
    login,
    updatePassword,
    waitlist,
    publicEvent,
    publicJob,
    contactUs,
    talengenCareer,
    googleDriveImagePicker,
    
    student,
    employer,
    university,
    forgotPassword,
    profilePicture,
    eventEmployer,
    eventUniversity,
    employerUniversityPartnership,
    fairAndEventEmployer,
    fairAndEventUniversity,
    leadingTeamEmployer,
    notificationsEmployer,
    jobEmployer,

    // student
    eventStudent,
    notificationsStudent,
    jobStudent,

    // university
    universityEmployerPartnership,
    leadingTeamUniversity,
    notificationsUniversity,
})

const rootReducer = (state, action) => {
    if (action.type === "USER_LOGOUT") {
        Object.keys(state).forEach((key) => {
            storage.removeItem(`persist:${key}`)
        })
        state = undefined
    }

    return appReducer(state, action)
}

export default rootReducer
