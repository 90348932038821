import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"

export const leadingTeamUniversitySlide = createSlice({
    name: "leadingTeamUniversity",
    initialState: {
        // leading team listing
        leadingTeamListData: null,
        leadingTeamPage: 0,
        leadingTeamPageCount: 0,
        leadingTeamTotalRow: 0,
        // leading team invitation
        leadingTeamInvitationSuccess: false,
        leadingTeamInvitationFail: false,
        leadingTeamInvitationErrorDetailData: null,
        leadingTeamInvitationCopyLink: null,
        // delete
        deleteLeadingTeamSuccess: false,
        deleteLeadingTeamFail: false,
        deleteLeadingTeamErrorDetailData: null,
        // update role
        updateLeadingTeamRoleSuccess: false,
        updateLeadingTeamRoleFail: false,
        updateLeadingTeamRoleErrorDetailData: null,
    },
    reducers: {
        doGetLeadingTeamListSuccess: (state, action) => {
            state.leadingTeamListData = action.payload.data;
            state.leadingTeamPage = action.payload?.current_page;
			state.leadingTeamPageCount = action.payload?.page_count;
            state.leadingTeamTotalRow = action.payload?.total_row;
        },
        doGetLeadingTeamListFail: (state, action) => {
            state.leadingTeamListData = null;
            state.leadingTeamPage = 0;
			state.leadingTeamPageCount = 0;
        },
        doGetLeadingTeamInvitationSuccess: (state, action) => {
            state.leadingTeamInvitationSuccess = true;
            state.leadingTeamInvitationFail = false;
            state.leadingTeamInvitationCopyLink = action.payload?.link;
        },
        doGetLeadingTeamInvitationFail: (state, action) => {
            state.leadingTeamInvitationSuccess = false;
            state.leadingTeamInvitationFail = true;
			state.leadingTeamInvitationErrorDetailData = action.payload;
        },
        resetLeadingTeamInvitationStateSuccess: (state, action) => {
            state.leadingTeamInvitationSuccess = action.payload;
            state.leadingTeamInvitationFail = action.payload;
			state.leadingTeamInvitationErrorDetailData = null;
        },
        doDeleteLeadingTeamSuccess: (state, action) => {
            state.deleteLeadingTeamSuccess = true;
            state.deleteLeadingTeamFail = false;
        },
        doDeleteLeadingTeamFail: (state, action) => {
            state.deleteLeadingTeamSuccess = false;
            state.deleteLeadingTeamFail = true;
			state.deleteLeadingTeamErrorDetailData = action.payload;
        },
        resetDeleteLeadingTeamState: (state, action) => {
            state.deleteLeadingTeamSuccess = action.payload;
            state.deleteLeadingTeamFail = action.payload;
			state.deleteLeadingTeamErrorDetailData = null;
        },
        doUpdateLeadingTeamRoleSuccess: (state, action) => {
            state.updateLeadingTeamRoleSuccess = true;
            state.updateLeadingTeamRoleFail = false;
        },
        doUpdateLeadingTeamRoleFail: (state, action) => {
            state.updateLeadingTeamRoleSuccess = false;
            state.updateLeadingTeamRoleFail = true;
			state.updateLeadingTeamRoleErrorDetailData = action.payload;
        },
        resetUpdateLeadingTeamRoleState: (state, action) => {
            state.updateLeadingTeamRoleSuccess = action.payload;
            state.updateLeadingTeamRoleFail = action.payload;
			state.updateLeadingTeamRoleErrorDetailData = null;
        },
    },
})

export const doGetLeadingTeamList = (param) => async (dispatch) => {
    try {
        let url = `/api/university/leading-team-list?page=${param?.page}&size=${param?.limit}`;
        
        if (param.filter != null) {
            for (const field in param.filter) {
                const value = param.filter[field];

                if (value !== null && value !== '' && value !== undefined) {
                    url += `&${field}=${value}`;
                }
            }
        }
        
        const response = await axios.get(url);
        dispatch(doGetLeadingTeamListSuccess(response.data));
    } catch (error) {
        dispatch(doGetLeadingTeamListFail(error.response));
    }
};

export const doSendLeadingTeamInvitation = (param) => async (dispatch) => {
    try {
        // send leading team invitation
        let url = `/api/university/leading-team-list/send-email-invitation`;
        
        const response = await axios.put(url, param);
        dispatch(doGetLeadingTeamInvitationSuccess(response.data));
    } catch (error) {
        dispatch(doGetLeadingTeamInvitationFail(error.response));
    }
};

export const doDeleteLeadingTeam = (param) => async (dispatch) => {
    try {
        let url = `/api/university/leading-team/delete`;
        
        const response = await axios.post(url, param);
        dispatch(doDeleteLeadingTeamSuccess(response.data));
    } catch (error) {
        dispatch(doDeleteLeadingTeamFail(error.response));
    }
};

export const doResetDeleteLeadingTeamState = () => async (dispatch) => {
    dispatch(resetDeleteLeadingTeamState())
}

export const doUpdateLeadingTeamRole = (param) => async (dispatch) => {
    try {
        let url = `/api/university/leading-team/role`;
        
        const response = await axios.put(url, param);
        dispatch(doUpdateLeadingTeamRoleSuccess(response.data));
    } catch (error) {
        dispatch(doUpdateLeadingTeamRoleFail(error.response));
    }
}

export const doResetUpdateLeadingTeamRoleState = () => async (dispatch) => {
    dispatch(resetUpdateLeadingTeamRoleState())
}

export const {
    doGetLeadingTeamListSuccess,
    doGetLeadingTeamListFail,
    doGetLeadingTeamInvitationSuccess,
    doGetLeadingTeamInvitationFail,
    resetLeadingTeamInvitationStateSuccess,
    doDeleteLeadingTeamSuccess,
    doDeleteLeadingTeamFail,
    resetDeleteLeadingTeamState,
    doUpdateLeadingTeamRoleSuccess,
    doUpdateLeadingTeamRoleFail,
    resetUpdateLeadingTeamRoleState,
} = leadingTeamUniversitySlide.actions

// leading team listing
export const getLeadingTeamList = (state) => state.leadingTeamUniversity?.leadingTeamListData
export const getLeadingTeamListPage = (state) => state.leadingTeamUniversity?.leadingTeamPage
export const getLeadingTeamListPageCount = (state) => state.leadingTeamUniversity?.leadingTeamPageCount
export const getLeadingTeamListTotalRow = (state) => state.leadingTeamUniversity?.leadingTeamTotalRow
// leading team invitation
export const getLeadingTeamInvitationSuccess = (state) => state.leadingTeamUniversity?.leadingTeamInvitationSuccess
export const getLeadingTeamInvitationFail = (state) => state.leadingTeamUniversity?.leadingTeamInvitationFail
export const getLeadingTeamInvitationErrorDetailData = (state) => state.leadingTeamUniversity?.leadingTeamInvitationErrorDetailData
export const getLeadingTeamInvitationCopyLink = (state) => state.leadingTeamUniversity?.leadingTeamInvitationCopyLink
// delete
export const getDeleteLeadingTeamSuccess = (state) => state.leadingTeamUniversity?.deleteLeadingTeamSuccess
export const getDeleteLeadingTeamFail = (state) => state.leadingTeamUniversity?.deleteLeadingTeamFail
export const getDeleteLeadingTeamErrorDetailData = (state) => state.leadingTeamUniversity?.deleteLeadingTeamErrorDetailData
// update role
export const getUpdateLeadingTeamRoleSuccess = (state) => state.leadingTeamUniversity?.updateLeadingTeamRoleSuccess
export const getUpdateLeadingTeamRoleFail = (state) => state.leadingTeamUniversity?.updateLeadingTeamRoleFail
export const getUpdateLeadingTeamRoleErrorDetailData = (state) => state.leadingTeamUniversity?.updateLeadingTeamRoleErrorDetailData

export default leadingTeamUniversitySlide.reducer