import React from "react";

const InviteTeamMember = React.lazy(() => import("src/pages/dashboard/university/invite_team_member/index"))
const EditUniversityProfile = React.lazy(() => import("src/pages/dashboard/university/university_profile/EditUniversityProfile"))

const routes = [
    { path: '/university/invite-team-member', name: 'Invite Team Member', element: InviteTeamMember },
    { path: '/university/edit-university-profile', name: 'Edit University Profile', element: EditUniversityProfile },
];

export default routes;