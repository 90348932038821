import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"

export const eventStudentSlide = createSlice({
    name: "eventStudent",
    initialState: {
        eventListData: [],
        employer_with_events: [],
        eventPage: 0,
        eventPageCount: 0,
        eventDetail: null,
        eventRegisterSuccess: false,
        eventRegisterFail: false,
        eventRegisterError: null,
        eventRegisteredListData: [],
        eventRegisteredPage: 0,
        eventRegisteredPageCount: 0,
        eventLikedListData: [],
        eventLikedPage: 0,
        eventLikedPageCount: 0,
        eventLikeSuccess: false,
        eventLikeFail: false,
        organizationProfile: null,
    },
    reducers: {
        doGetEventListSuccess: (state, action) => {
            state.eventListData = action.payload.data;
            state.employer_with_events = action.payload.employer_with_events;
            state.eventPage = action.payload?.current_page;
			state.eventPageCount = action.payload?.page_count;
        },
        doGetEventListFail: (state, action) => {
            state.eventListData = null;
            state.employer_with_events = [];
            state.eventPage = 0;
			state.eventPageCount = 0;
        },
        doGetEventLikedListSuccess: (state, action) => {
            state.eventLikedListData = action.payload.data;
            state.eventLikedPage = action.payload?.current_page;
			state.eventLikedPageCount = action.payload?.page_count;
        },
        doGetEventLikedListFail: (state, action) => {
            state.eventLikedListData = null;
            state.eventLikedPage = 0;
			state.eventLikedPageCount = 0;
        },
        doGetEventRegisteredListSuccess: (state, action) => {
            state.eventRegisteredListData = action.payload.data;
            state.eventRegisteredPage = action.payload?.current_page;
			state.eventRegisteredPageCount = action.payload?.page_count;
        },
        doGetEventRegisteredListFail: (state, action) => {
            state.eventRegisteredListData = null;
            state.eventRegisteredPage = 0;
			state.eventRegisteredPageCount = 0;
        },
        doGetEventDetailSuccess: (state, action) => {
            state.eventDetail = action.payload.data;
        },
        doGetEventDetailFail: (state, action) => {
            state.eventDetail = null;
        },
        doEventRegisterSuccess: (state, action) => {
            state.eventRegisterSuccess = true;
            state.eventRegisterFail = false;
        },
        doEventRegisterFail: (state, action) => {
            state.eventRegisterSuccess = false;
            state.eventRegisterFail = true;
            state.eventRegisterError = action.payload;
        },
        doEventLikeSuccess: (state, action) => {
            state.eventLikeSuccess = true;
            state.eventLikeFail = false;
        },
        doEventLikeFail: (state, action) => {
            state.eventLikeSuccess = false;
            state.eventLikeFail = true;
        },
        resetEventRegisterStateSuccess: (state, action) => {
            state.eventRegisterSuccess = action.payload;
            state.eventRegisterFail = action.payload;
        },
        doGetOrganizationProfileSuccess: (state, action) => {
            state.organizationProfile = action.payload.data;
        },
        doGetOrganizationProfileFail: (state, action) => {
            state.organizationProfile = null;
        },
    },
})

export const doGetEventList = (param) => async (dispatch) => {
    try {
        let url = `/api/student/event?page=${param.page}&size=${param.limit}`;

        if (param.filter != null) {
            for (const field in param.filter) {
                const value = param.filter[field];

                if (value !== null && value !== '' && value !== undefined) {
                    url += `&${field}=${value}`;
                }
            }
        }
        
        const response = await axios.get(url);
        dispatch(doGetEventListSuccess(response.data));
    } catch (error) {
        dispatch(doGetEventListFail(error.response));
    }
}

export const doGetEventLikedList = () => async (dispatch) => {
    try {
        let url = `/api/student/event/liked`;

        const response = await axios.get(url);
        dispatch(doGetEventLikedListSuccess(response.data));
    } catch (error) {
        dispatch(doGetEventLikedListFail(error.response));
    }
}

export const doGetEventRegisteredList = () => async (dispatch) => {
    try {
        let url = `/api/student/event/registered`;

        const response = await axios.get(url);
        dispatch(doGetEventRegisteredListSuccess(response.data));
    } catch (error) {
        dispatch(doGetEventRegisteredListFail(error.response));
    }
}

export const doGetEventDetail = (param) => async (dispatch) => {
    try {
        let url = `/api/student/event/detail`;
       
        const response = await axios.post(url, param);
        dispatch(doGetEventDetailSuccess(response.data));
    } catch (error) {
        dispatch(doGetEventDetailFail(error.response));
    }
};

export const doEventRegister = (param) => async (dispatch) => {
    try {
        let url = `/api/student/event/register`;

        const response = await axios.post(url, param);
        dispatch(doEventRegisterSuccess(response.data));
    } catch (error) {
        dispatch(doEventRegisterFail(error.response));
    }
}

export const doEventLike = (param) => async (dispatch) => {
    try {
        let url = `/api/student/event/like-unlike`;

        const response = await axios.post(url, param);
        dispatch(doEventLikeSuccess(response.data));
    } catch (error) {
        dispatch(doEventLikeFail(error.response));
    }
}

export const resetEventRegisterState = () => async (dispatch) => {
    dispatch(resetEventRegisterStateSuccess(false));
};

export const doGetOrganizationProfile = (param) => async (dispatch) => {
    try {
        let url = `/api/student/organization/detail`;
        
        const response = await axios.post(url, param);
        dispatch(doGetOrganizationProfileSuccess(response.data));
    } catch (error) {
        dispatch(doGetOrganizationProfileFail(error.response));
    }
};

export const {
    doGetEventListSuccess,
    doGetEventListFail,
    doGetEventLikedListSuccess,
    doGetEventLikedListFail,
    doGetEventRegisteredListSuccess,
    doGetEventRegisteredListFail,
    doGetEventDetailSuccess,
    doGetEventDetailFail,
    doEventRegisterSuccess,
    doEventRegisterFail,
    doEventLikeSuccess,
    doEventLikeFail,
    resetEventRegisterStateSuccess,
    doGetOrganizationProfileSuccess,
    doGetOrganizationProfileFail,
} = eventStudentSlide.actions

export const getEventList = (state) => state.eventStudent?.eventListData
export const getEmployerWithEvents = (state) => state.eventStudent?.employer_with_events
export const getEventPage = (state) => state.eventStudent?.eventPage
export const getEventPageCount = (state) => state.eventStudent?.eventPageCount
export const getEventDetail = (state) => state.eventStudent?.eventDetail

export const getEventLikedList = (state) => state.eventStudent?.eventLikedListData
export const getEventLikedPage = (state) => state.eventStudent?.eventLikedPage
export const getEventLikedPageCount = (state) => state.eventStudent?.eventLikedPageCount

export const getEventRegisteredList = (state) => state.eventStudent?.eventRegisteredListData
export const getEventRegisteredPage = (state) => state.eventStudent?.eventRegisteredPage
export const getEventRegisteredPageCount = (state) => state.eventStudent?.eventRegisteredPageCount

export const getEventRegisterSuccess = (state) => state.eventStudent?.eventRegisterSuccess;
export const getEventRegisterFail = (state) => state.eventStudent?.eventRegisterFail;

export const getOrganizationProfile = (state) => state.eventStudent?.organizationProfile

export default eventStudentSlide.reducer