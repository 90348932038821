import React from "react";

const CreateEvent = React.lazy(() => import('src/pages/dashboard/employer/create_event/components/forms/CreateEventForm'));
const EventDashboard = React.lazy(() => import('src/pages/dashboard/employer/your_event/event_detail/EmployerEventDashboard'));
const InvitationStatus = React.lazy(() => import('src/pages/dashboard/employer/your_event/event_detail/invitation_status/InvitationStatus'));
const EditEvent = React.lazy(() => import('src/pages/dashboard/employer/edit_event'));

const routes = [
    { path: '/employer/event/create', name: 'Create an Event', element: CreateEvent },
    { path: '/employer/event/detail', name: 'Event Dashboard', element: EventDashboard },
    { path: '/employer/event/detail/invitation-status', name: 'Invitation Status', element: InvitationStatus },
    { path: '/employer/event/edit', name: 'Edit Event', element: EditEvent },
];

export default routes;