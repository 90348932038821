import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"

export const contactUsSlide = createSlice({
    name: "contactUs",
    initialState: {
        sendGeneralInquirySuccess: false,
        sendGeneralInquiryFail: false,
        sendGeneralInquiryErrorDetail: null,
        sendNewBusinessInquirySuccess : false,
        sendNewBusinessInquiryFail : false,
        sendNewBusinessInquiryErrorDetail : null,
    },
    reducers: {
        doSendGeneralInquirySuccess: (state, action) => {
            state.sendGeneralInquirySuccess = true
            state.sendGeneralInquiryFail = false
            state.sendGeneralInquiryErrorDetail = null
        },
        doSendGeneralInquiryFail: (state, action) => {
            state.sendGeneralInquirySuccess = false
            state.sendGeneralInquiryFail = true
            state.sendGeneralInquiryErrorDetail = action.payload.data
        },
        resetGeneralInquiryState: (state, action) => {
            state.sendGeneralInquirySuccess = false
            state.sendGeneralInquiryFail = false
            state.sendGeneralInquiryErrorDetail = null
        },
        doSendNewBusinessInquirySuccess: (state, action) => {
            state.sendNewBusinessInquirySuccess = true
            state.sendNewBusinessInquiryFail = false
            state.sendNewBusinessInquiryErrorDetail = null
        },
        doSendNewBusinessInquiryFail: (state, action) => {
            state.sendNewBusinessInquirySuccess = false
            state.sendNewBusinessInquiryFail = true
            state.sendNewBusinessInquiryErrorDetail = action.payload.data
        },
        resetNewBusinessInquiryState: (state, action) => {
            state.sendNewBusinessInquirySuccess = false
            state.sendNewBusinessInquiryFail = false
            state.sendNewBusinessInquiryErrorDetail = null
        },
    },
})

export const doSendGeneralInquiry = (param) => async (dispatch) => {
    try {
        const url = `/api/contact-us`

        const response = await axios.post(url, param)
        dispatch(doSendGeneralInquirySuccess(response.data))
    } catch (error) {
        dispatch(doSendGeneralInquiryFail(error.response))
    }
}

export const doResetGeneralInquiryState = () => async (dispatch) => {
    dispatch(resetGeneralInquiryState());
}

export const doSendNewBusinessInquiry = (param) => async (dispatch) => {
    try {
        const url = `/api/contact-us-new-business`

        const response = await axios.post(url, param)
        dispatch(doSendNewBusinessInquirySuccess(response.data))
    } catch (error) {
        dispatch(doSendNewBusinessInquiryFail(error.response))
    }
}

export const doResetNewBusinessInquiryState = () => async (dispatch) => {
    dispatch(resetNewBusinessInquiryState());
}

export const {
    doSendGeneralInquirySuccess,
    doSendGeneralInquiryFail,
    resetGeneralInquiryState,
    doSendNewBusinessInquirySuccess,
    doSendNewBusinessInquiryFail,
    resetNewBusinessInquiryState,
} = contactUsSlide.actions

export const getSendGeneralInquirySuccess = (state) => state.contactUs?.sendGeneralInquirySuccess
export const getSendGeneralInquiryFail = (state) => state.contactUs?.sendGeneralInquiryFail
export const getSendGeneralInquiryErrorDetail = (state) => state.contactUs?.sendGeneralInquiryErrorDetail
export const getSendNewBusinessInquirySuccess = (state) => state.contactUs?.sendNewBusinessInquirySuccess
export const getSendNewBusinessInquiryFail = (state) => state.contactUs?.sendNewBusinessInquiryFail
export const getSendNewBusinessInquiryErrorDetail = (state) => state.contactUs?.sendNewBusinessInquiryErrorDetail

export default contactUsSlide.reducer