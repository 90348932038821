import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"

export const coreSlide = createSlice({
    name: "core",
    initialState: {
        commonData: null,
        isLoading: false,
    },
    reducers: {
        setCommonData: (state, action) => {
            state.commonData = action.payload.meta
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload
        }
    },
})

export const doGetCommonData = () => async (dispatch) => {
    try {
        const response = await axios.get("/api/commons/meta")

        dispatch(setCommonData({ meta: response.data }))
    } catch (error) {
        dispatch(setCommonData({ meta: null }))
    }
}

export const doSetIsLoading = (param) => async (dispatch) => {
    dispatch(setIsLoading(param))
}

export const {
    setCommonData,
    setIsLoading,
} = coreSlide.actions

export const getCommonData = (state) => state.core?.commonData
export const getIsLoading = (state) => state.core?.isLoading

export default coreSlide.reducer