import React from "react"
import moment from "moment"
import NumberFormat from "react-number-format"
import { useMediaQuery } from "react-responsive"

export const NumberFormatterLeadingZeros = React.forwardRef(function NumberFormatCustom(
    props,
    ref,
) {
    const { onChange, name, ...other } = props

    return (
        <NumberFormat
            {...other}
            allowLeadingZeros
            allowNegative={false}
            decimalSeparator={false}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: name,
                        value: values.value,
                    },
                })
            }}
        />
    )
})

export const NumberFormatter = React.forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, name, ...other } = props

    return (
        <NumberFormat
            {...other}
            allowNegative={false}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: name,
                        value: values.value,
                    },
                })
            }}
        />
    )
})

export const DesktopView = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 })
    return isDesktop ? children : null
}

export const TabletView = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
    return isTablet ? children : null
}

export const DefaultView = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 768 })
    return isNotMobile ? children : null
}

export const MobilePortraitView = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })

    return isMobile ? children : null
}

export const IsNotDesktopView = () => {
    const isNotDesktop = useMediaQuery({ maxWidth: 991 })
    return isNotDesktop ? true : false
}

export const GetTimeFormat = (start_date, end_date, start_time, end_time) => {
    const startDate = moment(start_date).format("MMMM DD, YYYY");
    const endDate = moment(end_date).format("MMMM DD, YYYY");
    const startTime = moment(start_time, "HH:mm:ss").format("h:mm A")
    const endTime = moment(end_time, "HH:mm:ss").format("h:mm A")

    if (startDate === endDate) {
        return startDate + " • " + startTime + " - " + endTime;
    }
    else {
        return startDate + " • " + startTime + " - " + endDate + " • " + endTime;
    }
}

export function numberWithCommas(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const formatDateToHowLongAgo = (date) => {
    const now = moment();
    const diffDays = now.diff(date, 'days');
    const diffWeeks = now.diff(date, 'weeks');
    const diffMonths = now.diff(date, 'months');

    if (diffDays < 1) return "Today";
    if (diffDays === 1) return "1 day ago";
    if (diffDays < 7) return `${diffDays} days ago`;
    if (diffWeeks === 1) return "1 week ago";
    if (diffWeeks < 4) return `${diffWeeks} weeks ago`;
    if (diffMonths === 1) return "1 month ago";
    return `${diffMonths} months ago`;
};

export const jobTypeClass = (status) => {
    switch (status) {
        case "Full Time":
            return "full-time";
        case "Part Time":
            return "part-time";
        case "Internship":
            return "internship";
        case "Contract":
            return "contract";
        default:
            return "full-time";
    }
};

export const locationTypeClass = (status) => {
    switch (status) {
        case "On-Site":
            return "on-site";
        case "Hybrid":
            return "hybrid";
        case "Remote":
            return "remote";
        default:
            return "on-site";
    }
};

export const downloadFileWithFilepath = (filepath, origname) => {
    const downloadLink = document.createElement('a');
    downloadLink.href = filepath;
    downloadLink.download = origname;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
}