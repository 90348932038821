import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"

export const publicJobSlide = createSlice({
    name: "publicJob",
    initialState: {
        publicJobListData: [],
        publicJobPage: 0,
        publicJobPageCount: 0,

        publicJobDetail: null,

        employerProfile: null,
    },
    reducers: {
        doGetPublicJobListSuccess: (state, action) => {
            state.publicJobListData = action.payload.data;
            state.publicJobPage = action.payload?.current_page;
			state.publicJobPageCount = action.payload?.page_count;
        },
        doGetPublicJobListFail: (state, action) => {
            state.jobListData = null;
            state.publicJobPage = 0;
			state.publicJobPageCount = 0;
        },
        doGetPublicJobDetailSuccess: (state, action) => {
            state.publicJobDetail = action.payload.data;
        },
        doGetPublicJobDetailFail: (state, action) => {
            state.publicJobDetail = null;
        },
        doGetPublicJobEmployerProfileSuccess: (state, action) => {
            state.employerProfile = action.payload.data;
        },
        doGetPublicJobEmployerProfileFail: (state, action) => {
            state.employerProfile = null;
        },
    },
})

export const doGetPublicJobList = (param) => async (dispatch) => {
    try {
        let url = `/api/public-job?page=${param.page}&size=${param.limit}`;

        if (param.filter != null) {
            for (const field in param.filter) {
                const value = param.filter[field];

                if (value !== null && value !== '' && value !== undefined) {
                    url += `&${field}=${value}`;
                }
            }
        }
        
        const response = await axios.get(url);
        dispatch(doGetPublicJobListSuccess(response.data));
    } catch (error) {
        dispatch(doGetPublicJobListFail(error.response));
    }
}

export const doGetPublicJobDetail = (param) => async (dispatch) => {
    try {
        let url = `/api/public-job/detail`;
       
        const response = await axios.post(url, param);
        dispatch(doGetPublicJobDetailSuccess(response.data));
    } catch (error) {
        dispatch(doGetPublicJobDetailFail(error.response));
    }
};

export const doGetPublicJobEmployerProfile = (param) => async (dispatch) => {
    try {
        let url = `/api/public-job/employer-profile`;
        
        const response = await axios.post(url, param);
        dispatch(doGetPublicJobEmployerProfileSuccess(response.data));
    } catch (error) {
        dispatch(doGetPublicJobEmployerProfileFail(error.response));
    }
};

export const {
    doGetPublicJobListSuccess,
    doGetPublicJobListFail,
    doGetPublicJobDetailSuccess,
    doGetPublicJobDetailFail,
    doGetPublicJobEmployerProfileSuccess,
    doGetPublicJobEmployerProfileFail,
} = publicJobSlide.actions

export const getPublicJobList = (state) => state.publicJob?.publicJobListData
export const getPublicJobPage = (state) => state.publicJob?.publicJobPage
export const getPublicJobPageCount = (state) => state.publicJob?.publicJobPageCount

export const getPublicJobDetail = (state) => state.publicJob?.publicJobDetail

export const getPublicJobEmployerProfile = (state) => state.publicJob?.employerProfile

export default publicJobSlide.reducer