import React from "react";

const UniversityPartnership = React.lazy(() => import("src/pages/dashboard/employer/university_partnership/index"))
const UniversityDetail = React.lazy(() => import("src/pages/dashboard/employer/university_partnership/university_detail/UniversityDetail"))
const UniversityRequest = React.lazy(() => import("src/pages/dashboard/employer/university_partnership/university_detail/university_request/index"))
const RequestStatus = React.lazy(() => import("src/pages/dashboard/employer/university_partnership/university_detail/university_request/RequestStatus"))
const ViewRequest = React.lazy(() => import("src/pages/dashboard/employer/university_partnership/university_detail/university_request/ViewRequest"))
const ContactUniversity = React.lazy(() => import("src/pages/dashboard/employer/university_partnership/contact_university/ContactUniversity"))

const routes = [
    { path: '/employer/university-partnership/request/status', name: 'Request Status', element: RequestStatus },
    { path: '/employer/university-partnership/request/view', name: 'View Request', element: ViewRequest },
    { path: '/employer/university-partnership/request', name: 'Request', element: UniversityRequest },
    { path: '/employer/university-partnership/detail', name: 'Detail', element: UniversityDetail },
    { path: '/employer/university-partnership', name: 'University Partership', element: UniversityPartnership },
    { path: '/employer/university-partnership/contact', name: 'Contact University', element: ContactUniversity },
];

export default routes;