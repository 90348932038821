import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"

export const employerUniversityPartnershipSlide = createSlice({
    name: "employerUniversityPartnership",
    initialState: {
        isPartner: false,
        employerUniversityPartnershipList: [],
        employerUniversityPartnershipListTotalRow: 0,
        employerUniversityPartnershipPartnerList: [],
        employerUniversityPartnershipPartnerListTotalRow: 0,
        universityPartnershipRequestList: [],
        universityPartnershipRequestListTotalRow: 0,
        universityPartnershipSentRequestList: [],
        universityPartnershipSentRequestListTotalRow: 0,
        universityDetail: null,
        requestPartnershipSuccess: false,
        requestPartnershipFail: false,
        requestPartnershipErrorDetailData: null,
        updateRequestSuccess: false,
        updateRequestFail: false,
        updateRequestErrorDetailData: null,
        contactPartnershipSuccess: false,
        contactPartnershipFail: false,
        contactPartnershipErrorDetailData: null,
        contactHistory: null,
    },
    reducers: {
        setIsPartner: (state, action) => {
            state.isPartner = action.payload.is_partnered;
        },
        doGetEmployerUniversityPartnershipListSuccess: (state, action) => {
            state.employerUniversityPartnershipList = action.payload.data;
            state.employerUniversityPartnershipListTotalRow = action.payload?.total_row
        },
        doGetEmployerUniversityPartnershipListFail: (state, action) => {
            state.employerUniversityPartnershipList = [];
            state.employerUniversityPartnershipListTotalRow = 0;
        },
        doGetEmployerUniversityPartnershipPartnerListSuccess: (state, action) => {
            state.employerUniversityPartnershipPartnerList = action.payload.data;
            state.employerUniversityPartnershipPartnerListTotalRow = action.payload?.total_row
        },
        doGetEmployerUniversityPartnershipPartnerListFail: (state, action) => {
            state.employerUniversityPartnershipPartnerList = [];
            state.employerUniversityPartnershipPartnerListTotalRow = 0
        },
        doGetUniversityPartnershipRequestListSuccess: (state, action) => {
            state.universityPartnershipRequestList = action.payload.data;
            state.universityPartnershipRequestListTotalRow = action.payload?.total_row;
        },
        doGetUniversityPartnershipRequestListFail: (state, action) => {
            state.universityPartnershipRequestList = null;
            state.universityPartnershipRequestListTotalRow = 0;
        },
        doGetUniversityPartnershipSentRequestListSuccess: (state, action) => {
            state.universityPartnershipSentRequestList = action.payload.data;
            state.universityPartnershipSentRequestListTotalRow = action.payload?.total_row;
        },
        doGetUniversityPartnershipSentRequestListFail: (state, action) => {
            state.universityPartnershipSentRequestList = null;
            state.universityPartnershipSentRequestListTotalRow = 0;
        },
        doGetUniversityDetailSuccess: (state, action) => {
            state.universityDetail = action.payload.data
        },
        doGetUniversityDetailFail: (state, action) => {
            state.universityDetail = null
        },
        doRequestUniversityPartnershipSuccess: (state, action) => {
            state.requestPartnershipSuccess = true
            state.requestPartnershipFail = false
        },
        doRequestUniversityPartnershipFail: (state, action) => {
            state.requestPartnershipSuccess = false
            state.requestPartnershipFail = true
            state.requestPartnershipErrorDetailData = action.payload
        },
        doUpdateRequestStatusSuccess: (state, action) => {
            state.updateRequestSuccess = true;
            state.updateRequestFail = false;
        },
        doUpdateRequestStatusFail: (state, action) => {
            state.updateRequestSuccess = false;
            state.updateRequestFail = true;
            state.updateRequestErrorDetailData = action.payload;
        },
        resetRequestPartnershipState: (state, action) => {
            state.requestPartnershipSuccess = false
            state.requestPartnershipFail = false
            state.requestPartnershipErrorDetailData = null
            state.updateRequestSuccess = action.payload;
            state.updateRequestFail = action.payload;
            state.updateRequestErrorDetailData = null;
        },
        doContactUniversityPartnershipSuccess: (state, action) => {
            state.contactPartnershipSuccess = true;
            state.contactPartnershipFail = false;
        },
        doContactUniversityPartnershipFail: (state, action) => {
            state.contactPartnershipSuccess = false;
            state.contactPartnershipFail = true;
            state.contactPartnershipErrorDetailData = action.payload.data;
        },
        resetContactPartnershipState: (state, action) => {
            state.contactPartnershipSuccess = false;
            state.contactPartnershipFail = false;
            state.contactPartnershipErrorDetailData = null;
        },
        doGetUniversityPartnershipContactHistorySuccess: (state, action) => {
            state.contactHistory = action.payload?.contactHistory;
        },
        doGetUniversityPartnershipContactHistoryFail: (state, action) => {
            state.contactHistory = null
        },
    },
})

export const doCheckUniversityIsPartner = (university_id) => async (dispatch) => {
    try {
        const response = await axios.get(`/api/employer/university-partnership/check/${university_id}`);
        dispatch(setIsPartner(response.data));
    } catch (error) {
        dispatch(setIsPartner(null));
    }
}

export const doGetEmployerUniversityPartnershipList = (param) => async (dispatch) => {
    try {
        let url = `/api/employer/university-partnership?page=${param.page}&size=${param.limit}`;

        if (param.filter != null) {
            for (const field in param.filter) {
                const value = param.filter[field];

                if (value !== null && value !== '' && value !== undefined) {
                    url += `&${field}=${value}`;
                }
            }
        }

        const response = await axios.get(url);
        dispatch(doGetEmployerUniversityPartnershipListSuccess(response.data))
    } catch (error) {
        dispatch(doGetEmployerUniversityPartnershipListFail(error.response))
    }
}

export const doGetEmployerUniversityPartnershipPartnerList = (param) => async (dispatch) => {
    try {
        let url = `/api/employer/university-partnership-partner?page=${param.page}&size=${param.limit}`;

        if (param.filter != null) {
            for (const field in param.filter) {
                const value = param.filter[field];

                if (value !== null && value !== '' && value !== undefined) {
                    url += `&${field}=${value}`;
                }
            }
        }
        
        const response = await axios.get(url);
        dispatch(doGetEmployerUniversityPartnershipPartnerListSuccess(response.data))
    } catch (error) {
        dispatch(doGetEmployerUniversityPartnershipPartnerListFail(error.response))
    }
}

export const doGetEmployerPartnershipRequestList = (param) => async (dispatch) => {
    try {
        let url = `/api/employer/university-partnership/request-list?page=${param.page}&size=${param.limit}`;

        const response = await axios.get(url);
        dispatch(doGetUniversityPartnershipRequestListSuccess(response.data));
    } catch (error) {
        dispatch(doGetUniversityPartnershipRequestListFail(error.response));
    }
}

export const doGetEmployerPartnershipSentRequestList = (param) => async (dispatch) => {
    try {
        let url = `/api/employer/university-partnership/sent-request-list?page=${param.page}&size=${param.limit}`;

        const response = await axios.get(url);
        dispatch(doGetUniversityPartnershipSentRequestListSuccess(response.data));
    } catch (error) {
        dispatch(doGetUniversityPartnershipSentRequestListFail(error.response));
    }
}

export const doGetUniversityDetail = (university_id) => async (dispatch) => {
    try {
        const response = await axios.get(`/api/university/detail/${university_id}`)
        dispatch(doGetUniversityDetailSuccess(response.data));
    } catch (error) {
        dispatch(doGetUniversityDetailFail(error.response));
    }
}

export const doRequestUniversityPartnership = (param, university_id) => async (dispatch) => {
    try {
        const url = `/api/employer/university-partnership/request/${university_id}`
        const response = await axios.post(url, param)
        dispatch(doRequestUniversityPartnershipSuccess(response.data))
    } catch (error) {
        dispatch(doRequestUniversityPartnershipFail(error.response))
    }
}

export const doUpdateRequestStatus = (param) => async (dispatch) => {
    try {
        const url = `/api/employer/university-partnership/request-status-update`;
        const response = await axios.post(url, param);
        dispatch(doUpdateRequestStatusSuccess(response.data));
    } catch (error) {
        dispatch(doUpdateRequestStatusFail(error.response));
    }
}

export const doResetRequestPartnershipState = () => async (dispatch) => {
    dispatch(resetRequestPartnershipState(false))
}

export const doGetUniversityPartnershipContactHistory = (employer_university_partnership_id) => async (dispatch) => {
    try {
        const url = `/api/employer/university-partnership/contact/${employer_university_partnership_id}`;
        const response = await axios.get(url);
        dispatch(doGetUniversityPartnershipContactHistorySuccess(response.data));
    } catch (error) {
        dispatch(doGetUniversityPartnershipContactHistoryFail(error.response));
    }
}

export const doContactUniversityPartnership = (param, employer_university_partnership_id) => async (dispatch) => {
    try {
        const url = `/api/employer/university-partnership/contact/${employer_university_partnership_id}`;
        const response = await axios.post(url, param);
        dispatch(doContactUniversityPartnershipSuccess(response.data));
    } catch (error) {
        console.log(error)
        dispatch(doContactUniversityPartnershipFail(error.response));
    }
}

export const doResetContactPartnershipState = () => async (dispatch) => {
    dispatch(resetContactPartnershipState(false));
}

export const {
    setIsPartner,
    doGetEmployerUniversityPartnershipListSuccess,
    doGetEmployerUniversityPartnershipListFail,
    doGetEmployerUniversityPartnershipPartnerListSuccess,
    doGetEmployerUniversityPartnershipPartnerListFail,
    doGetUniversityPartnershipRequestListSuccess,
    doGetUniversityPartnershipRequestListFail,
    doGetUniversityPartnershipSentRequestListSuccess,
    doGetUniversityPartnershipSentRequestListFail,
    doGetUniversityDetailSuccess,
    doGetUniversityDetailFail,
    doRequestUniversityPartnershipSuccess,
    doRequestUniversityPartnershipFail,
    doUpdateRequestStatusSuccess,
    doUpdateRequestStatusFail,
    resetRequestPartnershipState,
    doContactUniversityPartnershipSuccess,
    doContactUniversityPartnershipFail,
    resetContactPartnershipState,
    doGetUniversityPartnershipContactHistorySuccess,
    doGetUniversityPartnershipContactHistoryFail,
} = employerUniversityPartnershipSlide.actions

export const getUniversityIsPartner = (state) => state.employerUniversityPartnership?.isPartner

export const getEmployerUniversityPartnershipList = (state) => state.employerUniversityPartnership?.employerUniversityPartnershipList
export const getEmployerUniversityPartnershipListTotalRow = (state) => state.employerUniversityPartnership?.employerUniversityPartnershipListTotalRow
export const getEmployerUniversityPartnershipPartnerList = (state) => state.employerUniversityPartnership?.employerUniversityPartnershipPartnerList
export const getEmployerUniversityPartnershipPartnerListTotalRow = (state) => state.employerUniversityPartnership?.employerUniversityPartnershipPartnerListTotalRow
export const getUniversityPartnershipRequestList = (state) => state.employerUniversityPartnership?.universityPartnershipRequestList;
export const getUniversityPartnershipRequestListTotalRow = (state) => state.employerUniversityPartnership?.universityPartnershipRequestListTotalRow;
export const getUniversityPartnershipSentRequestList = (state) => state.employerUniversityPartnership?.universityPartnershipSentRequestList;
export const getUniversityPartnershipSentRequestListTotalRow = (state) => state.employerUniversityPartnership?.universityPartnershipSentRequestListTotalRow;

export const getUniversityDetail = (state) => state.employerUniversityPartnership?.universityDetail

export const getRequestPartnershipSuccess = (state) => state.employerUniversityPartnership?.requestPartnershipSuccess
export const getRequestPartnershipFail = (state) => state.employerUniversityPartnership?.requestPartnershipFail
export const getRequestPartnershipErrorDetailData = (state) => state.employerUniversityPartnership?.requestPartnershipErrorDetailData

export const getUpdateRequestSuccess = (state) => state.employerUniversityPartnership?.updateRequestSuccess;
export const getUpdateRequestFail = (state) => state.employerUniversityPartnership?.updateRequestFail;
export const getUpdateRequestErrorDetailData = (state) => state.employerUniversityPartnership?.updateRequestErrorDetailData;

export const getContactPartnershipSuccess = (state) => state.employerUniversityPartnership?.contactPartnershipSuccess;
export const getContactPartnershipFail = (state) => state.employerUniversityPartnership?.contactPartnershipFail;
export const getContactPartnershipErrorDetailData = (state) => state.employerUniversityPartnership?.contactPartnershipErrorDetailData;

export const getUniversityPartnershipContactHistory = (state) => state.employerUniversityPartnership?.contactHistory

export default employerUniversityPartnershipSlide.reducer
