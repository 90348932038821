import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"

export const publicEventSlide = createSlice({
    name: "publicEvent",
    initialState: {
        events: null,
        eventDetail: null,
        organizationProfile: null,
    },
    reducers: {
        setEvents: (state, action) => {
            state.events = action.payload.data
        },
        doGetPublicEventDetailSuccess: (state, action) => {
            state.eventDetail = action.payload.data;
        },
        doGetPublicEventDetailFail: (state, action) => {
            state.eventDetail = null;
        },
        doGetPublicEventOrganizationProfileSuccess: (state, action) => {
            state.organizationProfile = action.payload.data;
        },
        doGetPublicEventOrganizationProfileFail: (state, action) => {
            state.organizationProfile = null;
        },
    },
})

export const doGetPublicEvents = () => async (dispatch) => {
    try {
        const response = await axios.get("/api/public-event")
        dispatch(setEvents({ data: response.data }))
    } catch (error) {
        dispatch(setEvents({ data: null }))
    }
}

export const doGetPublicEventDetail = (param) => async (dispatch) => {
    try {
        const response = await axios.post("/api/public-event/detail", param)
        dispatch(doGetPublicEventDetailSuccess(response.data))
    } catch (error) {
        dispatch(doGetPublicEventDetailFail(error.response))
    }
}

export const doGetPublicEventOrganizationProfile = (param) => async (dispatch) => {
    try {
        let url = `/api/public-event/organization/detail`;
        
        const response = await axios.post(url, param);
        dispatch(doGetPublicEventOrganizationProfileSuccess(response.data));
    } catch (error) {
        dispatch(doGetPublicEventOrganizationProfileFail(error.response));
    }
};

export const {
    setEvents,
    doGetPublicEventDetailSuccess,
    doGetPublicEventDetailFail,
    doGetPublicEventOrganizationProfileSuccess,
    doGetPublicEventOrganizationProfileFail,
} = publicEventSlide.actions

export const getPublicEvents = (state) => state.publicEvent?.events
export const getPublicEventDetail = (state) => state.publicEvent?.eventDetail
export const getPublicEventOrganizationProfile = (state) => state.publicEvent?.organizationProfile

export default publicEventSlide.reducer