import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"

export const fairAndEventUniversitySlide = createSlice({
    name: "fairAndEventUniversity",
    initialState: {
        fairAndEventList: null,
        fairAndEventListTotalRow: 0,
        fairAndEventPartnerList: null,
        fairAndEventPartnerListTotalRow: 0,
        fairAndEventRegisteredList: null,
        fairAndEventRegisteredListTotalRow: 0,
        fairAndEventDetail: null,
        registerFairAndEventSuccess: false,
        registerFairAndEventFail: false,
        registerFairAndEventFailedDetail: null,
        fairAndEventRegistrationDetail: null,
        updateRegisterSuccess: false,
        updateRegisterFail: false,
        updateRegisterErrorDetail: null,
        notifySuccess: false,
        notifyFail: false,
        notifyErrorDetail: null,
        studentAmount: 0,

    },
    reducers: {
        doGetFairAndEventListSuccess: (state, action) => {
            state.fairAndEventList = action.payload.data
            state.fairAndEventListTotalRow = action.payload?.total_row;
        },
        doGetFairAndEventListFail: (state, action) => {
            state.fairAndEventList = null
            state.fairAndEventListTotalRow = 0;
        },
        doGetFairAndEventPartnerListSuccess: (state, action) => {
            state.fairAndEventPartnerList = action.payload.data
            state.fairAndEventPartnerListTotalRow = action.payload?.total_row;
        },
        doGetFairAndEventPartnerListFail: (state, action) => {
            state.fairAndEventPartnerList = null
            state.fairAndEventPartnerListTotalRow = 0;
        },
        doGetFairAndEventRegisteredListSuccess: (state, action) => {
            state.fairAndEventRegisteredList = action.payload.data
            state.fairAndEventRegisteredListTotalRow = action.payload?.total_row;
        },
        doGetFairAndEventRegisteredListFail: (state, action) => {
            state.fairAndEventRegisteredList = null
            state.fairAndEventRegisteredListTotalRow = 0;
        },
        doGetFairAndEventDetailSuccess: (state, action) => {
            state.fairAndEventDetail = action.payload.data
        },
        doGetFairAndEventDetailFail: (state, action) => {
            state.fairAndEventDetail = null
        },
        doRegisterFairAndEventSuccess: (state, action) => {
            state.registerFairAndEventSuccess = true
            state.registerFairAndEventFail = false
        },
        doRegisterFairAndEventFail: (state, action) => {
            state.registerFairAndEventSuccess = false
            state.registerFairAndEventFail = true
            state.registerFairAndEventFailedDetail = action.payload.data
        },
        resetRegisterFairAndEventState: (state, action) => {
            state.registerFairAndEventSuccess = false
            state.registerFairAndEventFail = false
            state.registerFairAndEventFailedDetail = null
        },
        doGetFairAndEventRegistrationDetailSuccess: (state, action) => {
            state.fairAndEventRegistrationDetail = action.payload.data
        },
        doGetFairAndEventRegistrationDetailFail: (state, action) => {
            state.fairAndEventRegistrationDetail = null
        },
        doUpdateRegisterFairAndEventSuccess: (state, action) => {
            state.updateRegisterSuccess = true
            state.updateRegisterFail = false
        },
        doUpdateRegisterFairAndEventFail: (state, action) => {
            state.updateRegisterSuccess = false
            state.updateRegisterFail = true
            state.updateRegisterErrorDetail = action.payload
        },
        resetUpdateFairAndEventRegistrationStateSuccess: (state, action) => {
            state.updateRegisterSuccess = false
            state.updateRegisterFail = false
            state.updateRegisterErrorDetail = null
        },
        doNotifyStudentFairAndEventSuccess: (state, action) => {
            state.notifySuccess = true
            state.notifyFail = false
            state.studentAmount = action.payload?.studentAmount
        },
        doNotifyStudentFairAndEventFail: (state, action) => {
            state.notifySuccess = false
            state.notifyFail = true
            state.notifyErrorDetail = action.payload
            state.studentAmount = 0
        },
        resetNotifyStudentFairAndEventState: (state, action) => {
            state.notifySuccess = false
            state.notifyFail = false
            state.notifyErrorDetail = null
            state.studentAmount = 0
        },
    },
})

export const doGetFairAndEventList = (param) => async (dispatch) => {
    try {
        let url = `api/university/fair-and-event?page=${param.page}&size=${param.limit}`;

        if (param.filter != null) {
            for (const field in param.filter) {
                const value = param.filter[field];

                if (value !== null && value !== '' && value !== undefined) {
                    url += `&${field}=${value}`;
                }
            }
        }

        const response = await axios.get(url);
        dispatch(doGetFairAndEventListSuccess(response.data))
    } catch (error) {
        dispatch(doGetFairAndEventListFail(error.response))
    }
}

export const doGetFairAndEventPartnerList = (param) => async (dispatch) => {
    try {
        let url = `api/university/fair-and-event/partner?page=${param.page}&size=${param.limit}`;

        if (param.filter != null) {
            for (const field in param.filter) {
                const value = param.filter[field];

                if (value !== null && value !== '' && value !== undefined) {
                    url += `&${field}=${value}`;
                }
            }
        }

        const response = await axios.get(url);
        dispatch(doGetFairAndEventPartnerListSuccess(response.data))
    } catch (error) {
        dispatch(doGetFairAndEventPartnerListFail(error.response))
    }
}

export const doGetFairAndEventRegisteredList = (param) => async (dispatch) => {
    try {
        let url = `api/university/fair-and-event/registered?page=${param.page}&size=${param.limit}`;

        if (param.filter != null) {
            for (const field in param.filter) {
                const value = param.filter[field];

                if (value !== null && value !== '' && value !== undefined) {
                    url += `&${field}=${value}`;
                }
            }
        }

        const response = await axios.get(url);
        dispatch(doGetFairAndEventRegisteredListSuccess(response.data))
    } catch (error) {
        dispatch(doGetFairAndEventRegisteredListFail(error.response))
    }
}

export const doGetFairAndEventDetail = (fair_id) => async (dispatch) => {
    try {
        const response = await axios.get(`/api/university/fair-and-event/${fair_id}`)
        dispatch(doGetFairAndEventDetailSuccess(response.data));
    } catch (error) {
        dispatch(doGetFairAndEventDetailFail(error.response));
    }
}

export const doRegisterFairAndEvent = (param, fair_id) => async (dispatch) => {
    try {
        const url = `/api/university/fair-and-event/${fair_id}`
        const response = await axios.post(url, param)
        dispatch(doRegisterFairAndEventSuccess(response.data));
    } catch (error) {
        dispatch(doRegisterFairAndEventFail(error.response));
    }
}

export const doResetRegisterFairAndEventState = () => async (dispatch) => {
    dispatch(resetRegisterFairAndEventState(false))
}

export const doGetFairAndEventRegistrationDetail = (fair_id) => async (dispatch) => {
    try {
        const response = await axios.get(`/api/university/fair-and-event/registration/${fair_id}`)
        dispatch(doGetFairAndEventRegistrationDetailSuccess(response.data));
    } catch (error) {
        dispatch(doGetFairAndEventRegistrationDetailFail(error.response));
    }
}

export const doUpdateRegisterFairAndEvent = (param, fair_registration_id) => async (dispatch) => {
    try {
        const url = `/api/university/fair-and-event/registration/${fair_registration_id}`
        const response = await axios.put(url, param)
        dispatch(doUpdateRegisterFairAndEventSuccess(response.data));
    } catch (error) {
        dispatch(doUpdateRegisterFairAndEventFail(error.response));
    }
}

export const doResetUpdateFairAndEventRegistrationStateSuccess = () => async (dispatch) => {
    dispatch(resetUpdateFairAndEventRegistrationStateSuccess(true))
}

export const doNotifyStudentFairAndEvent = (fair_id) => async (dispatch) => {
    try {
        const url = `/api/university/fair-and-event/notify/${fair_id}`
        const response = await axios.get(url)
        dispatch(doNotifyStudentFairAndEventSuccess(response.data));
    } catch (error) {
        dispatch(doNotifyStudentFairAndEventFail(error.response));
    }
}

export const doResetNotifyStudentFairAndEventState = () => async (dispatch) => {
    dispatch(resetNotifyStudentFairAndEventState())
}

export const {
    doGetFairAndEventListSuccess,
    doGetFairAndEventListFail,
    doGetFairAndEventPartnerListSuccess,
    doGetFairAndEventPartnerListFail,
    doGetFairAndEventRegisteredListSuccess,
    doGetFairAndEventRegisteredListFail,
    doGetFairAndEventDetailSuccess,
    doGetFairAndEventDetailFail,
    doRegisterFairAndEventSuccess,
    doRegisterFairAndEventFail,
    resetRegisterFairAndEventState,
    doGetFairAndEventRegistrationDetailSuccess,
    doGetFairAndEventRegistrationDetailFail,
    doUpdateRegisterFairAndEventSuccess,
    doUpdateRegisterFairAndEventFail,
    resetUpdateFairAndEventRegistrationStateSuccess,
    doNotifyStudentFairAndEventSuccess,
    doNotifyStudentFairAndEventFail,
    resetNotifyStudentFairAndEventState,
} = fairAndEventUniversitySlide.actions

export const getFairAndEventList = (state) => state.fairAndEventUniversity?.fairAndEventList
export const getFairAndEventListTotalRow = (state) => state.fairAndEventUniversity?.fairAndEventListTotalRow
export const getFairAndEventPartnerList = (state) => state.fairAndEventUniversity?.fairAndEventPartnerList
export const getFairAndEventPartnerListTotalRow = (state) => state.fairAndEventUniversity?.fairAndEventPartnerListTotalRow
export const getFairAndEventRegisteredList = (state) => state.fairAndEventUniversity?.fairAndEventRegisteredList
export const getFairAndEventRegisteredListTotalRow = (state) => state.fairAndEventUniversity?.fairAndEventRegisteredListTotalRow
export const getFairAndEventDetail = (state) => state.fairAndEventUniversity?.fairAndEventDetail
export const getRegisterFairAndEventDetailSuccess = (state) => state.fairAndEventUniversity?.registerFairAndEventSuccess
export const getRegisterFairAndEventDetailFail = (state) => state.fairAndEventUniversity?.registerFairAndEventFail
export const getRegisterFairAndEventFailedDetail = (state) => state.fairAndEventUniversity?.registerFairAndEventFailedDetail
export const getFairAndEventRegistrationDetail = (state) => state.fairAndEventUniversity?.fairAndEventRegistrationDetail
export const getUpdateRegisterFairAndEventSuccess = (state) => state.fairAndEventUniversity?.updateRegisterSuccess
export const getUpdateRegisterFairAndEventFail = (state) => state.fairAndEventUniversity?.updateRegisterFail
export const getUpdateRegisterFairAndEventErrorDetail = (state) => state.fairAndEventUniversity?.updateRegisterErrorDetail
export const getNotifyStudentFairAndEventSuccess = (state) => state.fairAndEventUniversity?.notifySuccess
export const getNotifyStudentFairAndEventFail = (state) => state.fairAndEventUniversity?.notifyFail
export const getNotifyStudentFairAndEventErrorDetail = (state) => state.fairAndEventUniversity?.notifyErrorDetail
export const getStudentAmount = (state) => state.fairAndEventUniversity?.studentAmount

export default fairAndEventUniversitySlide.reducer