import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"

export const universityEmployerPartnershipSlide = createSlice({
    name: "universityEmployerPartnership",
    initialState: {
        isPartner: false,        
        universityEmployerPartnershipList: [],
        universityEmployerPartnershipListTotalRow: 0,
        universityEmployerPartnershipPartnerList: [],
        universityEmployerPartnershipPartnerListTotalRow: 0,
        employerPartnershipRequestList: [],
        employerPartnershipRequestListTotalRow: 0,
        employerPartnershipSentRequestList: [],
        employerPartnershipSentRequestListTotalRow: 0,
        employerDetail: null,
        requestPartnershipSuccess: false,
        requestPartnershipFail: false,
        requestPartnershipErrorDetailData: null,
        updateRequestSuccess: false,
        updateRequestFail: false,
        updateRequestErrorDetailData: null,
        contactPartnershipSuccess: false,
        contactPartnershipFail: false,
        contactPartnershipErrorDetailData: null,
        contactHistory: null,
    },
    reducers: {
        setIsPartner: (state, action) => {
            state.isPartner = action.payload.is_partnered;
        },
        doGetUniversityEmployerPartnershipListSuccess: (state, action) => {
            state.universityEmployerPartnershipList = action.payload.data;
            state.universityEmployerPartnershipListTotalRow = action.payload?.total_row
        },
        doGetUniversityEmployerPartnershipListFail: (state, action) => {
            state.universityEmployerPartnershipList = null;
            state.universityEmployerPartnershipListTotalRow = 0
        },
        doGetUniversityEmployerPartnershipPartnerListSuccess: (state, action) => {
            state.universityEmployerPartnershipPartnerList = action.payload.data;
            state.universityEmployerPartnershipPartnerListTotalRow = action.payload?.total_row
        },
        doGetUniversityEmployerPartnershipPartnerListFail: (state, action) => {
            state.universityEmployerPartnershipPartnerList = null;
            state.universityEmployerPartnershipPartnerListTotalRow = 0
        },
        doGetEmployerPartnershipRequestListSuccess: (state, action) => {
            state.employerPartnershipRequestList = action.payload.data;
            state.employerPartnershipRequestListTotalRow = action.payload?.total_row
        },
        doGetEmployerPartnershipRequestListFail: (state, action) => {
            state.employerPartnershipRequestList = null;
            state.employerPartnershipRequestListTotalRow = 0
        },
        doGetEmployerPartnershipSentRequestListSuccess: (state, action) => {
            state.employerPartnershipSentRequestList = action.payload.data;
            state.employerPartnershipSentRequestListTotalRow = action.payload?.total_row
        },
        doGetEmployerPartnershipSentRequestListFail: (state, action) => {
            state.employerPartnershipSentRequestList = null;
            state.employerPartnershipSentRequestListTotalRow = 0
        },
        doGetEmployerDetailSuccess: (state, action) => {
            state.employerDetail = action.payload.data;
        },
        doGetEmployerDetailFail: (state, action) => {
            state.employerDetail = null;
        },
        doRequestEmployerPartnershipSuccess: (state, action) => {
            state.requestPartnershipSuccess = true;
            state.requestPartnershipFail = false;
        },
        doRequestEmployerPartnershipFail: (state, action) => {
            state.requestPartnershipSuccess = false;
            state.requestPartnershipFail = true;
            state.requestPartnershipErrorDetailData = action.payload;
        },
        doUpdateRequestStatusSuccess: (state, action) => {
            state.updateRequestSuccess = true;
            state.updateRequestFail = false;
        },
        doUpdateRequestStatusFail: (state, action) => {
            state.updateRequestSuccess = false;
            state.updateRequestFail = true;
            state.updateRequestErrorDetailData = action.payload;
        },
        resetRequestPartnershipState: (state, action) => {
            state.requestPartnershipSuccess = false;
            state.requestPartnershipFail = false;
            state.requestPartnershipErrorDetailData = null;
            state.updateRequestSuccess = action.payload;
            state.updateRequestFail = action.payload;
            state.updateRequestErrorDetailData = null;
        },
        doContactEmployerPartnershipSuccess: (state, action) => {
            state.contactPartnershipSuccess = true;
            state.contactPartnershipFail = false;
        },
        doContactEmployerPartnershipFail: (state, action) => {
            state.contactPartnershipSuccess = false;
            state.contactPartnershipFail = true;
            state.contactPartnershipErrorDetailData = action.payload.data;
        },
        resetContactPartnershipState: (state, action) => {
            state.contactPartnershipSuccess = false;
            state.contactPartnershipFail = false;
            state.contactPartnershipErrorDetailData = null;
        },
        doGetEmployerPartnershipContactHistorySuccess: (state, action) => {
            state.contactHistory = action.payload?.contactHistory;
        },
        doGetEmployerPartnershipContactHistoryFail: (state, action) => {
            state.contactHistory = null
        },
    },
})

export const doCheckEmployerIsPartner = (employer_id) => async (dispatch) => {
    try {
        const response = await axios.get(`/api/university/employer-partnership/check/${employer_id}`);
        dispatch(setIsPartner(response.data));
    } catch (error) {
        dispatch(setIsPartner(null));
    }
}

export const doGetUniversityEmployerPartnershipList = (param) => async (dispatch) => {
    try {
        let url = `/api/university/employer-partnership?page=${param.page}&size=${param.limit}`;

        if (param.filter != null) {
            for (const field in param.filter) {
                const value = param.filter[field];

                if (value !== null && value !== '' && value !== undefined) {
                    url += `&${field}=${value}`;
                }
            }
        }

        const response = await axios.get(url);
        dispatch(doGetUniversityEmployerPartnershipListSuccess(response.data));
    } catch (error) {
        dispatch(doGetUniversityEmployerPartnershipListFail(error.response));
    }
}

export const doGetUniversityEmployerPartnershipPartnerList = (param) => async (dispatch) => {
    try {
        let url = `/api/university/employer-partnership-partner?page=${param.page}&size=${param.limit}`;

        if (param.filter != null) {
            for (const field in param.filter) {
                const value = param.filter[field];

                if (value !== null && value !== '' && value !== undefined) {
                    url += `&${field}=${value}`;
                }
            }
        }

        const response = await axios.get(url);
        dispatch(doGetUniversityEmployerPartnershipPartnerListSuccess(response.data));
    } catch (error) {
        dispatch(doGetUniversityEmployerPartnershipPartnerListFail(error.response));
    }
}

export const doGetEmployerPartnershipRequestList = (param) => async (dispatch) => {
    try {
        let url = `/api/university/employer-partnership/request-list?page=${param.page}&size=${param.limit}`;

        if (param.filter != null) {
            for (const field in param.filter) {
                const value = param.filter[field];

                if (value !== null && value !== '' && value !== undefined) {
                    url += `&${field}=${value}`;
                }
            }
        }

        const response = await axios.get(url);
        dispatch(doGetEmployerPartnershipRequestListSuccess(response.data));
    } catch (error) {
        dispatch(doGetEmployerPartnershipRequestListFail(error.response));
    }
}

export const doGetEmployerPartnershipSentRequestList = (param) => async (dispatch) => {
    try {
        let url = `/api/university/employer-partnership/sent-request-list?page=${param.page}&size=${param.limit}`;

        if (param.filter != null) {
            for (const field in param.filter) {
                const value = param.filter[field];

                if (value !== null && value !== '' && value !== undefined) {
                    url += `&${field}=${value}`;
                }
            }
        }

        const response = await axios.get(url);
        dispatch(doGetEmployerPartnershipSentRequestListSuccess(response.data));
    } catch (error) {
        dispatch(doGetEmployerPartnershipSentRequestListFail(error.response));
    }
}

export const doGetEmployerDetail = (employer_id) => async (dispatch) => {
    try {
        const response = await axios.get(`/api/university/employer/detail/${employer_id}`);
        dispatch(doGetEmployerDetailSuccess(response.data));
    } catch (error) {
        dispatch(doGetEmployerDetailFail(error.response));
    }
}

export const doRequestEmployerPartnership = (param, employer_id) => async (dispatch) => {
    try {
        const url = `/api/university/employer-partnership/request/${employer_id}`;
        const response = await axios.post(url, param);
        dispatch(doRequestEmployerPartnershipSuccess(response.data));
    } catch (error) {
        dispatch(doRequestEmployerPartnershipFail(error.response));
    }
}

export const doUpdateRequestStatus = (param) => async (dispatch) => {
    try {
        const url = `/api/university/employer-partnership/request-status-update`;
        const response = await axios.post(url, param);
        dispatch(doUpdateRequestStatusSuccess(response.data));
    } catch (error) {
        dispatch(doUpdateRequestStatusFail(error.response));
    }
}

export const doResetRequestPartnershipState = () => async (dispatch) => {
    dispatch(resetRequestPartnershipState(false));
}

export const doGetEmployerPartnershipContactHistory = (employer_university_partnership_id) => async (dispatch) => {
    try {
        const url = `/api/university/employer-partnership/contact/${employer_university_partnership_id}`;
        const response = await axios.get(url);
        dispatch(doGetEmployerPartnershipContactHistorySuccess(response.data));
    } catch (error) {
        dispatch(doGetEmployerPartnershipContactHistoryFail(error.response));
    }
}

export const doContactEmployerPartnership = (param, employer_university_partnership_id) => async (dispatch) => {
    try {
        const url = `/api/university/employer-partnership/contact/${employer_university_partnership_id}`;
        const response = await axios.post(url, param);
        dispatch(doContactEmployerPartnershipSuccess(response.data));
    } catch (error) {
        console.log(error)
        dispatch(doContactEmployerPartnershipFail(error.response));
    }
}

export const doResetContactPartnershipState = () => async (dispatch) => {
    dispatch(resetContactPartnershipState(false));
}

export const {
    setIsPartner,
    doGetUniversityEmployerPartnershipListSuccess,
    doGetUniversityEmployerPartnershipListFail,
    doGetUniversityEmployerPartnershipPartnerListSuccess,
    doGetUniversityEmployerPartnershipPartnerListFail,
    doGetEmployerPartnershipRequestListSuccess,
    doGetEmployerPartnershipRequestListFail,
    doGetEmployerPartnershipSentRequestListSuccess,
    doGetEmployerPartnershipSentRequestListFail,
    doGetEmployerDetailSuccess,
    doGetEmployerDetailFail,
    doRequestEmployerPartnershipSuccess,
    doRequestEmployerPartnershipFail,
    doUpdateRequestStatusSuccess,
    doUpdateRequestStatusFail,
    resetRequestPartnershipState,
    doContactEmployerPartnershipSuccess,
    doContactEmployerPartnershipFail,
    resetContactPartnershipState,
    doGetEmployerPartnershipContactHistorySuccess,
    doGetEmployerPartnershipContactHistoryFail,
} = universityEmployerPartnershipSlide.actions

export const getEmployerIsPartner = (state) => state.universityEmployerPartnership?.isPartner;

export const getUniversityEmployerPartnershipList = (state) => state.universityEmployerPartnership?.universityEmployerPartnershipList;
export const getUniversityEmployerPartnershipListTotalRow = (state) => state.universityEmployerPartnership?.universityEmployerPartnershipListTotalRow;
export const getUniversityEmployerPartnershipPartnerList = (state) => state.universityEmployerPartnership?.universityEmployerPartnershipPartnerList;
export const getUniversityEmployerPartnershipPartnerListTotalRow = (state) => state.universityEmployerPartnership?.universityEmployerPartnershipPartnerListTotalRow;
export const getEmployerPartnershipRequestList = (state) => state.universityEmployerPartnership?.employerPartnershipRequestList;
export const getEmployerPartnershipRequestListTotalRow = (state) => state.universityEmployerPartnership?.employerPartnershipRequestListTotalRow;
export const getEmployerPartnershipSentRequestList = (state) => state.universityEmployerPartnership?.employerPartnershipSentRequestList;
export const getEmployerPartnershipSentRequestListTotalRow = (state) => state.universityEmployerPartnership?.employerPartnershipSentRequestListTotalRow;

export const getEmployerDetail = (state) => state.universityEmployerPartnership?.employerDetail;

export const getRequestPartnershipSuccess = (state) => state.universityEmployerPartnership?.requestPartnershipSuccess;
export const getRequestPartnershipFail = (state) => state.universityEmployerPartnership?.requestPartnershipFail;
export const getRequestPartnershipErrorDetailData = (state) => state.universityEmployerPartnership?.requestPartnershipErrorDetailData;

export const getUpdateRequestSuccess = (state) => state.universityEmployerPartnership?.updateRequestSuccess;
export const getUpdateRequestFail = (state) => state.universityEmployerPartnership?.updateRequestFail;
export const getUpdateRequestErrorDetailData = (state) => state.universityEmployerPartnership?.updateRequestErrorDetailData;

export const getContactPartnershipSuccess = (state) => state.universityEmployerPartnership?.contactPartnershipSuccess;
export const getContactPartnershipFail = (state) => state.universityEmployerPartnership?.contactPartnershipFail;
export const getContactPartnershipErrorDetailData = (state) => state.universityEmployerPartnership?.contactPartnershipErrorDetailData;

export const getEmployerPartnershipContactHistory = (state) => state.universityEmployerPartnership?.contactHistory

export default universityEmployerPartnershipSlide.reducer