import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"

export const eventUniversitySlide = createSlice({
    name: "eventUniversity",
    initialState: {
        eventList: [],
        eventListTotalRow: 0,
        invitedEventList: [],
        invitedEventListTotalRow: 0,
        invitedEventDetail: null,

        //create event
        createEventUrlParam: null,
        createEventSuccess: false,
        createEventFail: false,
        createEventFailedDetail: null,
        //get event
        successEventId: null,
        eventDetail: null,
        // invited universities
        eventInvitedEmployerList: null,
        // participant listing
        participantListData: null,
        participantPage: 0,
        participantPageCount: 0,
        participantTotalRow: 0,
        // delete
        deleteSuccess: false,
        deleteFail: false,
        deleteErrorDetailData: null,
        // update
        updateSuccess: false,
        updateFail: false,
        updateErrorDetailData: null,

        declineInvitedEventSuccess: false,
        declineInvitedEventFail: false,
        declineInvitedEventError: null,

        acceptInvitedEventSuccess: false,
        acceptInvitedEventFail: false,
        acceptInvitedEventError: null,

        eventRegisteredEmployerList: null,

        approveSuccess: false,
        approveFail: false,
        approveErrorDetail: null,
        declineSuccess: false,
        declineFail: false,
        declineErrorDetail: null,

        eventLeadingTeamList: null,
        eventLeadingTeamListPage: 0,
        eventLeadingTeamListPageCount: 0,
        eventLeadingTeamListTotalRow: 0,

        eventLeadingTeamInvitationSuccess: false,
        eventLeadingTeamInvitationFail: false,
        eventLeadingTeamInvitationErrorDetailData: null,
        eventLeadingTeamInvitationCopyLink: null,
        
        inviteEventTeamMemberSuccess: false,
        inviteEventTeamMemberFail: false,
        inviteEventTeamMemberErrorDetailData: null,
    },
    reducers: {
        doGetEventListSuccess: (state, action) => {
            state.eventList = action.payload.data
            state.eventListTotalRow = action.payload?.total_row
        },
        doGetEventListFail: (state, action) => {
            state.eventList = []
            state.eventListTotalRow = 0
        },
        setCreateEventUrlParam: (state, action) => {
            state.createEventUrlParam = action.payload
        },
        doGetInvitedEventListSuccess: (state, action) => {
            state.invitedEventList = action.payload.data
            state.invitedEventListTotalRow = action.payload?.total_row
        },
        doGetInvitedEventListFail: (state, action) => {
            state.invitedEventList = [];
            state.invitedEventListTotalRow = 0
        },
        doGetInvitedEventDetailSuccess: (state, action) => {
            state.invitedEventDetail = action.payload.data
        },
        doGetInvitedEventDetailFail: (state, action) => {
            state.invitedEventDetail = null
        },
        resetCreateEventUrlParamState: (state, action) => {
            state.createEventUrlParam = null
        },
        doCreateEventSuccess: (state, action) => {
            state.createEventSuccess = true
            state.createEventFail = false
            state.successEventId = action.payload.eventId
        },
        doCreateEventFail: (state, action) => {
            state.createEventSuccess = false
            state.createEventFail = true
            state.createEventFailedDetail = action.payload.data
        },
        resetCreateEventState: (state, action) => {
            state.createEventSuccess = false
            state.createEventFail = false
            state.createEventFailedDetail = null
        },
        doGetEventDetailSuccess: (state, action) => {
            state.eventDetail = action.payload.data
        },
        doGetEventDetailFail: (state, action) => {
            state.eventDetail = null
        },
        doGetEventInvitedEmployerListSuccess: (state, action) => {
            state.eventInvitedEmployerList = action.payload.data
        },
        doGetEventInvitedEmployerListFail: (state, action) => {
            state.eventInvitedEmployerList = null
        },
        doGetEventParticipantListSuccess: (state, action) => {
            state.participantListData = action.payload.data;
            state.participantPage = action.payload?.current_page;
			state.participantPageCount = action.payload?.page_count;
            state.participantTotalRow = action.payload?.total_row;
        },
        doGetEventParticipantListFail: (state, action) => {
            state.participantListData = null;
            state.participantPage = 0;
			state.participantPageCount = 0;
        },
        doDeleteEventSuccess: (state, action) => {
            state.deleteSuccess = true;
            state.deleteFail = false;
        },
        doDeleteEventFail: (state, action) => {
            state.deleteSuccess = false;
            state.deleteFail = true;
			state.deleteErrorDetailData = action.payload;
        },
        doDeclineInvitedEventSuccess: (state, action) => {
            state.declineInvitedEventSuccess = true;
            state.declineInvitedEventFail = false;
            state.declineInvitedEventError = null;
        },
        doDeclineInvitedEventFail: (state, action) => {
            state.declineInvitedEventSuccess = false;
            state.declineInvitedEventFail = true;
            state.declineInvitedEventError = action.payload.data;
        },
        doAcceptInvitedEventSuccess: (state, action) => {
            state.acceptInvitedEventSuccess = true;
            state.acceptInvitedEventFail = false;
            state.acceptInvitedEventError = null;
        },
        doAcceptInvitedEventFail: (state, action) => {
            state.acceptInvitedEventSuccess = false;
            state.acceptInvitedEventFail = true;
            state.acceptInvitedEventError = action.payload.data;
        },
        resetEventStateSuccess: (state, action) => {
            state.updateSuccess = action.payload;
            state.updateFail = action.payload;
			state.updateErrorDetailData = null;
            state.deleteSuccess = action.payload;
            state.deleteFail = action.payload;
            state.deleteErrorDetailData = null;
            state.declineInvitedEventSuccess = action.payload;
            state.declineInvitedEventFail = action.payload;
            state.declineInvitedEventError = null;
            state.acceptInvitedEventSuccess = action.payload;
            state.acceptInvitedEventFail = action.payload;
            state.acceptInvitedEventError = null;
        },
        doUpdateEventSuccess: (state, action) => {
            state.updateSuccess = true;
            state.updateFail = false;
        },
        doUpdateEventFail: (state, action) => {
            state.updateSuccess = false;
            state.updateFail = true;
			state.updateErrorDetailData = action.payload;
        },
        doGetEventRegisteredEmployerListSuccess: (state, action) => {
            state.eventRegisteredEmployerList = action.payload.data
        },
        doGetEventRegisteredEmployerListFail: (state, action) => {
            state.eventRegisteredEmployerList = null
        },
        doApproveEventRegistrationSuccess: (state, action) => {
            state.approveSuccess = true
            state.approveFail = false
        },
        doApproveEventRegistrationFail: (state, action) => {
            state.approveSuccess = false
            state.approveFail = true
            state.approveErrorDetail = action.payload
        },
        resetApproveEventRegistrationState: (state, action) => {
            state.approveSuccess = false
            state.approveFail = false
            state.approveErrorDetail = null
        },
        doDeclineEventRegistrationSuccess: (state, action) => {
            state.declineSuccess = true
            state.declineFail = false
        },
        doDeclineEventRegistrationFail: (state, action) => {
            state.declineSuccess = false
            state.declineFail = true
            state.declineErrorDetail = action.payload
        },
        resetDeclineEventRegistrationState: (state, action) => {
            state.declineSuccess = false
            state.declineFail = false
            state.declineErrorDetail = null
        },
        doGetEventLeadingTeamListSuccess: (state, action) => {
            state.eventLeadingTeamList = action.payload.data;
            state.eventLeadingTeamListPage = action.payload?.current_page;
			state.eventLeadingTeamListPageCount = action.payload?.page_count;
            state.eventLeadingTeamListTotalRow = action.payload?.total_row;
        },
        doGetEventLeadingTeamListFail: (state, action) => {
            state.eventLeadingTeamList = null;
            state.eventLeadingTeamListPage = 0;
			state.eventLeadingTeamListPageCount = 0;
        },
        doGetEventLeadingTeamInvitationSuccess: (state, action) => {
            state.eventLeadingTeamInvitationSuccess = true;
            state.eventLeadingTeamInvitationFail = false;
            state.eventLeadingTeamInvitationCopyLink = action.payload?.link;
        },
        doGetEventLeadingTeamInvitationFail: (state, action) => {
            state.eventLeadingTeamInvitationSuccess = false;
            state.eventLeadingTeamInvitationFail = true;
			state.eventLeadingTeamInvitationErrorDetailData = action.payload;
            state.eventLeadingTeamInvitationCopyLink = null;
        },
        resetEventLeadingTeamInvitationState: (state, action) => {
            state.eventLeadingTeamInvitationSuccess = false;
            state.eventLeadingTeamInvitationFail = false;
			state.eventLeadingTeamInvitationErrorDetailData = null;
        },
        doInviteEventTeamMemberSuccess: (state, action) => {
            state.inviteEventTeamMemberSuccess = true;
            state.inviteEventTeamMemberFail = false;
        },
        doInviteEventTeamMemberFail: (state, action) => {
            state.inviteEventTeamMemberSuccess = false;
            state.inviteEventTeamMemberFail = true;
			state.inviteEventTeamMemberErrorDetailData = action.payload;
        },
        resetInviteEventTeamMemberState: (state, action) => {
            state.inviteEventTeamMemberSuccess = false;
            state.inviteEventTeamMemberFail = false;
			state.inviteEventTeamMemberErrorDetailData = null;
        },
    },
})

export const doGetEventList = (param) => async (dispatch) => {
    try {
        let url = `api/university/event-list?page=${param.page}&size=${param.limit}`;

        if (param.filter != null) {
            for (const field in param.filter) {
                const value = param.filter[field];

                if (value !== null && value !== '' && value !== undefined) {
                    url += `&${field}=${value}`;
                }
            }
        }

        const response = await axios.get(url)
        dispatch(doGetEventListSuccess(response.data))
    } catch (error) {
        dispatch(doGetEventListFail(error.response))
    }
}

export const doGetInvitedEventList = (param) => async (dispatch) => {
    try {
        let url = `api/university/invited-event?page=${param.page}&size=${param.limit}`;

        if (param.filter != null) {
            for (const field in param.filter) {
                const value = param.filter[field];

                if (value !== null && value !== '' && value !== undefined) {
                    url += `&${field}=${value}`;
                }
            }
        }

        const response = await axios.get(url)
        dispatch(doGetInvitedEventListSuccess(response.data))
    } catch (error) {
        dispatch(doGetInvitedEventListFail(error.response))
    }
}

export const doGetInvitedEventDetail = (eventId) => async (dispatch) => {
    try {
        let url = `/api/university/event/${eventId}/invited-event-detail`;
       
        const response = await axios.get(url);
        dispatch(doGetInvitedEventDetailSuccess(response.data));
    } catch (error) {
        dispatch(doGetInvitedEventDetailFail(error.response));
    }
}

export const doSetCreateEventUrlParam = (param) => async (dispatch) => {
    dispatch(setCreateEventUrlParam(param))
}

export const doResetCreateEventUrlParamState = () => async (dispatch) => {
    dispatch(resetCreateEventUrlParamState());
};

export const doCreateEvent = (param) => async (dispatch) => {
    try {
        let formData = new FormData();
        for (const field in param) {
            const value = param[field];
            if (field !== 'eventFile' && field !== 'eventBoothFile') {
                if (field === 'selected_employers_list') {
                    for (let i = 0; i < value.length; i++) {
                        formData.append(
                            field, value[i].value
                        );
                    }
                }
                else if (field === 'target_student_group') {
                    for (let i = 0; i < value.length; i++) {
                        formData.append(
                            field, JSON.stringify(value[i])
                        );
                    }
                }
                else if (field === 'intended_time_period') {
                    for (let i = 0; i < value.length; i++) {
                        formData.append(
                            field, JSON.stringify(value[i])
                        );
                    }
                }
                else if (field === 'booth') {
                    for (let i = 0; i < value.length; i++) {
                        formData.append(
                            field, JSON.stringify(value[i])
                        );
                    }
                }
                else {
                    formData.append(
                        field, value
                    );
                }
            }
        }

        if (param.eventFile !== null && param.eventFile !== "") {
            formData.append('eventFile', param.eventFile);
        }

        if (param.eventBoothFile !== null && param.eventBoothFile !== "") {
            formData.append('eventBoothFile', param.eventBoothFile);
        }

        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }
        const url = `/api/university/event`
        const response = await axios.post(url, formData, config)
        dispatch(doCreateEventSuccess(response.data))
    } catch (error) {
        dispatch(doCreateEventFail(error.response))
    }
}

export const doResetCreateEventState = () => async (dispatch) => {
    dispatch(resetCreateEventState())
}

export const doGetEventDetail = (eventId) => async (dispatch) => {
    try {
        let url = `/api/university/event/${eventId}`;
       
        const response = await axios.get(url);
        dispatch(doGetEventDetailSuccess(response.data));
    } catch (error) {
        dispatch(doGetEventDetailFail(error.response));
    }
}

export const doGetEventInvitedEmployerList = (eventId) => async (dispatch) => {
    try {
        let url = `/api/university/event/employer_list/${eventId}`;

        const response = await axios.get(url);
        dispatch(doGetEventInvitedEmployerListSuccess(response.data));
    } catch (error) {
        dispatch(doGetEventInvitedEmployerListFail(error.response));
    }
};

export const doGetEventParticipantList = (param, eventId) => async (dispatch) => {
    try {
        let url = `/api/university/event/participant_list/${eventId}?page=${param?.page}&size=${param?.limit}`;
        // if(param?.uniList) {
        //     url += `&uniList=${encodeURIComponent(JSON.stringify(param?.uniList))}`;
        // }

        if(param?.graduationClass) {
            url += `&graduationClass=${encodeURIComponent(JSON.stringify(param?.graduationClass))}`;
        }

        if(param?.academicMajor) {
            url += `&academicMajor=${encodeURIComponent(JSON.stringify(param?.academicMajor))}`;
        }

        const response = await axios.get(url);
        dispatch(doGetEventParticipantListSuccess(response.data));
    } catch (error) {
        dispatch(doGetEventParticipantListFail(error.response));
    }
};

export const doDeleteEvent = (eventId) => async (dispatch) => {
    try {
        const url = `/api/university/event/${eventId}`;

        const response = await axios.delete(url);
        dispatch(doDeleteEventSuccess(response.data));
    } catch (error) {
        dispatch(doDeleteEventFail(error.response));
    }
};

export const doUpdateEvent = (param, eventId) => async (dispatch) => {
    try {
        const url = `/api/university/event/${eventId}`
        const response = await axios.put(url, param)
        dispatch(doUpdateEventSuccess(response.data))
    } catch (error) {
        dispatch(doUpdateEventFail(error.response))
    }
}

export const doDeclineInvitedEvent = (param) => async (dispatch) => {
    try {
        let url = `/api/university/event/invited/decline`;
        const response = await axios.post(url, param);
        
        dispatch(doDeclineInvitedEventSuccess(response.data))
    } catch (error) {
        dispatch(doDeclineInvitedEventFail(error.response))
    }
}

export const doAcceptInvitedEvent = (param) => async (dispatch) => {
    try {
        let url = `/api/university/event/invited/accept`;
        const response = await axios.post(url, param);
        
        dispatch(doAcceptInvitedEventSuccess(response.data))
    } catch (error) {
        dispatch(doAcceptInvitedEventFail(error.response))
    }
}

export const doGetEventRegisteredEmployerList = (eventId) => async (dispatch) => {
    try {
        let url = `/api/university/event/registered_employer_list/${eventId}`;

        const response = await axios.get(url);
        dispatch(doGetEventRegisteredEmployerListSuccess(response.data));
    } catch (error) {
        dispatch(doGetEventRegisteredEmployerListFail(error.response));
    }
};

export const doApproveEventRegistration = (param) => async (dispatch) => {
    try {
        let url = `/api/university/event/registered_employer_list/approve`;
        const response = await axios.put(url, param);
        dispatch(doApproveEventRegistrationSuccess(response.data));
    } catch (error) {
        dispatch(doApproveEventRegistrationFail(error.response));
    }
};

export const doResetApproveEventRegistrationState = () => async (dispatch) => {
    dispatch(resetApproveEventRegistrationState())
}

export const doDeclineEventRegistration = (param) => async (dispatch) => {
    try {
        let url = `/api/university/event/registered_employer_list/decline`;
        const response = await axios.put(url, param);
        dispatch(doDeclineEventRegistrationSuccess(response.data));
    } catch (error) {
        dispatch(doDeclineEventRegistrationFail(error.response));
    }
};

export const doResetDeclineEventRegistrationState = () => async (dispatch) => {
    dispatch(resetDeclineEventRegistrationState())
}

export const doGetEventLeadingTeamList = (param, eventId) => async (dispatch) => {
    try {
        let url = `api/university/event/${eventId}/leading-team?page=${param?.page}&size=${param?.limit}`;
        const response = await axios.get(url);

        dispatch(doGetEventLeadingTeamListSuccess(response.data))
    } catch (error) {
        dispatch(doGetEventLeadingTeamListFail(error.response))
    }
}

export const doSendEventLeadingTeamInvitation = (param, eventId) => async (dispatch) => {
    try {
        // send leading team invitation
        let url = `/api/university/event/${eventId}/leading-team/send-email-invitation`;
        
        const response = await axios.put(url, param);
        dispatch(doGetEventLeadingTeamInvitationSuccess(response.data));
    } catch (error) {
        dispatch(doGetEventLeadingTeamInvitationFail(error.response));
    }
};

export const doResetEventLeadingTeamInvitationState = () => async (dispatch) => {
    dispatch(resetEventLeadingTeamInvitationState());
};

export const doInviteEventTeamMember = (param, eventId) => async (dispatch) => {
    try {
        // invite leading team from team member
        let url = `/api/university/event/${eventId}/leading-team/send-email-invitation-team-member`;
        
        const response = await axios.put(url, param);
        dispatch(doInviteEventTeamMemberSuccess(response.data));
    } catch (error) {
        dispatch(doInviteEventTeamMemberFail(error.response));
    }
};

export const doResetInviteEventTeamMemberState = () => async (dispatch) => {
    dispatch(resetInviteEventTeamMemberState());
};

export const {
    doGetEventListSuccess,
    doGetEventListFail,
    doGetInvitedEventListSuccess,
    doGetInvitedEventListFail,
    doGetInvitedEventDetailSuccess,
    doGetInvitedEventDetailFail,
    setCreateEventUrlParam,
    resetCreateEventUrlParamState,
    doCreateEventSuccess,
    doCreateEventFail,
    resetCreateEventState,
    doGetEventDetailSuccess,
    doGetEventDetailFail,
    doGetEventInvitedEmployerListSuccess,
    doGetEventInvitedEmployerListFail,
    doGetEventParticipantListSuccess,
    doGetEventParticipantListFail,
    doDeleteEventSuccess,
    doDeleteEventFail,
    resetEventStateSuccess,
    doUpdateEventSuccess,
    doUpdateEventFail,
    doDeclineInvitedEventSuccess,
    doDeclineInvitedEventFail,
    doAcceptInvitedEventSuccess,
    doAcceptInvitedEventFail,
    doGetEventRegisteredEmployerListSuccess,
    doGetEventRegisteredEmployerListFail,
    doApproveEventRegistrationSuccess,
    doApproveEventRegistrationFail,
    resetApproveEventRegistrationState,
    doDeclineEventRegistrationSuccess,
    doDeclineEventRegistrationFail,
    resetDeclineEventRegistrationState,
    doGetEventLeadingTeamListSuccess,
    doGetEventLeadingTeamListFail,
    doGetEventLeadingTeamInvitationSuccess,
    doGetEventLeadingTeamInvitationFail,
    resetEventLeadingTeamInvitationState,
    doInviteEventTeamMemberSuccess,
    doInviteEventTeamMemberFail,
    resetInviteEventTeamMemberState,
} = eventUniversitySlide.actions

export const getEventList = (state) => state.eventUniversity?.eventList
export const getEventListTotalRow = (state) => state.eventUniversity?.eventListTotalRow
export const getInvitedEventList = (state) => state.eventUniversity?.invitedEventList
export const getInvitedEventListTotalRow = (state) => state.eventUniversity?.invitedEventListTotalRow
export const getInvitedEventDetail = (state) => state.eventUniversity?.invitedEventDetail

// create event
export const getCreateEventUrlParam = (state) => state.eventUniversity?.createEventUrlParam
export const getCreateEventSuccess = (state) => state.eventUniversity?.createEventSuccess
export const getCreateEventFailedDetail = (state) => state.eventUniversity?.createEventFailedDetail
export const getCreateEventFail = (state) => state.eventUniversity?.createEventFail
export const getCreateEventSuccessEventId = (state) => state.eventUniversity?.successEventId
// get event
export const getEventDetail = (state) => state.eventUniversity?.eventDetail
// invited universities
export const getEventInvitedEmployerList = (state) => state.eventUniversity?.eventInvitedEmployerList
// participant listing
export const getEventParticipantList = (state) => state.eventUniversity?.participantListData
export const getEventParticipantListPage = (state) => state.eventUniversity?.participantPage
export const getEventParticipantListPageCount = (state) => state.eventUniversity?.participantPageCount
export const getEventParticipantListTotalRow = (state) => state.eventUniversity?.participantTotalRow
// delete
export const getDeleteEventSuccess = (state) => state.eventUniversity?.deleteSuccess
export const getDeleteEventFail = (state) => state.eventUniversity?.deleteFail
export const getDeleteEventErrorDetailData = (state) => state.eventUniversity?.deleteErrorDetailData
// update
export const getUpdateEventSuccess = (state) => state.eventUniversity?.updateSuccess
export const getUpdateEventFail = (state) => state.eventUniversity?.updateFail
export const getUpdateEventErrorDetailData = (state) => state.eventUniversity?.updateErrorDetailData

export const getDeclineInvitedEventSuccess = (state) => state.eventUniversity?.declineInvitedEventSuccess
export const getDeclineInvitedEventFail = (state) => state.eventUniversity?.declineInvitedEventFail
export const getDeclineInvitedEventError = (state) => state.eventUniversity?.declineInvitedEventError

export const getAcceptInvitedEventSuccess = (state) => state.eventUniversity?.acceptInvitedEventSuccess
export const getAcceptInvitedEventFail = (state) => state.eventUniversity?.acceptInvitedEventFail
export const getAcceptInvitedEventError = (state) => state.eventUniversity?.acceptInvitedEventError

export const getEventRegisteredEmployerList = (state) => state.eventUniversity?.eventRegisteredEmployerList

export const getApproveEventRegistrationSuccess = (state) => state.eventUniversity?.approveSuccess
export const getApproveEventRegistrationFail = (state) => state.eventUniversity?.approveFail
export const getApproveEventRegistrationErrorDetail = (state) => state.eventUniversity?.approveErrorDetail

export const getDeclineEventRegistrationSuccess = (state) => state.eventUniversity?.declineSuccess
export const getDeclineEventRegistrationFail = (state) => state.eventUniversity?.declineFail
export const getDeclineEventRegistrationErrorDetail = (state) => state.eventUniversity?.declineErrorDetail

export const getEventLeadingTeamList = (state) => state.eventUniversity?.eventLeadingTeamList
export const getEventLeadingTeamListPage = (state) => state.eventUniversity?.eventLeadingTeamListPage
export const getEventLeadingTeamListPageCount = (state) => state.eventUniversity?.eventLeadingTeamListPageCount
export const getEventLeadingTeamListTotalRow = (state) => state.eventUniversity?.eventLeadingTeamListTotalRow

export const getEventLeadingTeamInvitationSuccess = (state) => state.eventUniversity?.eventLeadingTeamInvitationSuccess
export const getEventLeadingTeamInvitationFail = (state) => state.eventUniversity?.eventLeadingTeamInvitationFail
export const getEventLeadingTeamInvitationErrorDetailData = (state) => state.eventUniversity?.eventLeadingTeamInvitationErrorDetailData
export const getEventLeadingTeamInvitationCopyLink = (state) => state.eventUniversity?.eventLeadingTeamInvitationCopyLink

export const getInviteEventTeamMemberSuccess = (state) => state.eventUniversity?.inviteEventTeamMemberSuccess
export const getInviteEventTeamMemberFail = (state) => state.eventUniversity?.inviteEventTeamMemberFail
export const getInviteEventTeamMemberErrorDetailData = (state) => state.eventUniversity?.inviteEventTeamMemberErrorDetailData

export default eventUniversitySlide.reducer