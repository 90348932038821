// import { createStore, applyMiddleware, compose } from "redux"
// import { persistStore, persistReducer } from "redux-persist"
// import thunk from "redux-thunk"
// import storage from "localforage"

// import rootReducer from "./reducers"
// import axoisInterceptor from "src/utils/axios_interceptors"

// const persistConfig = {
//     key: "root",
//     storage,
//     blacklist: ["insurance", "myPurchase"],
// }

// const persistedReducer = persistReducer(persistConfig, rootReducer)
// const store = createStore(persistedReducer, compose(applyMiddleware(thunk)))
// const persistor = persistStore(store)

// axoisInterceptor(store)

// export { store, persistor }

import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import storage from "redux-persist/lib/storage";

import rootReducer from "src/reducers";
import axoisInterceptor from 'src/utils/axios_interceptors'

const persistConfig = {
    key: "root",
    version: 1,
    storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
});

axoisInterceptor(store)

export default store;
