import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { CATEGORIES } from "src/pages/sign_up/sign_up_constant"

export const ForgotPasswordSlide = createSlice({
    name: "forgotPassword",
    initialState: {
        forgotPasswordSuccess: false,
        forgotPasswordFail: false,
        forgotPasswordFailedDetail: null,
        updateResetPasswordSuccess: false,
        updateResetPasswordFail: false,
        updateResetPasswordFailedDetail: null,
    },
    reducers: {
        // forgot password
        doForgotPasswordSuccess: (state, action) => {
            state.forgotPasswordSuccess = true
            state.forgotPasswordFail = false
            state.forgotPasswordFailedDetail = null
        },
        doForgotPasswordFail: (state, action) => {
            state.forgotPasswordSuccess = false
            state.forgotPasswordFail = true
            state.forgotPasswordFailedDetail = action.payload.data
        },
        // reset password
         doUpdateResetPasswordSuccess: (state, action) => {
            state.updateResetPasswordSuccess = true
            state.updateResetPasswordFail = false
            state.updateResetPasswordFailedDetail = null
        },
        doUpdateResetPasswordFail: (state, action) => {
            state.updateResetPasswordSuccess = false
            state.updateResetPasswordFail = true
            state.updateResetPasswordFailedDetail = action.payload.data
        },
        // reset
        resetForgotPasswordState: (state, action) => {
            state.forgotPasswordSuccess = false
            state.forgotPasswordFail = false
            state.forgotPasswordFailedDetail = null
            state.updateResetPasswordSuccess = false
            state.updateResetPasswordFail = false
            state.updateResetPasswordFailedDetail = null
        },
    },
})

export const doForgotPassword = (param, type) => async (dispatch) => {
    try {
        let userType = "student";
        if(type !== null) {
            if(type === "employer") {
                userType = "employer_user";
            } else if(type === "university") {
                userType = "university_user";
            } 
        }
        let object = {
            email: param.email,
        }
        const url = `/api/${userType}/auth/forgot-password`
        const response = await axios.post(url, object)
        dispatch(doForgotPasswordSuccess(response.data))
    } catch (error) {
        dispatch(doForgotPasswordFail(error.response))
    }
}

export const doUpdateResetPassword = (type, param) => async (dispatch) => {
    try {
        let userType;
        if (type === "student") {
            userType = "student";
        } else if (type === "employer") {
            userType = "employer_user";
        } else if (type === "university") {
            userType = "university_user";
        }
      
        const url = `/api/${userType}/auth/update-reset-password`
        const response = await axios.post(url, param)
        dispatch(doUpdateResetPasswordSuccess(response.data))
    } catch (error) {
        dispatch(doUpdateResetPasswordFail(error.response))
    }
}

export const doResetForgotPasswordState = () => async (dispatch) => {
    dispatch(resetForgotPasswordState(false))
}

export const {
    doForgotPasswordSuccess,
    doForgotPasswordFail,
    doUpdateResetPasswordSuccess,
    doUpdateResetPasswordFail,
    resetForgotPasswordState,
} = ForgotPasswordSlide.actions


export const getForgotPasswordFailedDetail = (state) => state.forgotPassword?.forgotPasswordFailedDetail
export const getForgotPasswordFail = (state) => state.forgotPassword?.forgotPasswordFail
export const getForgotPasswordSuccess = (state) => state.forgotPassword?.forgotPasswordSuccess
export const getUpdateResetPasswordFailedDetail = (state) => state.forgotPassword?.updateResetPasswordFailedDetail
export const getUpdateResetPasswordFail = (state) => state.forgotPassword?.updateResetPasswordFail
export const getUpdateResetPasswordSuccess = (state) => state.forgotPassword?.updateResetPasswordSuccess

export default ForgotPasswordSlide.reducer
