import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"

export const jobEmployerSlide = createSlice({
    name: "jobEmployer",
    initialState: {
        jobList: [],
        jobTotalRow: 0,
        total_active: 0,
        total_expired: 0,
        jobDetail: null,
        jobApplicantList: [],
        jobApplicantPage: 0,
        jobApplicantPageCount: 0,
        jobApplicantTotalRow: 0,
        jobApplicantTotalWithoutFilterRow: 0,
        totalJobWithApplicants: [],
        jobTokensData: null,
        newJobToken: null,
        newJobTokenErrorDetail: null,
        createJobSuccess: false,
        createJobFail: false,
        createJobErrorDetailData: null,
        updateJobSuccess: false,
        updateJobFail: false,
        updateJobErrorDetailData: null,
        deleteJobSuccess: false,
        deleteJobFail: false,
        deleteJobErrorDetailData: null,
        updateJobApplicantStatusSuccess: false,
        updateJobApplicantStatusFail: false,
        updateJobApplicantStatusErrorDetailData: null,

        renewJobSuccess: false,
        renewJobFail: false,
        renewJobErrorDetailData: null,
        notifyJobApplicantSuccess: false,
        notifyJobApplicantFail: false,
        notifyJobApplicantErrorDetail: null,
        acceptJobApplicantSuccess: false,
        acceptJobApplicantFail: false,
        acceptJobApplicantErrorDetail: null,
    },
    reducers: {
        doGetJobListSuccess: (state, action) => {
            state.jobList = action.payload.data;
            state.jobTotalRow = action.payload.total_row;
        },
        doGetJobListFail: (state, action) => {
            state.jobList = [];
            state.totalJob = 0;
        },
        doGetJobActiveExpiredCountSuccess: (state, action) => {
            state.total_active = action.payload.total_active;
            state.total_expired = action.payload.total_expired;
        },
        doGetJobActiveExpiredCountFail: (state, action) => {
            state.total_active = 0;
            state.total_expired = 0;
        },
        doGetJobDetailSuccess: (state, action) => {
            state.jobDetail = action.payload.data;
        },
        doGetJobDetailFail: (state, action) => {
            state.jobDetail = null;
        },
        doGetJobApplicantListSuccess: (state, action) => {
            state.jobApplicantList = action.payload.data;
            state.jobApplicantPage = action.payload?.current_page;
			state.jobApplicantPageCount = action.payload?.page_count;
            state.jobApplicantTotalRow = action.payload?.total_row;
            state.jobApplicantTotalWithoutFilterRow = action.payload?.total_without_filter_row;
            state.totalJobWithApplicants = action.payload?.totalJobWithApplicants;
        },
        doGetJobApplicantListFail: (state, action) => {
            state.jobApplicantList = [];
            state.jobApplicantPage = 0;
			state.jobApplicantPageCount = 0;
            state.jobApplicantTotalRow = 0;
            state.jobApplicantTotalWithoutFilterRow = 0;
        },
        doGetJobTokensSuccess: (state, action) => {
            state.jobTokensData = action.payload.data;
        },
        doGetJobTokensFail: (state, action) => {
            state.jobTokensData = null;
        },
        doGetNewJobTokenSuccess: (state, action) => {
            state.newJobToken = action.payload.data;
            state.newJobTokenErrorDetail = null;
        },
        doGetNewJobTokenFail: (state, action) => {
            state.newJobToken = null
            state.newJobTokenErrorDetail = action.payload.data;
        },
        doCreateJobSuccess: (state, action) => {
            state.createJobSuccess = true
            state.createJobFail = false
            state.createJobErrorDetailData = null;
        },
        doCreateJobFail: (state, action) => {
            state.createJobSuccess = false
            state.createJobFail = true
            state.createJobErrorDetailData = action.payload;
        },
        doUpdateJobSuccess: (state, action) => {
            state.updateJobSuccess = true;
            state.updateJobFail = false;
            state.updateJobErrorDetailData = null;
        },
        doUpdateJobFail: (state, action) => {
            state.updateJobSuccess = false;
            state.updateJobFail = true;
			state.updateJobErrorDetailData = action.payload;
        },
        doDeleteJobSuccess: (state, action) => {
            state.deleteJobSuccess = true;
            state.deleteJobFail = false;
            state.deleteJobErrorDetailData = null;
        },
        doDeleteJobFail: (state, action) => {
            state.deleteJobSuccess = false;
            state.deleteJobFail = true;
			state.deleteJobErrorDetailData = action.payload;
        },
        doUpdateJobApplicantStatusSuccess: (state, action) => {
            state.updateJobApplicantStatusSuccess = true;
            state.updateJobApplicantStatusFail = false;
            state.updateJobApplicantStatusErrorDetailData = null;
        },
        doUpdateJobApplicantStatusFail: (state, action) => {
            state.updateJobApplicantStatusSuccess = false;
            state.updateJobApplicantStatusFail = true;
			state.updateJobApplicantStatusErrorDetailData = action.payload;
        },
        doRenewJobSuccess: (state, action) => {
            state.renewJobSuccess = true;
            state.renewJobFail = false;
            state.renewJobErrorDetailData = null;
        },
        doRenewJobFail: (state, action) => {
            state.renewJobSuccess = false;
            state.renewJobFail = true;
			state.renewJobErrorDetailData = action.payload;
        },
        resetJobStateSuccess: (state, action) => {
            state.createJobSuccess = false
            state.createJobFail = false
            state.createJobFailedDetail = null
            state.updateJobSuccess = false
            state.updateJobFail = false
            state.updateJobErrorDetailData = null
            state.deleteJobSuccess = false
            state.deleteJobFail = false
            state.deleteJobErrorDetailData = null
            state.updateJobApplicantStatusSuccess = false
            state.updateJobApplicantStatusFail = false
			state.updateJobApplicantStatusErrorDetailData = null
            state.renewJobSuccess = false;
            state.renewJobFail = false;
            state.renewJobErrorDetailData = null;
        },
        doNotifyJobApplicantSuccess: (state, action) => {
            state.notifyJobApplicantSuccess = true
            state.notifyJobApplicantFail = false
            state.notifyJobApplicantErrorDetail = null
        },
        doNotifyJobApplicantFail: (state, action) => {
            state.notifyJobApplicantSuccess = false
            state.notifyJobApplicantFail = true
            state.notifyJobApplicantErrorDetail = action.payload.data
        },
        resetNotifyJobApplicantState: (state, action) => {
            state.notifyJobApplicantSuccess = false
            state.notifyJobApplicantFail = false
            state.notifyJobApplicantErrorDetail = null
        },
        doAcceptJobApplicantSuccess: (state, action) => {
            state.acceptJobApplicantSuccess = true
            state.acceptJobApplicantFail = false
            state.acceptJobApplicantErrorDetail = null
        },
        doAcceptJobApplicantFail: (state, action) => {
            state.acceptJobApplicantSuccess = false
            state.acceptJobApplicantFail = true
            state.acceptJobApplicantErrorDetail = action.payload.data
        },
        resetAcceptJobApplicantState: (state, action) => {
            state.acceptJobApplicantSuccess = false
            state.acceptJobApplicantFail = false
            state.acceptJobApplicantErrorDetail = null
        },
    },
})

export const doGetJobList = (param) => async (dispatch) => {
    try {
        let url = `api/employer/job/list?page=${param.page}&size=${param.limit}`;

        if (param.filter != null) {
            for (const field in param.filter) {
                const value = param.filter[field];

                if (value !== null && value !== '' && value !== undefined) {
                    url += `&${field}=${value}`;
                }
            }
        }

        const response = await axios.get(url)
        dispatch(doGetJobListSuccess(response.data))
    } catch (error) {
        dispatch(doGetJobListFail(error.response))
    }
}

export const doGetJobActiveExpiredCount = () => async (dispatch) => {
    try {
        let url = `api/employer/job/active-expired-count`;

        const response = await axios.get(url)
        dispatch(doGetJobActiveExpiredCountSuccess(response.data))
    } catch (error) {
        dispatch(doGetJobActiveExpiredCountFail(error.response))
    }
}

export const doGetJobDetail = (employerJobId) => async (dispatch) => {
    try {
        let url = `api/employer/job/${employerJobId}`;

        const response = await axios.get(url)
        dispatch(doGetJobDetailSuccess(response.data))
    } catch (error) {
        dispatch(doGetJobDetailFail(error.response))
    }
}

export const doGetJobApplicantList = (param) => async (dispatch) => {
    try {
        let url = `api/employer/job/applicant-list?page=${param.page}&size=${param.limit}`;

        if (param.filter != null) {
            for (const field in param.filter) {
                const value = param.filter[field];

                if (value !== null && value !== '' && value !== undefined) {
                    url += `&${field}=${value}`;
                }
            }
        }

        const response = await axios.get(url)
        dispatch(doGetJobApplicantListSuccess(response.data))
    } catch (error) {
        dispatch(doGetJobApplicantListFail(error.response))
    }
}

export const doGetJobTokens = () => async (dispatch) => {
    try {
        let url = `api/employer/job-tokens`;

        const response = await axios.get(url)
        dispatch(doGetJobTokensSuccess(response.data))
    } catch (error) {
        dispatch(doGetJobTokensFail(error.response))
    }
}

export const doGetNewJobToken = () => async (dispatch) => {
    try {
        let url = `api/employer/new-job-token`;

        const response = await axios.get(url)
        dispatch(doGetNewJobTokenSuccess(response.data))
    } catch (error) {
        dispatch(doGetNewJobTokenFail(error.response))
    }
}

export const doCreateJob = (param) => async (dispatch) => {
    try {
        let formData = new FormData();
        for (const field in param) {
            const value = param[field];
            if (field !== 'jobFile') {
                if(value !== undefined) {
                    formData.append(
                        field, value
                    );
                }
               
            }
        }

        if (param.jobFile !== null && param.jobFile !== "") {
            formData.append('jobFile', param.jobFile);
        }

        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }

        const url = `/api/employer/job`
        const response = await axios.post(url, formData, config)
        dispatch(doCreateJobSuccess(response.data))
    } catch (error) {
        dispatch(doCreateJobFail(error.response))
    }
}

export const doUpdateJob = (param, employerJobId) => async (dispatch) => {
    try {
        let formData = new FormData();
        for (const field in param) {
            const value = param[field];
            if (field !== 'jobFile') {
                if(value !== undefined && value !== null) {
                    formData.append(
                        field, value
                    );
                }
            }
        }

        if (param.jobFile !== null && param.jobFile !== "") {
            formData.append('jobFile', param.jobFile);
        }

        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }

        const url = `/api/employer/job/${employerJobId}`
        const response = await axios.put(url, formData, config)
        dispatch(doUpdateJobSuccess(response.data))
    } catch (error) {
        dispatch(doUpdateJobFail(error.response))
    }
}

export const doDeleteJob = (employerJobId) => async (dispatch) => {
    try {
        const url = `/api/employer/job/${employerJobId}`;

        const response = await axios.delete(url);
        dispatch(doDeleteJobSuccess(response.data));
    } catch (error) {
        dispatch(doDeleteJobFail(error.response));
    }
};

export const doUpdateJobApplicantStatus = (param) => async (dispatch) => {
    try {
        const url = `/api/employer/job/applicant/update-status`
        const response = await axios.put(url, param)
        dispatch(doUpdateJobApplicantStatusSuccess(response.data))
    } catch (error) {
        dispatch(doUpdateJobApplicantStatusFail(error.response))
    }
}

export const doRenewJob = (param) => async (dispatch) => {
    try {
        const url = `/api/employer/job/${param}/renew`
        const response = await axios.put(url, param)
        dispatch(doRenewJobSuccess(response.data))
    } catch (error) {
        dispatch(doRenewJobFail(error.response))
    }
}

export const doResetJobState = () => async (dispatch) => {
    dispatch(resetJobStateSuccess());
};

export const doNotifyJobApplicant = (param) => async (dispatch) => {
    try {
        const url = `/api/employer/job/applicant/notify`
        const response = await axios.put(url, param)
        dispatch(doNotifyJobApplicantSuccess(response.data))
    } catch (error) {
        dispatch(doNotifyJobApplicantFail(error.response))
    }
}

export const doResetNotifyJobApplicantState = () => async (dispatch) => {
    dispatch(resetNotifyJobApplicantState());
};

export const doAcceptJobApplicant = (param) => async (dispatch) => {
    try {
        const url = `/api/employer/job/applicant/accept`
        const response = await axios.put(url, param)
        dispatch(doAcceptJobApplicantSuccess(response.data))
    } catch (error) {
        dispatch(doAcceptJobApplicantFail(error.response))
    }
}

export const doResetAcceptJobApplicantState = () => async (dispatch) => {
    dispatch(resetAcceptJobApplicantState());
};

export const {
    doGetJobListSuccess,
    doGetJobListFail,
    doGetJobActiveExpiredCountSuccess,
    doGetJobActiveExpiredCountFail,
    doGetJobDetailSuccess,
    doGetJobDetailFail,
    doGetJobApplicantListSuccess,
    doGetJobApplicantListFail,
    doGetJobTokensSuccess,
    doGetJobTokensFail,
    doGetNewJobTokenSuccess,
    doGetNewJobTokenFail,
    doCreateJobSuccess,
    doCreateJobFail,
    doUpdateJobSuccess,
    doUpdateJobFail,
    doDeleteJobSuccess,
    doDeleteJobFail,
    doUpdateJobApplicantStatusSuccess,
    doUpdateJobApplicantStatusFail,
    doRenewJobSuccess,
    doRenewJobFail,
    resetJobStateSuccess,
    doNotifyJobApplicantSuccess,
    doNotifyJobApplicantFail,
    resetNotifyJobApplicantState,
    doAcceptJobApplicantSuccess,
    doAcceptJobApplicantFail,
    resetAcceptJobApplicantState,
} = jobEmployerSlide.actions

export const getJobList = (state) => state.jobEmployer?.jobList;
export const getJobListTotalRow = (state) => state.jobEmployer?.jobTotalRow;
export const getJobActiveCount = (state) => state.jobEmployer?.total_active;
export const getJobExpiredCount = (state) => state.jobEmployer?.total_expired;
export const getJobDetail = (state) => state.jobEmployer?.jobDetail;

export const getJobApplicantList = (state) => state.jobEmployer?.jobApplicantList;
export const getJobApplicantListPage = (state) => state.jobEmployer?.jobApplicantPage;
export const getJobApplicantListPageCount = (state) => state.jobEmployer?.jobApplicantPageCount;
export const getJobApplicantListTotalRow = (state) => state.jobEmployer?.jobApplicantTotalRow;
export const getJobApplicantListTotalWithoutFilterRow = (state) => state.jobEmployer?.jobApplicantTotalWithoutFilterRow;
export const getTotalJobWithApplicants = (state) => state.jobEmployer?.totalJobWithApplicants;

export const getJobTokensData = (state) => state.jobEmployer?.jobTokensData;
export const getNewJobToken = (state) => state.jobEmployer?.newJobToken;
export const getNewJobTokenErrorDetail = (state) => state.jobEmployer?.newJobTokenErrorDetail;
export const getCreateJobSuccess = (state) => state.jobEmployer?.createJobSuccess;
export const getCreateJobFail = (state) => state.jobEmployer?.createJobFail;
export const getCreateJobFailedDetail = (state) => state.jobEmployer?.createJobErrorDetailData;
export const getUpdateJobSuccess = (state) => state.jobEmployer?.updateJobSuccess;
export const getUpdateJobFail = (state) => state.jobEmployer?.updateJobFail;
export const getUpdateJobErrorDetailData = (state) => state.jobEmployer?.updateJobErrorDetailData;
export const getDeleteJobSuccess = (state) => state.jobEmployer?.deleteJobSuccess;
export const getDeleteJobFail = (state) => state.jobEmployer?.deleteJobFail;
export const getDeleteJobErrorDetailData = (state) => state.jobEmployer?.deleteJobErrorDetailData;
export const getUpdateJobApplicantStatusSuccess = (state) => state.jobEmployer?.updateJobApplicantStatusSuccess;
export const getUpdateJobApplicantStatusFail = (state) => state.jobEmployer?.updateJobApplicantStatusFail;
export const getUpdateJobApplicantStatusFailedDetail = (state) => state.jobEmployer?.updateJobApplicantStatusErrorDetailData;
export const getNotifyJobApplicantSuccess = (state) => state.jobEmployer?.notifyJobApplicantSuccess;
export const getNotifyJobApplicantFail = (state) => state.jobEmployer?.notifyJobApplicantFail;
export const getNotifyJobApplicantErrorDetail = (state) => state.jobEmployer?.notifyJobApplicantErrorDetail;
export const getAcceptJobApplicantSuccess = (state) => state.jobEmployer?.acceptJobApplicantSuccess;
export const getAcceptJobApplicantFail = (state) => state.jobEmployer?.acceptJobApplicantFail;
export const getAcceptJobApplicantErrorDetail = (state) => state.jobEmployer?.acceptJobApplicantErrorDetail;

export const getRenewJobSuccess = (state) => state.jobEmployer?.renewJobSuccess;
export const getRenewJobFail = (state) => state.jobEmployer?.renewJobFail;
export const getRenewJobErrorDetailData = (state) => state.jobEmployer?.renewJobErrorDetailData;

export default jobEmployerSlide.reducer