import employer_company_profile_routes from "./employer_modules/employer_company_profile_routes";
import employer_dashboard_routes from "./employer_modules/employer_dashboard_routes";
import employer_event_routes from "./employer_modules/employer_event_routes";
import employer_fair_and_event_routes from "./employer_modules/employer_fair_and_event_routes";
import employer_my_profile_routes from "./employer_modules/employer_my_profile_routes";
import employer_notification_routes from "./employer_modules/employer_notification_routes";
import employer_partnership_routes from "./employer_modules/employer_partnership_routes";
import employer_your_event_routes from "./employer_modules/employer_your_event_routes";
import employer_job_routes from "./employer_modules/employer_job_routes";

const routes = [
    ...employer_company_profile_routes,
    ...employer_dashboard_routes,
    ...employer_event_routes,
    ...employer_fair_and_event_routes,
    ...employer_my_profile_routes,
    ...employer_notification_routes,
    ...employer_partnership_routes,
    ...employer_your_event_routes,
    ...employer_job_routes,
];

export default routes;