import { CImage } from "@coreui/react";
import { Box, Button, } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import React from "react";
import { connect } from "react-redux";

import logo from "src/assets/TalenGenLogo.png";

export const MobileAlphaReleaseDialog = ({ 
    handleClose, isOpen
}) => {
    return (
        <Dialog disableEscapeKeyDown open={isOpen} PaperProps={{ sx: { width: "95vw", height: '100vh', margin: 0, p: 1.5, display:'flex', justifyContent: 'center' } }}>
            <Box className="text-center">
                <DialogTitle id="confirmation-dialog-title">
                    <CImage fluid src={logo} alt="logo" style={{ width: "192px" }} />
                </DialogTitle>

                <DialogContent>
                    <Typography mb={3} style={{ fontSize: '15.68px', color: '#FF4700', fontWeight: '600' }}>
                        Welcome to our Alpha Release!
                    </Typography>
                    <Typography mb={5} style={{ fontSize: '12.56px', fontWeight: '400' }}>
                        While we&apos;re thrilled to have you here,
                        we recommend accessing the full
                        experience on your <span style={{ fontWeight: 'bold' }}>desktop</span> or <span style={{ fontWeight: 'bold' }}>laptop </span>
                        for the best user interface. Thank you
                        for your understanding and enjoy
                        exploring our platform.
                    </Typography>

                    <Box className="d-flex justify-content-center">
                        <Button
                            variant="contained"
                            disableElevation
                            sx={{ width: '77px', fontSize: '12.5px', fontWeight: '600', background: '#FF4700' }}
                            onClick={handleClose}
                            >
                            Close
                        </Button>
                    </Box>
                </DialogContent>
            </Box>
        </Dialog>
    );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MobileAlphaReleaseDialog);
