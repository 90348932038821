import React from "react";

const InviteTeamMember = React.lazy(() => import("src/pages/dashboard/employer/invite_team_member/index"))
const EditCompanyProfile = React.lazy(() => import("src/pages/dashboard/employer/company_profile/EditCompanyProfile"))

const routes = [
    { path: '/employer/edit-company-profile', name: 'Edit Company Profile', element: EditCompanyProfile },
    { path: '/employer/invite-team-member', name: 'Invite Team Member', element: InviteTeamMember },

];

export default routes;