import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"

export const jobStudentSlide = createSlice({
    name: "jobStudent",
    initialState: {
        filterState: {},
        likedFilterState: {},
        appliedFilterState: {},
        jobListData: [],
        jobPage: 0,
        jobPageCount: 0,
        jobDetail: null,
        jobApplyUrlParam: null,
        jobApplySuccess: false,
        jobApplyFail: false,
        jobApplyError: null,
        jobAppliedListData: [],
        jobAppliedPage: 0,
        jobAppliedPageCount: 0,
        employerProfile: null,
        jobLikedListData: [],
        jobLikedPage: 0,
        jobLikedPageCount: 0,
        jobLikeSuccess: false,
        jobLikeFail: false,
    },
    reducers: {
        doGetJobListSuccess: (state, action) => {
            state.jobListData = action.payload.data;
            state.jobPage = action.payload?.current_page;
			state.jobPageCount = action.payload?.page_count;
        },
        doGetJobListFail: (state, action) => {
            state.jobListData = null;
            state.jobPage = 0;
			state.jobPageCount = 0;
        },
        doGetJobLikedListSuccess: (state, action) => {
            state.jobLikedListData = action.payload.data;
            state.jobLikedPage = action.payload?.current_page;
			state.jobLikedPageCount = action.payload?.page_count;
        },
        doGetJobLikedListFail: (state, action) => {
            state.jobLikedListData = null;
            state.jobLikedPage = 0;
			state.jobLikedPageCount = 0;
        },
        doGetJobAppliedListSuccess: (state, action) => {
            state.jobAppliedListData = action.payload.data;
            state.jobAppliedPage = action.payload?.current_page;
			state.jobAppliedPageCount = action.payload?.page_count;
        },
        doGetJobAppliedListFail: (state, action) => {
            state.jobAppliedListData = null;
            state.jobAppliedPage = 0;
			state.jobAppliedPageCount = 0;
        },
        doGetJobDetailSuccess: (state, action) => {
            state.jobDetail = action.payload.data;
        },
        doGetJobDetailFail: (state, action) => {
            state.jobDetail = null;
        },
        setJobApplyUrlParam: (state, action) => {
            state.jobApplyUrlParam = action.payload;
        },
        doJobApplySuccess: (state, action) => {
            state.jobApplySuccess = true;
            state.jobApplyFail = false;
        },
        doJobApplyFail: (state, action) => {
            state.jobApplySuccess = false;
            state.jobApplyFail = true;
            state.jobApplyError = action.payload.data;
        },
        resetJobApplyState: (state, action) => {
            state.jobApplySuccess = false;
            state.jobApplyFail = false;
            state.jobApplyError = null;
        },
        doGetJobEmployerProfileSuccess: (state, action) => {
            state.employerProfile = action.payload.data;
        },
        doGetJobEmployerProfileFail: (state, action) => {
            state.employerProfile = null;
        },
        setJobFilterState: (state, action) => {
            state.filterState = action.payload;
        },
        setJobLikedFilterState: (state, action) => {
            state.likedFilterState = action.payload;
        },
        setJobAppliedFilterState: (state, action) => {
            state.appliedFilterState = action.payload;
        },
        doJobLikeSuccess: (state, action) => {
            state.jobLikeSuccess = true;
            state.jobLikeFail = false;
        },
        doJobLikeFail: (state, action) => {
            state.jobLikeSuccess = false;
            state.jobLikeFail = true;
        },
    },
})

export const doGetJobList = (param) => async (dispatch) => {
    try {
        let url = `/api/student/job?page=${param.page}&size=${param.limit}`;

        if (param.filter != null) {
            for (const field in param.filter) {
                const value = param.filter[field];

                if (value !== null && value !== '' && value !== undefined) {
                    url += `&${field}=${value}`;
                }
            }
        }
        
        const response = await axios.get(url);
        dispatch(doGetJobListSuccess(response.data));
    } catch (error) {
        dispatch(doGetJobListFail(error.response));
    }
}

export const doGetJobLikedList = (param) => async (dispatch) => {
    try {
        let url = `/api/student/job/liked?page=${param.page}&size=${param.limit}`;

        if (param.filter != null) {
            for (const field in param.filter) {
                const value = param.filter[field];

                if (value !== null && value !== '' && value !== undefined) {
                    url += `&${field}=${value}`;
                }
            }
        }

        const response = await axios.get(url);
        dispatch(doGetJobLikedListSuccess(response.data));
    } catch (error) {
        dispatch(doGetJobLikedListFail(error.response));
    }
}

export const doGetJobAppliedList = (param) => async (dispatch) => {
    try {
        let url = `/api/student/job/applied?page=${param.page}&size=${param.limit}`;

        if (param.filter != null) {
            for (const field in param.filter) {
                const value = param.filter[field];

                if (value !== null && value !== '' && value !== undefined) {
                    url += `&${field}=${value}`;
                }
            }
        }

        const response = await axios.get(url);
        dispatch(doGetJobAppliedListSuccess(response.data));
    } catch (error) {
        dispatch(doGetJobAppliedListFail(error.response));
    }
}

export const doGetJobDetail = (param) => async (dispatch) => {
    try {
        let url = `/api/student/job/detail`;
       
        const response = await axios.post(url, param);
        dispatch(doGetJobDetailSuccess(response.data));
    } catch (error) {
        dispatch(doGetJobDetailFail(error.response));
    }
};

export const doSetJobApplyUrlParam = (param) => async (dispatch) => {
    dispatch(setJobApplyUrlParam(param));
};

export const doJobApply = (param) => async (dispatch) => {
    try {
        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }
        
        let url = `/api/student/job/apply`;
        const response = await axios.post(url, param, config);
        dispatch(doJobApplySuccess(response.data));
    } catch (error) {
        dispatch(doJobApplyFail(error.response));
    }
}

export const doResetJobApplyState = () => async (dispatch) => {
    dispatch(resetJobApplyState(false));
};

export const doGetJobEmployerProfile = (param) => async (dispatch) => {
    try {
        let url = `/api/student/job/employer-profile`;
        
        const response = await axios.post(url, param);
        dispatch(doGetJobEmployerProfileSuccess(response.data));
    } catch (error) {
        dispatch(doGetJobEmployerProfileFail(error.response));
    }
};

export const doSetJobFilterState = (param) => async (dispatch) => {
    dispatch(setJobFilterState(param));
};

export const doSetJobLikedFilterState = (param) => async (dispatch) => {
    dispatch(setJobLikedFilterState(param));
};

export const doSetJobAppliedFilterState = (param) => async (dispatch) => {
    dispatch(setJobAppliedFilterState(param));
};

export const doJobLike = (param) => async (dispatch) => {
    try {
        let url = `/api/student/job/like-unlike`;

        const response = await axios.post(url, param);
        dispatch(doJobLikeSuccess(response.data));
    } catch (error) {
        dispatch(doJobLikeFail(error.response));
    }
}

export const {
    doGetJobListSuccess,
    doGetJobListFail,
    doGetJobLikedListSuccess,
    doGetJobLikedListFail,
    doGetJobAppliedListSuccess,
    doGetJobAppliedListFail,
    doGetJobDetailSuccess,
    doGetJobDetailFail,
    setJobApplyUrlParam,
    doJobApplySuccess,
    doJobApplyFail,
    resetJobApplyState,
    doGetJobEmployerProfileSuccess,
    doGetJobEmployerProfileFail,
    setJobFilterState,
    setJobLikedFilterState,
    setJobAppliedFilterState,
    doJobLikeSuccess,
    doJobLikeFail,
} = jobStudentSlide.actions

export const getJobList = (state) => state.jobStudent?.jobListData
export const getJobPage = (state) => state.jobStudent?.jobPage
export const getJobPageCount = (state) => state.jobStudent?.jobPageCount

export const getJobDetail = (state) => state.jobStudent?.jobDetail

export const getJobLikedList = (state) => state.jobStudent?.jobLikedListData
export const getJobLikedPage = (state) => state.jobStudent?.jobLikedPage
export const getJobLikedPageCount = (state) => state.jobStudent?.jobLikedPageCount

export const getJobAppliedList = (state) => state.jobStudent?.jobAppliedListData
export const getJobAppliedPage = (state) => state.jobStudent?.jobAppliedPage
export const getJobAppliedPageCount = (state) => state.jobStudent?.jobAppliedPageCount

export const getJobApplyUrlParam = (state) => state.jobStudent?.jobApplyUrlParam;
export const getJobApplySuccess = (state) => state.jobStudent?.jobApplySuccess;
export const getJobApplyFail = (state) => state.jobStudent?.jobApplyFail;
export const getJobApplyErrorDetail = (state) => state.jobStudent?.jobApplyError;

export const getJobEmployerProfile = (state) => state.jobStudent?.employerProfile;

export const getJobFilterState = (state) => state.jobStudent?.filterState;
export const getJobLikedFilterState = (state) => state.jobStudent?.likedFilterState;
export const getJobAppliedFilterState = (state) => state.jobStudent?.appliedFilterState;

export default jobStudentSlide.reducer