import university_dashboard_routes from "./university_modules/university_dashboard_routes";
import university_event_routes from "./university_modules/university_event_routes";
import university_fair_and_event_routes from "./university_modules/university_fair_and_event_routes";
import university_my_profile_routes from "./university_modules/university_my_profile_routes";
import university_notification_routes from "./university_modules/university_notification_routes";
import university_partnership_routes from "./university_modules/university_partnership_routes";
import university_university_profile_routes from "./university_modules/university_university_profile_routes";
import university_your_event_routes from "./university_modules/university_your_event_routes";

const routes = [
    ...university_dashboard_routes,
    ...university_event_routes,
    ...university_fair_and_event_routes,
    ...university_my_profile_routes,
    ...university_notification_routes,
    ...university_partnership_routes,
    ...university_university_profile_routes,
    ...university_your_event_routes,
];

export default routes;