import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { LOGGED_IN_MODE } from "src/utils/common_constant"

export const LoginSlide = createSlice({
    name: "login",
    initialState: {
        loggedIn: false,
        loggedInMode: LOGGED_IN_MODE.NONE,
        // login student
        loginFailedDetailStudent: null,
        loginSuccessStudent: false,
        logoutSuccessStudent: false,
        logoutFailStudent: false,
        //login employer
        loginFailedDetailEmployer: null,
        loginSuccessEmployer: false,
        logoutSuccessEmployer: false,
        logoutFailEmployer: false,
        //login university
        loginFailedDetailUniversity: null,
        loginSuccessUniversity: false,
        logoutSuccessUniversity: false,
        logoutFailUniversity: false,

        headerDropdownName: null,
    },
    reducers: {
        setLoggedIn: (state, action) => {
            state.loggedIn = action.payload
        },
        // login student
        doLoginSuccessStudent: (state, action) => {
            state.loginSuccessStudent = true
            state.logoutSuccessStudent = false
            state.loginFailedDetailStudent = null
        },
        doLoginFailStudent: (state, action) => {
            state.loggedIn = null
            state.loggedInMode = LOGGED_IN_MODE.NONE
            state.loginSuccessStudent = false
            state.loginFailedDetailStudent = action.payload.data
        },
        resetLoginFailStudent: (state, action) => {
            state.loginFailedDetailStudent = null
            state.loginSuccessStudent = false
            state.logoutSuccessStudent = false
            state.logoutFailStudent = false
        },
        doLoggedInSuccessStudent: (state, action) => {
            state.loggedIn = action.payload
            state.loggedInMode = LOGGED_IN_MODE.STUDENT
        },
        doLoggedInFailStudent: (state, action) => {
            state.loggedIn = null
            state.loggedInMode = LOGGED_IN_MODE.NONE
        },
        doLogoutSuccessStudent: (state, action) => {
            state.loggedIn = null
            state.loggedInMode = LOGGED_IN_MODE.NONE
            state.loginSuccessStudent = false
            state.logoutSuccessStudent = true
            state.logoutFailStudent = false
        },
        resetLogoutStateStudent: (state, action) => {
            state.logoutSuccessStudent = false
        },
        doLogoutFailStudent: (state, action) => {
            state.logoutSuccessStudent = false
            state.logoutFailStudent = true
        },
        // login employer
        doLoginSuccessEmployer: (state, action) => {
            state.loginSuccessEmployer = true
            state.logoutSuccessEmployer = false
            state.loginFailedDetailEmployer = null
        },
        doLoginFailEmployer: (state, action) => {
            state.loggedIn = null
            state.loggedInMode = LOGGED_IN_MODE.NONE
            state.loginSuccessEmployer = false
            state.loginFailedDetailEmployer = action.payload.data
        },
        resetLoginFailEmployer: (state, action) => {
            state.loginFailedDetailEmployer = null
            state.loginSuccessEmployer = false
            state.logoutSuccessEmployer = false
            state.logoutFailEmployer = false
        },
        doLoggedInSuccessEmployer: (state, action) => {
            state.loggedIn = action.payload
            state.loggedInMode = LOGGED_IN_MODE.EMPLOYER
        },
        doLoggedInFailEmployer: (state, action) => {
            state.loggedIn = null
            state.loggedInMode = LOGGED_IN_MODE.NONE
        },
        doLogoutSuccessEmployer: (state, action) => {
            state.loggedIn = null
            state.loggedInMode = LOGGED_IN_MODE.NONE
            state.loginSuccessEmployer = false
            state.logoutSuccessEmployer = true
            state.logoutFailEmployer = false
        },
        resetLogoutStateEmployer: (state, action) => {
            state.logoutSuccessEmployer = false
        },
        doLogoutFailEmployer: (state, action) => {
            state.logoutSuccessEmployer = false
            state.logoutFailEmployer = true
        },
        // login university
        doLoginSuccessUniversity: (state, action) => {
            state.loginSuccessUniversity = true
            state.logoutSuccessUniversity = false
            state.loginFailedDetailUniversity = null
        },
        doLoginFailUniversity: (state, action) => {
            state.loggedIn = null
            state.loggedInMode = LOGGED_IN_MODE.NONE
            state.loginSuccessUniversity = false
            state.loginFailedDetailUniversity = action.payload.data
        },
        resetLoginFailUniversity: (state, action) => {
            state.loginFailedDetailUniversity = null
            state.loginSuccessUniversity = false
            state.logoutSuccessUniversity = false
            state.logoutFailUniversity = false
        },
        doLoggedInSuccessUniversity: (state, action) => {
            state.loggedIn = action.payload
            state.loggedInMode = LOGGED_IN_MODE.UNIVERSITY
        },
        doLoggedInFailUniversity: (state, action) => {
            state.loggedIn = null
            state.loggedInMode = LOGGED_IN_MODE.NONE
        },
        doLogoutSuccessUniversity: (state, action) => {
            state.loggedIn = null
            state.loggedInMode = LOGGED_IN_MODE.NONE
            state.loginSuccessUniversity = false
            state.logoutSuccessUniversity = true
            state.logoutFailUniversity = false
        },
        resetLogoutStateUniversity: (state, action) => {
            state.logoutSuccessUniversity = false
        },
        doLogoutFailUniversity: (state, action) => {
            state.logoutSuccessUniversity = false
            state.logoutFailUniversity = true
        },
        setHeaderDropdownName: (state, action) => {
            state.headerDropdownName = action.payload;
        },
    },
})

//
export const doSetLoggedIn = (param) => async (dispatch) => {
    dispatch(setLoggedIn(param))
}

// student
export const doLoginStudent = (param) => async (dispatch) => {
    try {
        const url = `/api/student/auth/login`
        const response = await axios.post(url, param)
        dispatch(doLoginSuccessStudent(response.data))
    } catch (error) {
        dispatch(doLoginFailStudent(error.response))
    }
}

export const doResetLoginFailStudent = () => async (dispatch) => {
    dispatch(resetLoginFailStudent(false))
}

export const doLogoutStudent = () => async (dispatch) => {
    try {
        const url = `/api/student/auth/logout`
        const response = await axios.get(url)
        dispatch(doLogoutSuccessStudent(response.data))
    } catch (error) {
        dispatch(doLogoutFailStudent(error.response))
    }
}

export const doResetLogoutStateStudent = () => async (dispatch) => {
    dispatch(resetLogoutStateStudent())
}

export const doGetLoggedInDetailsStudent = () => async (dispatch) => {
    try {
        const url = `/api/student/auth/me`
        const response = await axios.get(url)
        dispatch(doLoggedInSuccessStudent(response.data))
    } catch (error) {
        dispatch(doLoggedInFailStudent(error.response))
    }
}

// employer
export const doLoginEmployer = (param) => async (dispatch) => {
    try {
        const url = `/api/employer_user/auth/login`
        const response = await axios.post(url, param)
        dispatch(doLoginSuccessEmployer(response.data))
    } catch (error) {
        dispatch(doLoginFailEmployer(error.response))
    }
}

export const doResetLoginFailEmployer = () => async (dispatch) => {
    dispatch(resetLoginFailEmployer(false))
}

export const doLogoutEmployer = () => async (dispatch) => {
    try {
        const url = `/api/employer_user/auth/logout`
        const response = await axios.get(url)
        dispatch(doLogoutSuccessEmployer(response.data))
    } catch (error) {
        dispatch(doLogoutFailEmployer(error.response))
    }
}

export const doResetLogoutStateEmployer = () => async (dispatch) => {
    dispatch(resetLogoutStateEmployer())
}

export const doGetLoggedInDetailsEmployer = () => async (dispatch) => {
    try {
        const url = `/api/employer_user/auth/me`
        const response = await axios.get(url)
        dispatch(doLoggedInSuccessEmployer(response.data))
    } catch (error) {
        dispatch(doLoggedInFailEmployer(error.response))
    }
}

// university
export const doLoginUniversity = (param) => async (dispatch) => {
    try {
        const url = `/api/university_user/auth/login`
        const response = await axios.post(url, param)
        dispatch(doLoginSuccessUniversity(response.data))
    } catch (error) {
        dispatch(doLoginFailUniversity(error.response))
    }
}

export const doResetLoginFailUniversity = () => async (dispatch) => {
    dispatch(resetLoginFailUniversity(false))
}

export const doLogoutUniversity = () => async (dispatch) => {
    try {
        const url = `/api/university_user/auth/logout`
        const response = await axios.get(url)
        dispatch(doLogoutSuccessUniversity(response.data))
    } catch (error) {
        dispatch(doLogoutFailUniversity(error.response))
    }
}

export const doResetLogoutStateUniversity = () => async (dispatch) => {
    dispatch(resetLogoutStateUniversity())
}

export const doGetLoggedInDetailsUniversity = () => async (dispatch) => {
    try {
        const url = `/api/university_user/auth/me`
        const response = await axios.get(url)
        dispatch(doLoggedInSuccessUniversity(response.data))
    } catch (error) {
        dispatch(doLoggedInFailUniversity(error.response))
    }
}

export const doSetHeaderDropdownName = (param) => async (dispatch) => {
    dispatch(setHeaderDropdownName(param));
}

export const {
    setLoggedIn,

    doLoginSuccessStudent,
    doLoginFailStudent,
    doLoggedInSuccessStudent,
    doLoggedInFailStudent,
    resetLoginFailStudent,
    doLogoutSuccessStudent,
    resetLogoutStateStudent,
    doLogoutFailStudent,

    doLoginSuccessEmployer,
    doLoginFailEmployer,
    doLoggedInSuccessEmployer,
    doLoggedInFailEmployer,
    resetLoginFailEmployer,
    doLogoutSuccessEmployer,
    resetLogoutStateEmployer,
    doLogoutFailEmployer,

    doLoginSuccessUniversity,
    doLoginFailUniversity,
    doLoggedInSuccessUniversity,
    doLoggedInFailUniversity,
    resetLoginFailUniversity,
    doLogoutSuccessUniversity,
    resetLogoutStateUniversity,
    doLogoutFailUniversity,

    setHeaderDropdownName,
} = LoginSlide.actions


export const getLoggedIn = (state) => state.login?.loggedIn
export const getLoggedInMode = (state) => state.login?.loggedInMode
// login student
export const getLoginFailedDetailStudent = (state) => state.login?.loginFailedDetailStudent
export const getLoginSuccessStudent = (state) => state.login?.loginSuccessStudent
export const getLogoutSuccessStudent = (state) => state.login?.logoutSuccessStudent
// login employer
export const getLoginFailedDetailEmployer = (state) => state.login?.loginFailedDetailEmployer
export const getLoginSuccessEmployer = (state) => state.login?.loginSuccessEmployer
export const getLogoutSuccessEmployer = (state) => state.login?.logoutSuccessEmployer
// login university
export const getLoginFailedDetailUniversity = (state) => state.login?.loginFailedDetailUniversity
export const getLoginSuccessUniversity = (state) => state.login?.loginSuccessUniversity
export const getLogoutSuccessUniversity = (state) => state.login?.logoutSuccessUniversity

export const getHeaderDropdownName = (state) => state.login?.headerDropdownName

export default LoginSlide.reducer