import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"

export const waitlistSlide = createSlice({
    name: "waitlist",
    initialState: {
        joinWaitlistSuccess: false,
        joinWaitlistFail: false,
        joinWaitlistErrorDetail: null,
    },
    reducers: {
        doJoinWaitlistSuccess: (state, action) => {
            state.joinWaitlistSuccess = true
            state.joinWaitlistFail = false
            state.joinWaitlistErrorDetail = null
        },
        doJoinWaitlistFail: (state, action) => {
            state.joinWaitlistSuccess = false
            state.joinWaitlistFail = true
            state.joinWaitlistErrorDetail = action.payload.data
        },
        resetJoinWaitlistState: (state, action) => {
            state.joinWaitlistSuccess = false
            state.joinWaitlistFail = false
            state.joinWaitlistErrorDetail = null
        },
    },
})

export const doJoinWaitlist = (param) => async (dispatch) => {
    try {
        const url = `/api/waitlist/join`

        const response = await axios.post(url, param)
        dispatch(doJoinWaitlistSuccess(response.data))
    } catch (error) {
        dispatch(doJoinWaitlistFail(error.response))
    }
}

export const doResetJoinWaitlistState = () => async (dispatch) => {
    dispatch(resetJoinWaitlistState());
}

export const {
    doJoinWaitlistSuccess,
    doJoinWaitlistFail,
    resetJoinWaitlistState,
} = waitlistSlide.actions

export const getJoinWaitlistSuccess = (state) => state.waitlist?.joinWaitlistSuccess
export const getJoinWaitlistFail = (state) => state.waitlist?.joinWaitlistFail
export const getJoinWaitlistErrorDetail = (state) => state.waitlist?.joinWaitlistErrorDetail

export default waitlistSlide.reducer