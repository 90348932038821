import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"

export const notificationsUniversitySlide = createSlice({
    name: "notificationsEmployer",
    initialState: {
        notificationList: null,
        readNotificationSuccess: false,
        readNotificationFail: false,
        readNotificationErrorDetail: null,
    },
    reducers: {
        setNotificationList: (state, action) => {
            state.notificationList = action.payload.data
        },
        doReadNotificationSuccess: (state, action) => {
            state.readNotificationSuccess = true;
            state.readNotificationFail = false;
        },
        doReadNotificationFail: (state, action) => {
            state.readNotificationSuccess = false;
            state.readNotificationFail = true;
            state.readNotificationErrorDetail = action.payload.data;
        },
        resetReadNotificationState: (state, action) => {
            state.readNotificationSuccess = false;
            state.readNotificationFail = false;
            state.readNotificationErrorDetail = null;
        },
    },
})

export const doGetNotificationList = (param) => async (dispatch) => {
    try {
        let url = `api/university/notifications?page=${param.page}&size=${param.limit}`;

        const response = await axios.get(url);
        dispatch(setNotificationList({ data: response.data }))
    } catch (error) {
        dispatch(setNotificationList({ data: null }))
    }
}

export const doReadNotification = (notification_id) => async (dispatch) => {
    try {
        let url = `api/university/notifications/read/${notification_id}`;
        const response = await axios.put(url);
        dispatch(doReadNotificationSuccess(response.data));
    } catch (error) {
        dispatch(doReadNotificationFail(error.response))
    }
}

export const doReadAllNotification = () => async (dispatch) => {
    try {
        let url = `api/university/notifications/read-all`;
        const response = await axios.put(url);
        dispatch(doReadNotificationSuccess(response.data));
    } catch (error) {
        dispatch(doReadNotificationFail(error.response))
    }
}

export const doResetReadNotificationState = () => async (dispatch) => {
    dispatch(resetReadNotificationState())
}

export const {
    setNotificationList,
    doReadNotificationSuccess,
    doReadNotificationFail,
    resetReadNotificationState,
} = notificationsUniversitySlide.actions

export const getNotificationList = (state) => state.notificationsUniversity?.notificationList

export const getReadNotificationSuccess = (state) => state.notificationsUniversity?.readNotificationSuccess
export const getReadNotificationFail = (state) => state.notificationsUniversity?.readNotificationFail
export const getReadNotificationErrorDetail = (state) => state.notificationsUniversity?.readNotificationErrorDetail

export default notificationsUniversitySlide.reducer